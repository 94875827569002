import { Action } from '@ngrx/store';

export enum ingredientsActionTypes {
    LOAD_INGREDIENTS = '[Ingredients component] load ingredients',
    SAVE_INGREDIENTS = '[Ingredients component] save ingredients',
    RESET_INGREDIENTS = '[Ingredients component] reset ingredients',
    RELOAD_INGREDIENTS = '[Ingredients component] reload ingredients'
}


export class LoadIngredientsAction implements Action{
    readonly type = ingredientsActionTypes.LOAD_INGREDIENTS;
}

export class SaveIngredientsAction implements Action{
    readonly type = ingredientsActionTypes.SAVE_INGREDIENTS;

    constructor(public payload: any){}
}

export class ResetIngredientsAction implements Action{
    readonly type = ingredientsActionTypes.RESET_INGREDIENTS
}

export class ReloadIngredientsAction implements Action{

    readonly type = ingredientsActionTypes.RELOAD_INGREDIENTS

    constructor(public payload: any){}
}

export type ingredientsActions = LoadIngredientsAction | SaveIngredientsAction | ResetIngredientsAction | ReloadIngredientsAction;