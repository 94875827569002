import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from '~models/menu-item';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-menu-items',
    templateUrl: './menu-items.component.html',
    styleUrls: ['./menu-items.component.scss']
})
export class MenuItemsComponent implements OnInit  {
  

    @Input() items$: Observable<MenuItem[]>;
    noSearchResultsImage = '../assets/images/noSearchResults.png'

    @Input()
    isSpecial: boolean;

    constructor() {
    }

    ngOnInit() {
     
    }
  

}
