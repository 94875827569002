import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MenuItem } from '~app/models/menu-item';
import { AppState } from '~app/reducers/main';
import { selectItemsByIDs } from '~app/selectors/menu.selectors';
import { DialogData } from '../menu/upscale-item/upscale-item.component';

export interface RoundOffDialogData {
  roundOffItemsIds:string[],
  roundOffAmount:number
  targetRoundOffValue: number
}

@Component({
  selector: 'app-round-off-items',
  templateUrl: './round-off-items.component.html',
  styleUrls: ['./round-off-items.component.scss']
})
export class RoundOffItemsComponent implements OnInit {

  noRoundOffItemsImage='../assets/images/noSearchResults.png'

  roundOffItems$:Observable<MenuItem[]>

  selectedAtleastOneRoundItemOff: boolean = false

  constructor(public dialogRef: MatDialogRef<RoundOffItemsComponent>,
    @Inject(MAT_DIALOG_DATA) public data:RoundOffDialogData,
    private store: Store<AppState>,) {
      this.roundOffItems$=this.store.pipe(select(selectItemsByIDs,{ids:data.roundOffItemsIds}))
     }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close()
  }

  selectedAtleastOneRoundOff(event: any){
    this.selectedAtleastOneRoundItemOff = true
  }

}
