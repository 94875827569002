import { MenuItem } from '~app/models/menu-item';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { orderActions, OrderActionTypes } from '~app/actions/order.actions';


export interface Round{
    id: number
    items: MenuItem[]
    status: string
}

export interface RoundState extends EntityState<Round>{
    noOfRounds: number
}

export const adapter: EntityAdapter<Round> = createEntityAdapter<Round>();

let initialState: RoundState = adapter.getInitialState({noOfRounds: 0});


export function orderReducer(state=initialState, action: orderActions){
    switch(action.type){
        case OrderActionTypes.PLACE_ORDER: {
            let payload = action.payload

            let data = []
            for(let p of payload){ 
                data.push(p)
            }
            let dataFinal = {
                id: state.ids.length + 1,
                items: data,
                status: 'INPROGRESS'
            }
            return adapter.addOne(dataFinal, state)
        }
    
        case OrderActionTypes.CLEAR_ORDERS: {
            return undefined
        }

        default: {
            return state
        }
    }
}


// get the selectors
const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = adapter.getSelectors();


  // select the array of user ids
  export const selectOrderIds = selectIds;
   
  // select the dictionary of user entities
  export const selectOrderEntities = selectEntities;
   
  // select the array of users
  export const selectAllOrders = selectAll;
   
  // select the total user count
  export const selectOrderTotal = selectTotal