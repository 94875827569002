import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JsonApiQueryData } from 'angular2-jsonapi';
import { Round } from '~app/jsonapi/round.model';
import { Datastore } from '~app/jsonapi/store-service';
import { Observable } from 'rxjs';
import { Restaurant } from '~app/models/restaurant';
import { Store, select } from '@ngrx/store';
import { AppState } from '~app/reducers/main';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {
  rounds: Round[];

  restaurant$: Observable<Restaurant>

  isForDelivery: boolean = false

  constructor(
    private store: Store<AppState>
  ) { 
    this.restaurant$ = this.store.pipe(select('restaurant'))

    if(localStorage.getItem('isForDelivery')!=null && localStorage.getItem('isForDelivery')=='true'){
      this.isForDelivery = true
    }
  }

  ngOnInit() {
  }

  

}
