import { Injectable } from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects'
import { switchMap, map, concatMap } from 'rxjs/operators';
import { MenuService } from '~app/services/menu.service';
import { menuActionTypes, SaveMenuAction, LoadMenuAction, MenuReloadAction, ReloadCategoriesAction, ReloadMenuItemsAction, ReloadIngredientsAction } from '~app/actions/menu.actions';
import { MenuLoadService } from '~app/services/menu-load.service';

@Injectable()
export class MenuEffects {

    constructor(
        private actions$: Actions, 
        private menuService: MenuService,
        private menuLoadService: MenuLoadService
        ){}

    @Effect()
    LoadMenuAction$ = this.actions$.pipe(
        ofType<LoadMenuAction>(menuActionTypes.LOAD_MENU),
        map(action => action.payload),
        switchMap((payload) => this.menuService.getItems(payload)
        .pipe(
            map(res => new SaveMenuAction(res))
        )),
        
    )


    // Menu reload effects
    
    @Effect()
    $ = this.actions$.pipe(
        ofType<MenuReloadAction>(menuActionTypes.MENU_RELOAD),
        map(action => action.payload),
        concatMap(payload => [
            new ReloadCategoriesAction(payload),
            new ReloadMenuItemsAction(payload),
            new ReloadIngredientsAction(payload)
         ])
    )

    @Effect(
        {dispatch: false}
    )
    ReloadCategoriesAction$ = this.actions$.pipe(
        ofType<MenuReloadAction>(menuActionTypes.LOAD_CATEGORIES),
        map(() => this.menuLoadService.loadCategories()),

    )

    @Effect(
        {dispatch: false}
    )
    ReloadMenuItemsAction$ = this.actions$.pipe(
        ofType<MenuReloadAction>(menuActionTypes.LOAD_MENU_ITEMS),
        map(() => this.menuLoadService.loadMenuItems()),
        
    )

    // @Effect(
    //     {dispatch: false}
    // )
    // ReloadIngredientsAction$ = this.actions$.pipe(
    //     ofType<MenuReloadAction>(menuActionTypes.LOAD_INGREDIENTS),
    //     map(() => this.menuLoadService.loadIngredients()),
    // )

}
