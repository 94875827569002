import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RestaurantDataProvider } from '~app/services/restaurant-data-provider.service';

@Component({
  selector: 'app-thankyou-business',
  templateUrl: './thankyou-business.component.html',
  styleUrls: ['./thankyou-business.component.scss']
})
export class ThankyouBusinessComponent implements OnInit {

  restaurantId: string;
  constructor(private route: ActivatedRoute, public router: Router) { 
    this.route.params.subscribe(params => this.restaurantId = params.restaurantId)
  }

  ngOnInit() {
  }

}
