import { Injectable } from '@angular/core';
import { GenericConstants } from '../constants/generic.constants';
import { AuthService } from './auth.service';
import { CapturedTimestampService } from './captured-timestamp.service';
import { retryWhen, tap, delayWhen } from 'rxjs/operators';
import { timer } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { AppState } from '~app/reducers/main';
import { Store } from '@ngrx/store';
import { ChangeWebSocketConnectionStatusAction, WebsocketConnectionOffAction } from '~app/actions/websocket-log.actions';

@Injectable({
  providedIn: 'root'
})
export class WebsocketEventService {

  WEBSOCKET_URL: string

  constructor(
    private authService: AuthService,
    private ctsService: CapturedTimestampService,
    private store:Store<AppState>
    ) {
    let timeStamp = this.ctsService.capturedTimeStamp?this.ctsService.capturedTimeStamp:undefined
    
    this.WEBSOCKET_URL =  GenericConstants.WEBSOCKET_URL.toString()

    let hasJwt = this.authService.hasJwtToken()

    if(hasJwt){
      if(timeStamp){
        this.WEBSOCKET_URL  = this.WEBSOCKET_URL + '?x-kittychef-ws-data-from='+ timeStamp+'&authz='+ this.authService.getContext().jwt
      }else{
        this.WEBSOCKET_URL = this.WEBSOCKET_URL +'?authz='+ this.authService.getContext().jwt
      }
    }
   

  }

  connect(){
    return webSocket(this.WEBSOCKET_URL).pipe(
      retryWhen(errors => 
        errors.pipe(
          tap(err => {console.log("error in websocket:", err)
                     this.store.dispatch(new WebsocketConnectionOffAction())}),
          delayWhen(() => timer(1000))
        )
      )
    )
  }
}
