import {Component, OnInit, Input} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {Question} from '~models/question';
import {KittyOpinionsComponent} from '~components/kitty-opinions/kitty-opinions.component';
import {SurveyService} from '~services/survey.service';
import {Survey} from '~models/survey';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {SurveyResponseService} from '~app/services/survey-response.service';
import {HttpClient} from '@angular/common/http';
import {RestaurantDataProvider} from '~services/restaurant-data-provider.service';
import {NotificationsService} from '~services/notifications.service';
import { AppState } from '~app/reducers/main';
import { select, Store } from '@ngrx/store';

@Component({
    selector: 'app-restaurant-feedback',
    templateUrl: './restaurant-feedback.component.html',
    styleUrls: ['./restaurant-feedback.component.scss'],
})
export class RestaurantFeedbackComponent implements OnInit {
    private static readonly RestaurantFeedbackUrn = 'urn:rupant:kittychef:surveys:restaurant-survey-v1';

    // questionnire length
    questionnaireLength: number;

    survey: Survey;
    restaurantId: string;

    services = ['Friendly', 'Responsive'];

    // This is used for switching b/w sections
    selectedIndex: number = 0;
    maxNumberOfTabs: number = 4;
    selected = new FormControl(0);


    // this is for boy helping old women
    height: string = '80px';
    width: string = '60px';
    marginLeft: number = 0
    surveyResponseService: SurveyResponseService;

    styleObject(): Object {
        return {height: this.height, width: this.width}
    }

    boyMotion(): Object {
        return {marginLeft: this.marginLeft.toString() + 'px'}
    }

    nextStep(selectedValue: number) {
        if (selectedValue == undefined)
            selectedValue = 0;
        this.selected.setValue(selectedValue + 1);
    }

    prevStep(selectedValue) {
        this.selected.setValue(selectedValue - 1);

    }

    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;

    constructor(
        private _formBuilder: FormBuilder,
        private surveyService: SurveyService,
        private activatedRoute: ActivatedRoute,
        public router: Router,
        private restaurantData: RestaurantDataProvider,
        private notificationsService: NotificationsService,
        private location: Location,
        private store:Store<AppState>,
        private http: HttpClient) {
    }

    ngOnInit() {
        this.firstFormGroup = this._formBuilder.group({
            firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
            secondCtrl: ['', Validators.required]
        });
        
        this.store.pipe(select('restaurant')).subscribe(
            next =>{
                 this.restaurantId = next.id
        });

        // TODO: may be move it up in the food chain and load it before the survey component is loaded?
        this.surveyService.byUrn(RestaurantFeedbackComponent.RestaurantFeedbackUrn).subscribe(
            survey => {
                this.survey = survey;
                this.questionnaireLength = survey.questionnaire.sections.length - 1;
                this.surveyResponseService = new SurveyResponseService(this.survey.id, this.http);
            }
        );
    }

    submitSurvey() {
        this.surveyResponseService.submitResponse(this.restaurantId).subscribe(
            next => {
                console.log('Submitted');
                this.router.navigate(['/thankyou-kitty']);
            },
            error => {
                console.log('error occured while submitting', error);
            }
        );
    }

    navigateToQuickMenu() {
        this.location.back();
    }

    // Todo: fix closure
    // {'question': 'Thank You for helping restaurant to serve your better', 'hint': '', 'imageUrl': '/assets/icons/thankyou.png'},
}
