import {Component, OnInit, Input, EventEmitter, Output, SimpleChanges, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {Choice} from '~models/choice';
import {Question} from '~app/models/question';
import {SurveyResponseService} from '~app/services/survey-response.service';
import {SingleSelectionResponse} from '~models/single-selection-response';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-radio-button',
    templateUrl: './radio-button.component.html',
    styleUrls: ['./radio-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class RadioButtonComponent implements OnInit {
    @Input()
    selectedAnswer: string;

    @Input()
    displayNext: boolean;

    @Input()
    question: Question;

    @Output() 
    radioButtonAnswerEvent = new EventEmitter<string>();

    @Output()
    clickedNextButtonEvent = new EventEmitter();


    @Output()
    finalSubmitEvent = new EventEmitter();

    @Input()
    surveyResponseService: SurveyResponseService;

    @Input()
    showSkeleton: boolean;

    radioForm: FormGroup;

    statusFlag:boolean=false;

    private initForm(){
        this.radioForm = new FormGroup({
            radio: new FormControl("",
            Validators.required)
        });
    }

    constructor() {
    }

    ngOnInit() {
        this.initForm();
    }

    submitAnswer() {

        if(this.statusFlag === false){
            const answer = this.radioForm.get('radio').value;
            this.surveyResponseService.recordResponse(this.question.id, new SingleSelectionResponse(answer));
            this.statusFlag=true;
        }
        
    }


}
