import {JsonApiModelConfig, JsonApiModel, Attribute, HasMany, BelongsTo} from 'angular2-jsonapi';

@JsonApiModelConfig({
  type: 'image-download-descriptors'
})
export class ImageDownloadDescriptor extends JsonApiModel {

  @Attribute()
  url: string;
}
