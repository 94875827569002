import { Component, OnInit } from '@angular/core';
import { AuthService } from '~app/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '~app/reducers/main';
import { UpdateDinerIdentityAction } from '~app/actions/diner.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { NotificationsService } from '~app/services/notifications.service';
import { SnackBarMessageMap } from '~app/constants/snackbar-message.constants';
import { RestaurantService } from '~app/services/restaurant.service';
import { NearByRestaurant } from '~app/models/restaurant';
import { AuthContext } from '~app/models/auth-context';
import { TimeStampValue } from '~app/utils/common-functions';

@Component({
  selector: 'app-near-by-restaurants',
  templateUrl: './near-by-restaurants.component.html',
  styleUrls: ['./near-by-restaurants.component.scss']
})
export class NearByRestaurantsComponent implements OnInit {

  lon: number
  lat: number

  nearByRestaurants: NearByRestaurant[] = []

  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    public loadingIndicatorService: LoadingIndicatorService,
    public spinner: NgxSpinnerService,
    public notificationService: NotificationsService,
    private restaurantService: RestaurantService
  ) {
    // if(hasRunningSession!=null && JSON.parse(hasRunningSession)){
    //   this.router.navigate(['/restaurant', this.authService.getContext().restaurantId, 'menu']);
    // }

      if(localStorage.getItem('longitude')!=null && localStorage.getItem('latitude')!=null){
        this.lat = +localStorage.getItem('latitude')
        this.lon = +localStorage.getItem('longitude')
      }

   }

  ngOnInit() {

      // this.lat = 18.6241337
      // this.lon = 79.9355346

        //TODO: Fetch the details for near by restaurants
        this.restaurantService.fetchNearByRestaurants(this.lat, this.lon, 6000, 0, 10).subscribe(
          next => {
         
            this.nearByRestaurants = next

          },
          error => {
            console.log("error in getting near by restaurants:", error)
          }
        )
 
  }

  gotoDeliveryDetails(restaurantID: string){

    this.loadingIndicatorService.set(LoadingContextMap.LOADING)
    this.spinner.show()
    this.authService.authenticateDiner("", true, restaurantID).subscribe(
      next => {
       
        localStorage.setItem('onlyMenu', 'false')
        localStorage.setItem('isForDelivery', 'true')

        this.store.dispatch(new UpdateDinerIdentityAction({ sessionID: next.sessionId, restaurantID: next.restaurantId, orderID: next.orderId ,trackingCode:next.trackingCode}))

        this.router.navigate(['/delivery-details'])

        this.spinner.hide()

      },
      error => {
        this.notificationService.showMessage(SnackBarMessageMap.SOMETHING_WRONG)
      }
    )
  }

}
