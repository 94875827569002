import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import {
  animate,
  state,
  style,
  transition,
  trigger,
  keyframes
} from '@angular/animations';
import { AfterViewInit, HostBinding } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  share,
  throttleTime
} from 'rxjs/operators';
import {RestaurantDataProvider} from '~services/restaurant-data-provider.service';
import { MatBottomSheet } from '@angular/material';
import { SpecialsComponent } from '~app/components/specials/specials.component';
import { MenuItem } from '~app/models/menu-item';
import { Store, select } from '@ngrx/store';
import { AppState } from '~app/reducers/main';
import { CartState } from '~app/reducers/cart.reducer';
import { cartLocationService, position } from '~app/services/cart-location.service';
import { CallWaiterService } from '~app/services/call-waiter.service';
import { Restaurant } from '~app/models/restaurant';
import { selectCartCount } from '~app/selectors/cart.selectors';


export enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

export enum Direction {
  Up = 'Up',
  Down = 'Down'
}

@Component({
  selector: 'app-business-footer',
  templateUrl: './business-footer.component.html',
  styleUrls: ['./business-footer.component.scss'],
  animations: [
    trigger('toggle', [
      state(
        VisibilityState.Hidden,
        style({ opacity: 0, transform: 'translateY(100%)' })
      ),
      state(
        VisibilityState.Visible,
        style({ opacity: 1, transform: 'translateY(0)' })
      ),
      transition('* => *', animate('400ms ease-in'))
    ]),
    
  trigger('shakeit', [
    state('shakestart', style({
        transform: 'scale(1)',
    })),
    state('shakeend', style({
        transform: 'scale(1)',
    })),
    transition('shakestart => shakeend', animate('3000ms ease-in',     keyframes([
      style({transform: 'rotate(20deg)', transformOrigin:'top center', offset: 0.1}),
      style({transform: 'rotate(-20deg)', transformOrigin:'top center',color:'lightgreen', offset: 0.2}),
      style({transform: 'rotate(20deg)', transformOrigin:'top center',color:'lightgreen', offset: 0.3}),
      style({transform: 'rotate(-20deg)', transformOrigin:'top center',color:'lightgreen', offset: 0.4}),
      style({transform: 'rotate(20deg)', transformOrigin:'top center',color:'lightgreen',offset: 0.5}),
      style({transform: 'rotate(-20deg)', transformOrigin:'top center',color:'lightgreen', offset: 0.6}),
      style({transform: 'rotate(20deg)', transformOrigin:'top center', color:'lightgreen',offset: 0.7}),
      style({transform: 'rotate(-20deg)', transformOrigin:'top center',color:'lightgreen', offset: 0.8}),
      style({transform: 'rotate(0deg)', transformOrigin:'top center',offset: 0.9}),
    ]))),
 ])]

})
export class BusinessFooterComponent implements OnInit, AfterViewInit {

    restaurant: Restaurant;
    badgeValue$: Observable<number>
    restaurant$:Observable<Restaurant>;

    states = {};

    check=0;
   
    constructor(public restaurantData: RestaurantDataProvider,
                public router: Router,
                private store: Store<AppState>,
                private elementRef:ElementRef,
                private cartLocationService:cartLocationService,
                private callWaiterService:CallWaiterService)
                 {
  
                  // this.states['state1'] = 'shakestart';
                  // this.states['state2'] = 'shakestart';
                  this.restaurant$=this.store.pipe(select('restaurant'))
                  this.badgeValue$ = this.store.pipe(select(selectCartCount))
    }

    ngOnInit() {
        // Subscribe to restaurant details when updated
        this.restaurantData.dataUpdated.subscribe(updatedDate => {
            this.restaurant = this.restaurantData.restaurant;
        });
      
    }

  private isVisible = true;

  @HostBinding('@toggle')
  get toggle(): VisibilityState {
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }

  ngAfterViewInit() {
    this.setCartPosition();
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => window.pageYOffset),
      pairwise(),
      map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
      distinctUntilChanged(),
      share()
    );

    const goingUp$ = scroll$.pipe(
      filter(direction => direction === Direction.Up)
    );

    const goingDown$ = scroll$.pipe(
      filter(direction => direction === Direction.Down)
    );

    goingUp$.subscribe(() => (this.isVisible = true));
    goingDown$.subscribe(() => (this.isVisible = false));
  }

// openSpecials(){
//     this.bottomSheet.open(SpecialsComponent);
// }


openCallWaiterSheet(){
  // this.bottomSheet.open(CallWaiterComponent,{
  //   panelClass:"bottomSheet"
  // });
}

openKittyList(){
  this.router.navigate(['/kitty-list']);
}

shakeMe(stateVar: string) {
  this.callWaiterService.callwaiter().subscribe(
    next=>{
      if(next.status == true){
        this.states[stateVar] = (this.states[stateVar] === 'shakestart' ? 'shakeend' : 'shakestart');
        console.log("call waiter request sucessfully sent ")
      }
    },
    error =>{
       console.log("error in sending call waiter request",error)
    } 
  )
}

shakeEnd(stateVar: string, event) {
this.states[stateVar] = 'shakeend';
}
  

@HostListener('window:resize', ['$event'])
onResize($event:any):void{
  this.setCartPosition();
}

setCartPosition():void{
  let cartDiv = this.elementRef.nativeElement.querySelector("#cart-icon").getBoundingClientRect();
  let x = cartDiv.left+10;
  let y = cartDiv.top;
  this.cartLocationService.changePosition(new position(x,y));
}

}

 

