import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from '~app/models/menu-item';
import { Router } from '@angular/router';
import { QuickItemSkeletonService } from '~app/services/quick-item-skeleton.service';
import { SplGParentChildService } from '~app/services/spl-gparent-child.service';

@Component({
  selector: 'app-spl-menu-item',
  templateUrl: './spl-menu-item.component.html',
  styleUrls: ['./spl-menu-item.component.scss']
})
export class SplMenuItemComponent implements OnInit {

  @Input() item: MenuItem;

    vegIcon = '../assets/icons/Veg_icon.svg';

    nonVegIcon = '../assets/icons/Nonveg_icon.svg';

    showSkeleton: boolean;

    constructor(private router: Router, private quickItemSkeleton: QuickItemSkeletonService, private splGrandChild: SplGParentChildService) {
    }

    ngOnInit() {
        this.showSkeleton = this.quickItemSkeleton.get();
    }

    openItem() {
        this.splGrandChild.emitChildEvent(this.item);
        this.splGrandChild.emitChildEventOnItemClick(true);
    }

}
