import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs/Rx';
import { PaymentOrder } from '~app/models/payment';

import { AppState } from '~app/reducers/main';

@Component({
  selector: 'app-payment-success-dialog',
  templateUrl: './payment-success-dialog.component.html',
  styleUrls: ['./payment-success-dialog.component.scss']
})
export class PaymentSuccessDialogComponent implements OnInit {
  payment$: Observable<PaymentOrder>;

  isRedirectToKittyFeedBack:boolean;

  constructor(public dialogRef: MatDialogRef<PaymentSuccessDialogComponent>,
               public router: Router,
               @Inject(MAT_DIALOG_DATA) public data: any,
               private store:Store<AppState>,
               ) { 
                this.payment$ = this.store.pipe(select('paymentOrder'))
               }

  ngOnInit() {
    
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  onSkip(){
    this.isRedirectToKittyFeedBack=false;
    this.dialogRef.close({isRedirectToKittyFeedBack:this.isRedirectToKittyFeedBack})
  }

  goToKittyFeedback(){
    this.isRedirectToKittyFeedBack=true;
    this.dialogRef.close({isRedirectToKittyFeedBack:this.isRedirectToKittyFeedBack})
  }



}
