import {QuestionnaireResponse} from '~models/questionnaire-response';

export class SurveyResponse {

  constructor(id: string, questionnaireResponse: QuestionnaireResponse) {
    this.surveyId = id;
    this.questionnaireResponse = questionnaireResponse;
  }
  surveyId: string;
  restaurantId: string;
  sessionId: string; // ignore for now
  questionnaireResponse: QuestionnaireResponse;
}
