import {Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Input} from '@angular/core';
import {Tag} from '~models/tag';
import {MatBottomSheet} from '@angular/material';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TagsComponent implements OnInit {
    @Input() tags: Tag[];

    constructor(private bottomSheet: MatBottomSheet) {
    }

    ngOnInit() {
        console.log('TAGS', this.tags);
    }

}
