import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material';
import {NotificationsComponent} from '~components/common/notifications/notifications.component';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    durationInSeconds = 0.2
    horizontalPosition: MatSnackBarHorizontalPosition = 'center'
    verticalPosition: MatSnackBarVerticalPosition = 'bottom'
    
    constructor(
        private snackBar: MatSnackBar
        ) {

        }

    showMessage(message: string) {
        this.snackBar.openFromComponent(NotificationsComponent,
            {
                // duration: this.durationInSeconds*1000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                data: message,
            });
    }

    showMessageWithDuration(message: string) {
        this.snackBar.openFromComponent(NotificationsComponent,
            {
                duration: this.durationInSeconds*1000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                data: message,
            });
    }

    dismissCurrentMessage() {
        this.snackBar.dismiss();
    }
}
