import { Action } from '@ngrx/store';


export enum RoundActionTypes {
    ADD_ROUND = '[Round Component] add round',
    RESET_ROUNDS = '[Round Component] reset rounds',
    UPDATE_ROUND = '[Round Component] Update Round'
}

export class AddRoundAction implements Action{
    readonly type = RoundActionTypes.ADD_ROUND

    constructor(public payload: any){}
}

export class ResetRoundsAction implements Action{
    readonly type = RoundActionTypes.RESET_ROUNDS
}

export class UpdateRoundAction implements Action{
    readonly type = RoundActionTypes.UPDATE_ROUND

    constructor(public payload: any){}
}

export type RoundActions = AddRoundAction |
UpdateRoundAction |
ResetRoundsAction