import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { item } from '../know-your-product.component';

@Component({
  selector: 'app-know-your-item',
  templateUrl: './know-your-item.component.html',
  styleUrls: ['./know-your-item.component.scss']
})
export class KnowYourItemComponent implements OnInit {

  vegIcon = '../assets/icons/Veg_icon.svg';

  nonVegIcon = '../assets/icons/Nonveg_icon.svg';

  @Input() item:item;
  @Input() isResponseSumbit:boolean;
  @Input() isCartShowed:boolean ;
  @Output() addItem = new EventEmitter<item> ();

  constructor() { }

  ngOnInit() {
  }

  onAddItem(addedItem:item){
     this.addItem.emit(addedItem)
  }

}
