import { Action } from '@ngrx/store';

export enum OrderActionTypes{
    SEND_TO_KITCHEN = '[Order component] send to kitchen',
    PLACE_ORDER = '[Order component] place order',
    CLEAR_ORDERS = '[Order component] clear order'
}

export class SendtoKitchenAction implements Action{
    readonly type = OrderActionTypes.SEND_TO_KITCHEN

    constructor(public payload: any){}
}

export class PlaceOrderAction implements Action{
    readonly type = OrderActionTypes.PLACE_ORDER

    constructor(public payload: any){}
}

export class ClearOrdersAction implements Action{
    readonly type = OrderActionTypes.CLEAR_ORDERS

}

export type orderActions = SendtoKitchenAction |
PlaceOrderAction |
ClearOrdersAction