import { RoundActionTypes } from '~app/actions/round.actions';
import { EntityState, Dictionary, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { RndOff, RoundOffItem } from '~app/models/round-off';
import { RoundOffActions, RoundOffActionTypes } from '~app/actions/round-off.actions';

export class RoundOffState implements EntityState<RndOff>{
    ids: string[] | number[];
    entities: Dictionary<RndOff>;
}


export const adapter: EntityAdapter<RndOff> = createEntityAdapter<RndOff>();

let initialState: RoundOffState = adapter.getInitialState();


export function roundOffReducer(state=initialState, action: RoundOffActions){

    switch(action.type){
        case RoundOffActionTypes.ADD_ROUND_OFF_ITEMS: {
            
            return adapter.addOne(action.payload, state)
        }
        
        case RoundOffActionTypes.UPDATE_ROUND_OFF_ITEMS: {
            return adapter.setOne(action.payload, state)
        }

        default : {
            return state
        }
    }

}

// get the selectors
const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = adapter.getSelectors();


  // select the array of user ids
  export const selectRoundOffIds = selectIds;
   
  // select the dictionary of user entities
  export const selectRoundOffEntities = selectEntities;
   
  // select the array of users
  export const selectAllRoundOffs = selectAll;
   
  // select the total user count
  export const selectRoundOffTotal = selectTotal