import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AppState } from '~app/reducers/main';
import { Store, select } from '@ngrx/store';
import { RoundState } from '~app/reducers/order.reducer';
import { Observable } from 'rxjs';
import { selectOrderItems, selectBillableItems, selectTotalBill } from '~app/selectors/order.selectors';
import { WindowRefService } from '~app/services/window-ref.service';
import { RazorpayService } from '~app/services/razorpay.service';
import { Datastore } from '~app/jsonapi/store-service';
import { Order } from '~app/jsonapi/order.model';
import { Round } from '~app/jsonapi/round.model';
import { JsonApiQueryData } from 'angular2-jsonapi';
import { element } from 'protractor';
import { listItem } from '../cart-summary/current-course/current-course.component';
import { selectByItemId } from '~app/selectors/menu.selectors';
import { PaymentService } from '~app/services/payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InitiatePaymentAction } from '~app/actions/payment.actions';
import { PaymentOrder } from '~app/models/payment';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isOrderCompleted } from '~app/selectors/round.selector';
import { MatDialog } from '@angular/material';
import { PaymentSuccessDialogComponent } from './payment-success-dialog/payment-success-dialog.component';
import { Restaurant } from '~app/models/restaurant';
import { selectRestaurant } from '~app/selectors/restaurant.selectors';

@Component({
  selector: 'app-paymentscreen',
  templateUrl: './paymentscreen.component.html',
  styleUrls: ['./paymentscreen.component.scss']
})

export class PaymentInitiateScreenComponent implements OnInit {

  displayedColumns: string[] = ['SLNo', 'Item', 'Rate', 'Amount'];

  orders$: Observable<RoundState[]>
  totalBill$: Observable<number>

  totalBill: number;

  payment$: Observable<PaymentOrder>

  rupantLogo = '../assets/icons/rupant_clr.png'

  rounds: Round[];
  lineItems: listItem[] = [];

  isOrderComplete$: Observable<boolean>

  restaurantObj$: Observable<Restaurant>;

  noOrderImage = '../assets/images/no_rounds.png'

  cashPaymentIcon='../assets/icons/cash.png'
  cardPaymentIcon='../assets/icons/credit.png'
  upiPaymentIcon='../assets/icons/upi.svg'

  constructor(private store: Store<AppState>,
    private winRef: WindowRefService,
    private rzpayService: RazorpayService,
    private dataStore: Datastore,
    private paymentService: PaymentService,
    private dialog: MatDialog,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingIndicatorService: LoadingIndicatorService,
    private spinner: NgxSpinnerService,
    private cd: ChangeDetectorRef
  ) {
    this.orders$ = this.store.pipe(select(selectBillableItems))
    this.totalBill$ = this.store.pipe(select(selectTotalBill))
    this.isOrderComplete$ = this.store.pipe(select(isOrderCompleted))
    this.payment$ = this.store.pipe(select('paymentOrder'))
    this.restaurantObj$ = this.store.pipe(select(selectRestaurant))

    // this.openPaymentSuccessDialog()
  }

  ngOnInit() {
    this.payment$.subscribe(
      next => {
        console.log("PAYMENT ORDR:", next)
      },
      error => {
        console.log("error in payment ordr:", error)
      }
    )
  }

  createRzpayOrder(amount: number) {
    this.loadingIndicatorService.set(LoadingContextMap.LOADING)
    this.spinner.show()
    this.store.dispatch(new InitiatePaymentAction());
    this.router.navigate(['../payment'], { relativeTo: this.activatedRoute });
    this.spinner.hide()
  }



  // openPaymentSuccessDialog(){
  //   this.payment$.subscribe(
  //     (payment:PaymentOrder) => {
  //       if (payment.isPaid) {
  //         const dialogRef = this.dialog.open(PaymentSuccessDialogComponent,{ disableClose: true })
  //         dialogRef.afterClosed().subscribe(
  //           result => {
  //             if (result.isRedirectToKittyFeedBack === true) {
  //               this.router.navigate(['../kitty-feedback'], { relativeTo: this.activatedRoute })
  //             }
  //             if (result.isRedirectToKittyFeedBack === false) {
  //               this.router.navigate(['../thankyou'], { relativeTo: this.activatedRoute })
  //             }
  //           })

  //       }}
  //   )

  // }


  getItemById(itemId: string) {
    let item: any;
    this.store.pipe(select(selectByItemId, { id: itemId })).subscribe(
      next => {
        item = next
      }
    )
    return item
  }


  ngAfterViewInit() {
    this.loadingIndicatorService.set(LoadingContextMap.LOADING)
    this.spinner.show()
    this.dataStore.findAll(Round,
      { include: 'session-order' }).subscribe(
        (rounds: JsonApiQueryData<Round>) => {
          this.rounds = rounds.getModels()
          if(this.rounds.length>0){
            this.totalBill = this.rounds[0].sessionOrder.orderTotal
            this.rounds.forEach(
              element => {
                this.lineItems = this.lineItems.concat(element.lineItems)
              }
            )
          }
          
          this.cd.detectChanges()
          this.spinner.hide()
        },
        error => {
          this.spinner.hide()
          console.log("Error in getting bill details:", error)
        }
      )

  }

}
