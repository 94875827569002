import { Ingredient } from '~app/models/ingredient';
import {EntityState, Dictionary, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import { ServiceItem } from '~app/models/service-item';
import { serviceItemsActions, serviceItemsActionTypes } from '~app/actions/service-Items.actions';


export class ServiceItemsState implements EntityState<ServiceItem>{
    ids: string[] | number[];
    entities: Dictionary<ServiceItem>;
}

export const adapter: EntityAdapter<ServiceItem> = createEntityAdapter<ServiceItem>();

let initialState: ServiceItemsState = adapter.getInitialState();

export function serviceItemsReducer(state=initialState, action: serviceItemsActions ){
    switch(action.type){
        case serviceItemsActionTypes.LOAD_SERVICE_ITEMS : {
            return state;
        }
        case serviceItemsActionTypes.SAVE_SERVICE_ITEMS: {
            return adapter.addMany(action.payload, state);
        }
        case serviceItemsActionTypes.REST_SERVICE_ITEMS: {
            return undefined
        }
        default: {
            return state;
        }
    }
}



// get the selectors
const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = adapter.getSelectors();
   
  // select the array of user ids
  export const selectserviceItemsIds = selectIds;
   
  // select the dictionary of user entities
  export const selectserviceItemsEntities = selectEntities;
   
  // select the array of users
  export const selectAllserviceItems = selectAll;
   
  // select the total user count
  export const selectserviceItemsTotal = selectTotal