import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '~app/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '~app/reducers/main';
import { UpdateDinerIdentityAction, CompleteDiningAction } from '~app/actions/diner.actions';
import { NotificationsService } from '~app/services/notifications.service';
import { SnackBarMessageMap } from '~app/constants/snackbar-message.constants';
import { Datastore } from '~app/jsonapi/store-service';
import { SessionProfile } from '~app/jsonapi/session-Profile.model';
import { SaveRestaurantAction } from '~app/actions/restaurant.actions';
import { AuthContext } from '~app/models/auth-context';
import { SaveDinerAction } from '~app/actions/diner.actions';
import { TimeStampValue } from '~app/utils/common-functions';
import { PreloadingService } from '~app/services/preloading.service';
import { UpdateBasket } from '~app/actions/basket.actions';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';
import { CameraAccessDialogComponent } from '~app/components/camera-access-dialog/camera-access-dialog.component';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss']
})
export class ScanComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  imageLabel: string;
  openedToScan: boolean = false


  constructor(
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public loadingIndicatorService: LoadingIndicatorService,
    public spinner: NgxSpinnerService,
    public authService:AuthService,
    public store: Store<AppState>,
    public router: Router,
    public dataStore: Datastore,
    public notificationService: NotificationsService,
    public preloadingService: PreloadingService
  ) { 

    let hasRunningSession = localStorage.getItem("hasRunningSession")
    if(hasRunningSession!=null && JSON.parse(hasRunningSession)){
      this.router.navigate(['/restaurant', this.authService.getContext().restaurantId, 'home']);
    }else{
      this.activatedRoute.queryParams.subscribe((params) => {
        let trackingID = params['trackingID']
        let secret = params['secret']

        if(trackingID && secret){
          this.loadingIndicatorService.set('Loading...')
          this.spinner.show()
        }
  
        let code = undefined
        if(trackingID){
            code = trackingID
        }
  
        if(secret){
            code = code+':'+secret
        }
  
        if(code){
            this.VendorQRScanHandler(code)
        }
    })
    }
  }

  ngOnInit() {
    
    this.galleryOptions = [
      {
          width: '100%',
          height: '55vh',
          imageAnimation: NgxGalleryAnimation.Fade,
          imageAutoPlay: true,
          imageSwipe: true,
          preview: false,
          thumbnails: false,
          imageArrows: false,
          imageBullets: true
      }
  ];

  

  this.galleryImages = [
      {
          small: '/assets/images/happyDinners.jpg',
          medium: '/assets/images/happyDinners.jpg',
          big: '/assets/images/happyDinners.jpg',
          label: 'Happy dinners'
      },

      // {
      //     small: '/assets/images/madeWithLove.jpg',
      //     medium: '/assets/images/madeWithLove.jpg',
      //     big: '/assets/images/madeWithLove.jpg',
      //     label: 'Made with love'
      // },
      // {
      //     small: '/assets/images/kittyDigitalExperience.jpg',
      //     medium: '/assets/images/kittyDigitalExperience.jpg',
      //     big: '/assets/images/kittyDigitalExperience.jpg',
      //     label: "Kittychef's digital experience"
      // },
      // {
      //     small: 'https://res.cloudinary.com/demo/image/upload/w_500,ar_1,c_crop,g_auto:wine_glass/docs/pizza_wine.jpg',
      //     medium: 'https://res.cloudinary.com/demo/image/upload/w_500,ar_1,c_crop,g_auto:wine_glass/docs/pizza_wine.jpg',
      //     big: 'https://res.cloudinary.com/demo/image/upload/w_500,ar_1,c_crop,g_auto:wine_glass/docs/pizza_wine.jpg',
      //     label: 'Hello world'
      // },
      // {
      //     small: 'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/SouthIndian_a5ivgy',
      //     medium: 'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/SouthIndian_a5ivgy',
      //     big: 'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/SouthIndian_a5ivgy',
      //     label: 'See this'
      // }
  ];

  }

  
  openDialog() {
    this.dialog.open(CameraAccessDialogComponent, {
      data: {
      }
    });
  }

  public scanSuccessHandler(code: string) {

    // Clear out any previous session data (ngrx, localStorage)
    localStorage.clear()
    this.store.dispatch(new CompleteDiningAction())

    let constraints={video:true};
    navigator.mediaDevices.getUserMedia(constraints)
    .then((stream)=>{console.log("camera access allowed")})
    .catch((err)=>
     {if(err.name=="NotAllowedError"){this.openDialog()}
    });       

    //TODO: Now, Code is going to be url, and we need to extract the trackingID value and secret value
    const url = new URL(code)

    const trackingID = url.searchParams.get('trackingID');
    const secret = url.searchParams.get('secret')

    let newCode = undefined

    if(trackingID!=''){
        newCode = trackingID
    }

    if(secret!=''){
        newCode = newCode+':'+secret
    }

    this.VendorQRScanHandler(newCode)
   
}

  VendorQRScanHandler(code){

    // Clear out the old session
    console.log("coming out")
    this.store.dispatch(new CompleteDiningAction())
    
    this.loadingIndicatorService.set(LoadingContextMap.QR_SCAN)
    this.spinner.show()


    this.authService.authenticateDiner(code).subscribe(
        next => {
            // close scanner
            this.openedToScan = false
            this.loadingIndicatorService.set(LoadingContextMap.QR_SCAN_SUCCESS)

            this.store.dispatch(new UpdateDinerIdentityAction({ sessionID: next.sessionId, restaurantID: next.restaurantId, orderID: next.orderId ,trackingCode:next.trackingCode, cartID: next.cartID}))

            //Dispatch an action to initialize cart, (i.e empty cart)
            this.store.dispatch(new UpdateBasket([]))

            // Based on pricing tier take diversion


            if(next.pricingTier == 1){
              let dinerName = "chefk" // default chef name if user clicks on skip button
  
              let sessionProfile = this.dataStore.createRecord(SessionProfile,{
               name:dinerName
             });
           
             sessionProfile.save().subscribe(
               next =>{
                 this.store.dispatch(new SaveDinerAction({id: next.id, name: next.name}))
                 this.preloadingService.initialize()
               },
               error =>{
                 this.spinner.hide()
                 this.notificationService.showMessage(SnackBarMessageMap.SOMETHING_WRONG)
               }
             ); 
            }else if(next.pricingTier == 2){
              this.spinner.hide()
              this.router.navigate(['session'])
            }
  
        },
        error => {
            this.spinner.hide()
            this.notificationService.showMessage(SnackBarMessageMap.WRONG_QR_SCAN)
        }
    )

}

imageChangedEvent(image: NgxGalleryImage) {
  this.imageLabel = image.label;
}

openScanner(){
  // this.router.navigate(['scanner'])
  this.openedToScan = true
}



}
