import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Survey} from '~models/survey';
import {SurveyResponse} from '~models/survey-response';
import {QuestionnaireResponse} from '~models/questionnaire-response';
import {QuestionResponse} from '~models/question-response';


export class SurveyResponseService {
    private surveyResponseUrl = 'api/surveys/responses';
    private responseMap = new Map();

    constructor(private surveyId: string, private http: HttpClient) {
    }

    submitResponse(restaurantId: any): Observable<any> {
        const surveyResponse = this.buildResponse();
        surveyResponse.restaurantId = restaurantId;
        return this.http.post<Survey>(this.surveyResponseUrl, surveyResponse);
    }

    buildResponse(): SurveyResponse {
        return new SurveyResponse(this.surveyId, QuestionnaireResponse.fromMap(this.responseMap));
    }

    recordResponse(questionId: string, response: any) {
        this.responseMap.set(questionId, new QuestionResponse(questionId, response));
    }
}
