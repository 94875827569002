import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MenuItem } from '~app/models/menu-item';
import { CartActions, CartActionTypes } from '~app/actions/cart.actions';
import { Store } from '@ngrx/store';
import { AppState } from './main';

export interface CartState extends EntityState<MenuItem>{
   // extra field
//    cartCount: number
}

export const adapter: EntityAdapter<MenuItem> = createEntityAdapter<MenuItem>();


let initialState: CartState = adapter.getInitialState({cartCount: 0});


export function cartReducer(state=initialState, action: CartActions){
    switch(action.type){
        case CartActionTypes.ADD_TO_CART: {
            let payload = action.payload
            let data = {
               ...payload,
                quantity: 1
            }
            // let store = payload.store
            // let diff = 1
            // console.log("we are at starting", payload.store)
            // store.dispatch(new UpdateQuanityAction({id: payload.item.id, updatedQuantity: diff}))
            // console.log("we are here upto")
            // return adapter.addOne(data, {...state, cartCount: state.cartCount+1})
            return adapter.addOne(data, {...state})
        }

        case CartActionTypes.UPDATE_ITEM_QUANITY: {
            let payload = action.payload // example: -, + 
            let diff = 0
            if(payload.operation === '-'){
                diff = - 1
            }else if(payload.operation==='+'){
                diff = 1
            }

            let data: MenuItem = {
                ...payload.item,
                quantity:  (payload.item.quantity + diff)>=0?(payload.item.quantity + diff):0
            }

            let changes = {quantity:  (payload.item.quantity + diff)>=0?(payload.item.quantity + diff):0}

            // check if updated quantity is <=0
            if(changes.quantity<=0){
                return adapter.removeOne(payload.item.id, {...state})
            }else{
                return adapter.updateOne({id: payload.item.id, changes: changes }, {...state})
            }
            
        }

        case CartActionTypes.REMOVE_ITEM_FROM_CART: {
            return adapter.removeOne(action.payload, state)
        }
    

        case CartActionTypes.CLEAR_CART: {
            return undefined
        }

        default: {
            return state
        }
    }
}


// get the selectors
const {
    selectIds,
    selectEntities,
    selectAll,
    // selectTotal,
  } = adapter.getSelectors();


  // select the array of user ids
  export const selectCartIds = selectIds;
   
  // select the dictionary of user entities
  export const selectCartEntities = selectEntities;
   
  // select the array of users
  export const selectAllCart = selectAll;
   
//   // select the total user count
//   export const selectCartTotal = selectTotal


