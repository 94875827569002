import { Component, OnInit } from '@angular/core';
import { AppState } from '~app/reducers/main';
import { Store, select } from '@ngrx/store';
import { RoundState } from '~app/reducers/order.reducer';
import { Observable } from 'rxjs';
import { selectOrderCount } from '~app/selectors/order.selectors';
import { Round } from '~app/jsonapi/round.model';

import { RoundToAdd } from '~app/reducers/round.reducer';
import { selectBusinessRounds } from '~app/selectors/round.selector';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentOrder } from '~app/models/payment';

@Component({
  selector: 'app-order-courses',
  templateUrl: './order-courses.component.html',
  styleUrls: ['./order-courses.component.scss']
})
export class OrderCoursesComponent implements OnInit {
  ordersCount$: Observable<number>;

  orders$: Observable<RoundState[]>;
  rounds: Round[]=[];
  rounds$:Observable<RoundToAdd[]>;

  noOrderImage = '../assets/images/no_rounds.png'
  payment$: Observable<PaymentOrder>;
  
  constructor(private store: Store<AppState>,
              public router: Router,
              private activatedRoute: ActivatedRoute,
              ) {
    this.rounds$=this.store.pipe(select(selectBusinessRounds));
    this.ordersCount$ = this.store.pipe(select(selectOrderCount));
    this.payment$ = this.store.pipe(select('paymentOrder'))
   }


  ngOnInit() {
    
  }

  goToPayment(){
    this.router.navigate(['../payment-initiate'], { relativeTo: this.activatedRoute});
  }

  backToQuickMenu(){
    this.router.navigate(['../menu'], { relativeTo: this.activatedRoute});
  }
 

}
