import { Action } from '@ngrx/store';


export enum PaymentActionTypes{
    
    INITIATE_PAYMENT='[Payment Component] Intiate Payment',
    SAVE_PAYMENT='[Payment Component] Save Payment',
    LOAD_PAYMENT='[Payment Component] Load Payment',
    UPDATE_PAYMENT='[Payment Component] UpdatemPayment',
    CLEAR_PAYMENT = '[Payment Component] Clear payment',
}

export class InitiatePaymentAction implements Action{
    readonly type = PaymentActionTypes.INITIATE_PAYMENT
}

export class SavePaymentAction implements Action{
    readonly type = PaymentActionTypes.SAVE_PAYMENT
    constructor(public payload: any){}
}

export class LoadPaymentAction implements Action{
    readonly type = PaymentActionTypes.LOAD_PAYMENT
}

export class UpdatePaymentAction implements Action{
    readonly type = PaymentActionTypes.UPDATE_PAYMENT
    constructor(public payload: any){}
}

export class ClearPaymentAction implements Action{
    readonly type = PaymentActionTypes.CLEAR_PAYMENT
}



export type paymentActions = 
InitiatePaymentAction  | 
SavePaymentAction  |
LoadPaymentAction  |
UpdatePaymentAction |
ClearPaymentAction