import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidationMap } from '~app/constants/validators';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { Store } from '@ngrx/store';
import { AppState } from '~app/reducers/main';
import { Datastore } from '~app/jsonapi/store-service';
import { SaveDinerAction } from '~app/actions/diner.actions';
import { SessionProfile } from '~app/jsonapi/session-Profile.model';
import { PreloadingService } from '~app/services/preloading.service';
import { NotificationsService } from '~app/services/notifications.service';
import { SnackBarMessageMap } from '~app/constants/snackbar-message.constants';

@Component({
  selector: 'app-delivery-details',
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.scss']
})
export class DeliveryDetailsComponent implements OnInit {

  deliveryDetailsForm: FormGroup

  constructor(
    private loadingIndicatorService: LoadingIndicatorService,
    private spinner: NgxSpinnerService,
    private store: Store<AppState>,
    private dataStore: Datastore,
    private preloadingService: PreloadingService,
    private notificationService: NotificationsService
  ) { }

  ngOnInit() {
    this.initForm()
  }

  private initForm() {
    this.deliveryDetailsForm = new FormGroup({
        // tslint:disable-next-line:max-line-length
        dinerName: new FormControl('', 
        Validators.compose([Validators.minLength(2), Validators.required])),
        mobileNumber: new FormControl('',
        Validators.compose(
          [Validators.minLength(10),
          Validators.required,
          Validators.pattern(ValidationMap.MOBILE_NUMBER_PATTERN)
        ]
        )),
        address: new FormControl('',
        Validators.compose(
          [
            Validators.required,
          ]
        )
        )

    })
  }

  onSubmit(){

    this.loadingIndicatorService.set(LoadingContextMap.UPDATING_GUEST)
    this.spinner.show()
  
    let dinerName = this.deliveryDetailsForm.get('dinerName').value
    let phone = this.deliveryDetailsForm.get('mobileNumber').value
    let address = this.deliveryDetailsForm.get('address').value

    //TODO: Here we need to pass in, mobile number, and latitude and langitude along with the diner name
  
     let sessionProfile = this.dataStore.createRecord(SessionProfile,{
      name:dinerName,
      phone: phone,
      address: address
    });
  
    sessionProfile.save().subscribe(
      next =>{
        let hasRunningSession = localStorage.getItem("hasRunningSession")
        this.store.dispatch(new SaveDinerAction({id: next.id, name: next.name}))
        this.preloadingService.initialize()
      },
      error =>{
        this.spinner.hide()
        this.notificationService.showMessage(SnackBarMessageMap.SOMETHING_WRONG)
      }
    ); 
     
    }

}
