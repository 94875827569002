import { Action } from '@ngrx/store';

export enum WebSocketActionTypes {
    ADD_EVENT = '[Websocket Component] Add Event',
    SAVE_WEBSOCKET_LOG = '[Websocket Component] Save Websocket Log',
    GOT_EVENT = '[Websocket Component] Got Event',
    GOT_ROUND_ADDED_MESSAGE = '[Websocket Component] Got Round Added Message',
    GOT_ROUND_UPDATED_MESSAGE = '[Websocket Component] Got Round Updated Message',
    CHANGE_WEBSOCKET_CONNECTION_STATUS=  '[Websocket Component] Change web socket connection status',
    WEBSOCKET_CONNECTION_ON = '[Websocket Component] Websocket connection on status',
    WEBSOCKET_CONNECTION_OFF = '[Websocket Component] Websocket connection off status',
}


export class AddEventAction implements Action{
    readonly type = WebSocketActionTypes.ADD_EVENT

    constructor(public payload: any){}
}

export class SaveWebsocketLogAction implements Action{
    readonly type = WebSocketActionTypes.SAVE_WEBSOCKET_LOG

    constructor(public payload: any){}
}


export class GotEventAction implements Action{
    readonly type = WebSocketActionTypes.GOT_EVENT

    constructor(public payload: any){}
}

export class GotRoundAddedMessageAction implements Action{
    readonly type = WebSocketActionTypes.GOT_ROUND_ADDED_MESSAGE
    
    constructor(public payload: any){}
}

export class GotRoundUpdatedMessageAction implements Action{
    readonly type = WebSocketActionTypes.GOT_ROUND_UPDATED_MESSAGE
    
    constructor(public payload: any){}
}

export class ChangeWebSocketConnectionStatusAction implements Action{

    readonly type = WebSocketActionTypes.CHANGE_WEBSOCKET_CONNECTION_STATUS

    constructor(public payload: any){}
}

export class WebsocketConnectionOnAction implements Action{
   readonly type = WebSocketActionTypes.WEBSOCKET_CONNECTION_ON
   
   constructor(){}
}

export class WebsocketConnectionOffAction implements Action{
    readonly type = WebSocketActionTypes.WEBSOCKET_CONNECTION_OFF
    
    constructor(){}
 }

export type WebsocketActions = AddEventAction |
SaveWebsocketLogAction |
GotEventAction |
GotRoundAddedMessageAction |
GotRoundUpdatedMessageAction |
ChangeWebSocketConnectionStatusAction |
WebsocketConnectionOnAction | 
WebsocketConnectionOffAction