import { Injectable } from '@angular/core';
import { CategoriesService } from './categories.service';
import { MenuItemService } from './menu-item.service';
import { IngredientsService } from './ingredients.service';
import { RestaurantService } from './restaurant.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceItemsService } from './service-items.service';
import { concat, Observable, empty } from 'rxjs';
import { RouteService } from './route.service';
import { catchError } from 'rxjs/operators';
import { MenuSettingsService } from './menu-setting.service';
import { CategorySelectionService } from './category-selection.service';
import { AppState } from '~app/reducers/main';
import { Store, select } from '@ngrx/store';
import { selectBusinessCategories } from '~app/selectors/menu.selectors';
import { SaveCategoryIdAction } from '~app/actions/categories.actions';
import { CartService } from './cart.service';
import { RoundOffService } from './round-off.service';
import { PaymentModesService } from './payment-modes.service';
import { UpiDetailsService } from './upi-details.service';
import { JokeService } from './joke.service';

@Injectable({
  providedIn: 'root'
})
export class PreloadingService {

  constructor(
    private router: Router,
    private authService: AuthService,
    private spinner: NgxSpinnerService, 
    private restaurantService: RestaurantService,
    private categoriesService: CategoriesService,
    private menuItemService: MenuItemService,
    private ingredientsService: IngredientsService,
    private serviceItemsService: ServiceItemsService,
    private routeService: RouteService,
    private menuSettingsService:MenuSettingsService,
    private categorySelectionService: CategorySelectionService,
    private store: Store<AppState>,
    private roundOffService: RoundOffService,
    private paymentModesService: PaymentModesService,
    private upiDetailsService: UpiDetailsService,
    private jokeService: JokeService
  ) {
    //this is to handle first category selection in the initial setup
    localStorage.setItem('firstCatSelectionStatus', "false");
   }

   initialize(){
    const sources = [
      this.restaurantService.initialize(),
      this.categoriesService.initialize(),
      this.menuItemService.initialize(),
      this.menuSettingsService.initialize(),
      // this.ingredientsService.initialize(),
      // this.serviceItemsService.initialize(),
      this.roundOffService.initialize(),
      // this.paymentModesService.initialize(),
      this.upiDetailsService.initialize(),
      this.jokeService.initialize(),
      this.routeService.initialize()
    ]

    const sourcesWithCatch = sources.map(s => s.pipe(catchError(e => {
      return empty()
    })))

     concat(
      ...sourcesWithCatch
     ).subscribe(
        
       next => {

          switch(next.source){
            case 1: {
              this.restaurantService.load(next.result)
              break
            }

            case 2: {
              this.categoriesService.load(next.result)
              break
            }

            case 3: {
              this.menuItemService.load(next.result)
              break
            }

            case 4: {
              this.menuSettingsService.load(next.result)
              break
            }

            // case 5: {
            //   this.ingredientsService.load(next.result)
            //   break
            // }

            // case 6: {
            //   this.serviceItemsService.load(next.result)
            //   break
            // }

            case 7: {
              this.roundOffService.load(next.result)
              break
            }

            case 11: {
              this.paymentModesService.load(next.result)
              break
            }


            case 12: {
              this.upiDetailsService.load(next.result)
              break
            }

            case 13: {
              this.jokeService.load(next.result)
              break
            }

            case 10: {
              this.store.pipe(select(selectBusinessCategories)).subscribe(

                  next => {
                    if(!JSON.parse(localStorage.getItem('firstCatSelectionStatus'))){
                      let firstCatID = next[0].id
                      this.store.dispatch(new SaveCategoryIdAction(firstCatID))
                      localStorage.setItem('firstCatSelectionStatus', 'true')
  
  
                      this.router.navigate(['/restaurant', this.authService.getContext().restaurantId, 'home']);
                      localStorage.setItem("hasRunningSession", "true")
                      localStorage.setItem('doneWithPreloading', "true")
                      localStorage.setItem('isShownAddEatPay', 'false')
                      this.spinner.hide()
                    }
                },
                error => {
                  console.log("Error in loading business categories:", error)
                }
              )
             
              break
            }

            default: {
              break
            }

          }
       },
     )
     
   }


   initializeForOnlyMenu(){
    const sources = [
      this.categoriesService.initialize(),
      this.menuItemService.initialize(),
      this.menuSettingsService.initialize(),
      this.ingredientsService.initialize(),
      // this.categorySelectionService.initialize(),
      this.roundOffService.initialize(),
      this.routeService.initialize()
    ]

    const sourcesWithCatch = sources.map(s => s.pipe(catchError(e => {
      return empty()
    })))

     concat(
      ...sourcesWithCatch
     ).subscribe(
        
       next => {

          switch(next.source){
            

            case 2: {
              this.categoriesService.load(next.result)
              break
            }

            case 3: {
              this.menuItemService.load(next.result)
              break
            }

            case 4: {
              this.menuSettingsService.load(next.result)
              break
            }

            case 5: {
              this.ingredientsService.load(next.result)
              break
            }

            case 6: {
              this.categorySelectionService.load(next.result)
              break
            }

            case 7: {
              this.roundOffService.load(next.result)
              break
            }

            case 10: {

              this.store.pipe(select(selectBusinessCategories)).subscribe(
                next => {
                  if(!JSON.parse(localStorage.getItem('firstCatSelectionStatus'))){
                    let firstCatID = next[0].id
                    this.store.dispatch(new SaveCategoryIdAction(firstCatID))
                    localStorage.setItem('firstCatSelectionStatus', 'true')


                    this.router.navigate(['/restaurant', this.authService.getContext().restaurantId, 'menu']);
                    localStorage.setItem("hasRunningSession", "true")
                    localStorage.setItem('doneWithPreloading', "true")
                    this.spinner.hide()
                  }
               

                },
                error => {
                  console.log("Loading business categories error:", error)
                }
              )
              
             
              break
            }

            default: {
              break
            }

          }
       },
     )
     
   }
}
