import {EntityState, Dictionary, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import { MenuSettingsActions,MenuSettingsActionTypes } from '~app/actions/menu-setting.actions';
import { CategoryIndex } from '~app/models/menu-settings';

export class MenuSettingsState implements EntityState<CategoryIndex>{
    ids: string[] | number[];
    entities: Dictionary<CategoryIndex>;
}

export const adapter: EntityAdapter<CategoryIndex> = createEntityAdapter<CategoryIndex>(
    {
        selectId: (model: CategoryIndex) => model.index,
    }
);

let initialState: MenuSettingsState = adapter.getInitialState();

export function menuSettingsReducer(state=initialState, action: MenuSettingsActions){
    switch(action.type){
        case MenuSettingsActionTypes.LOAD_MENU_SETTINGS: {
            return state;
        }
        case MenuSettingsActionTypes.SAVE_MENU_SETTINGS: {
            let payload = action.payload
            console.log(payload)
            let menuSettings = [] 

            for(let p of payload){
                menuSettings.push({
                    ...p,
                })
            }

            return adapter.addMany(menuSettings, state);
        }

        case MenuSettingsActionTypes.REST_MENU_SETTINGS: {
            return undefined
        }

        case MenuSettingsActionTypes.SAVE_RELOADED__MENU_SETTINGS: {
            return adapter.setAll(action.payload, state)
        }

        
        default: {
            return state;
        }
    }
}

// get the selectors
const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = adapter.getSelectors();
   
  // select the array of user ids
  export const selectMenuSettingsIds = selectIds;
   
  // select the dictionary of user entities
  export const selectMenuSettingsEntities = selectEntities;
   
  // select the array of users
  export const selectAllMenuSettings = selectAll;
   
  // select the total user count
  export const selectMenuSettingsTotal = selectTotal