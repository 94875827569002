import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeLeft'
})
export class TimeLeftPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let d: Date = new Date(value)

    let year = d.getFullYear()
    let month = d.getMonth()+1
    let day = d.getDate()

    let hours = d.getHours()
    let minutes = d.getMinutes()
    let seconds = d.getSeconds()
    let millisec = d.getMilliseconds()

    let pa = (hours>11)?"PM":"AM"

    let result = "" + year+'/' +month + "/"+day+" - " + ("00"+hours).slice(-2) +":"+("00"+minutes).slice(-2)+" "+ pa
    return result
  }

}
