import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { InitiatePaymentAction, PaymentActionTypes, SavePaymentAction } from '~app/actions/payment.actions';
import { PaymentService } from '~app/services/payment.service';

@Injectable()
export class PaymentEffects {

    constructor(  
        private actions$: Actions,
        private paymentService:PaymentService
         ){}

    @Effect()
    IntiatePaymentAction$ = this.actions$.pipe(
        ofType<InitiatePaymentAction>(PaymentActionTypes.INITIATE_PAYMENT),
        switchMap((payload) => this.paymentService.paymentInitiate()
        .pipe(
            map(res => new SavePaymentAction(res))
        )),
        
    )
}