import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UpdateJokeAction } from '~app/actions/joke.actions';
import { Joke } from '~app/models/joke';
import { AppState } from '~app/reducers/main';

@Injectable({
  providedIn: 'root'
})
export class JokeService {

  jokeUrl = 'api/jokes/random/v1'

  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) { }

  getRandomJoke(): Observable<Joke>{
    let language = "english"

    let prefLanguage = localStorage.getItem("jokesLanguage")

    if(prefLanguage!=null){
      language = prefLanguage
    }

    return this.http.get<Joke>(this.jokeUrl+"?language="+language)
  }

  initialize(): Observable<any>{
    let defaultLanguage = "english"
    return this.http.get<any>(this.jokeUrl+"?language="+defaultLanguage).pipe(map(c => {return {source: 13, result: c}}));
  }
  
  load(next: any){
    if(next!=null){
      localStorage.setItem("jokesLanguage", "english")
      this.store.dispatch(new UpdateJokeAction(next));
    }
  }
}
