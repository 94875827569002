import * as fromPaymentModes from '../reducers/payment-modes.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PaymentMode } from '~app/models/payment-modes';

export interface State{
    paymentModes: fromPaymentModes.PaymentModesState
  }
  
  export const selectPaymentModeState = createFeatureSelector<fromPaymentModes.PaymentModesState>('paymentModes')

  export const selectPaymentModeIds = createSelector(
    selectPaymentModeState,
    (paymentModeState) => fromPaymentModes.selectPaymentModeIds(paymentModeState)
  )

  export const selectPaymentModeEntities = createSelector(
    selectPaymentModeState,
    (paymentModeState) => fromPaymentModes.selectPaymentModeEntities(paymentModeState)
  )


  export const selectPaymentModes = createSelector(
    selectPaymentModeIds,
    selectPaymentModeEntities,
    (ids, entities) => {

      let result: PaymentMode[] = []

      for(let id of ids){
        result.push(entities[id])
      }

      return result;
    }
  )