import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Upsale } from '~app/models/upsale';

@Injectable({
  providedIn: 'root'
})
export class UpsaleService {

  upsaleUrl = 'api/upsale-items'

  constructor(
    private http: HttpClient
  ) { }

  getUpsaleItem(upsaleID: string): Observable<Upsale>{
    return this.http.get<Upsale>(this.upsaleUrl+'/'+upsaleID);
  }
}
