import {JsonApiModelConfig, JsonApiModel, Attribute, HasMany, BelongsTo} from 'angular2-jsonapi';
import { listItem } from '~app/components/cart-summary/current-course/current-course.component';
import { Order } from './order.model';



@JsonApiModelConfig({
    type: 'rounds'
})

export class Round extends JsonApiModel {

    @Attribute()
    lineItems: listItem[];

    @Attribute()
    status:string;

    @Attribute()
    number:number;

    @BelongsTo({key: 'session-order'})
    sessionOrder:Order;
    
}