import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { CallWaiterResponse } from '~app/models/call-waiter';

@Injectable({
    providedIn: 'root'
})

export class CallWaiterService {
    callWaiterUrl='/api/call-waiter';

    constructor(private http: HttpClient) {
    }

   callwaiter():Observable<CallWaiterResponse>{
        return this.http.post<CallWaiterResponse>(this.callWaiterUrl,null)
    }

}
