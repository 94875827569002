import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-upi-payment-details-dialog',
  templateUrl: './upi-payment-details-dialog.component.html',
  styleUrls: ['./upi-payment-details-dialog.component.scss']
})
export class UpiPaymentDetailsDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<UpiPaymentDetailsDialogComponent>
  ) { }

  ngOnInit() {
  }


  close(){
    this.dialogRef.close({confirm: false})
  }

  confirm(){
    this.dialogRef.close({confirm: true})
  }

}
