import { themeActions, themeActionTypes } from '~app/actions/theme.actions';

export interface ThemeState{
    theme: boolean
}

let initialState: ThemeState = {
    theme: false
}

export function themeReducer(state=initialState, action: themeActions){
    switch(action.type){
        case themeActionTypes.CHANGE_THEME:{
            return {
                theme: !state.theme
            }
        }
        default: {
            return state;
        }
    }
}