import { Action } from '@ngrx/store';


export enum BasketActionTypes{
    UPDATE_BASKET ='[Basket Component] update basket',
    CLEAR_BASKET = '[Cart Component] clear basket'
}

export class UpdateBasket implements Action{

    readonly type = BasketActionTypes.UPDATE_BASKET

    constructor(public payload: any){}

}

export class ClearBasket implements Action{

    readonly type = BasketActionTypes.CLEAR_BASKET

}

export type BasketActions = UpdateBasket | ClearBasket