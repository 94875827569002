import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {RangeChoice} from '~models/range-choice';
import {Question} from '~app/models/question';
import {SurveyResponseService} from '~app/services/survey-response.service';
import {RangeResponse} from '~models/range-response';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
    statusFlag:boolean=false;
    @Input()
    question: Question;

    @Input()
    displayNext: boolean;

    @Input()
    value: number;

    formatLabel: string;

    @Input()
    surveyResponseService: SurveyResponseService;

    @Output()
    clickedNextButtonEvent = new EventEmitter();

    @Output()
    finalSubmitEvent = new EventEmitter();

    @Input()
    showSkeleton: boolean;

    sliderForm:  FormGroup;

    private initForm(){
        this.sliderForm = new FormGroup({
                slider: new FormControl("", Validators.required)
            });
    }

    constructor() {
    }

    ngOnInit() {
        this.initForm();
    }

    submitAnswer() {
       if(this.statusFlag === false){
        const answer = this.sliderForm.get('slider').value;
        this.surveyResponseService.recordResponse(this.question.id, new RangeResponse(answer));
       } 
          
        this.statusFlag=true;
    }


}
