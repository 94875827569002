import {EntityState, Dictionary, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import { categoriesActions, categoriesActionTypes } from '~app/actions/categories.actions';
import { Category } from '~app/models/category';

export class CategoriesState implements EntityState<Category>{
    ids: string[] | number[];
    entities: Dictionary<Category>;
}

export const adapter: EntityAdapter<Category> = createEntityAdapter<Category>();

let initialState: CategoriesState = adapter.getInitialState();

export function categoriesReducer(state=initialState, action: categoriesActions){
    switch(action.type){
        case categoriesActionTypes.LOAD_CATEGORIES: {
            return state;
        }
        case categoriesActionTypes.SAVE_CATEGORIES: {
            return adapter.addMany(action.payload, state);
        }

        case categoriesActionTypes.SAVE_RELOAD_CATEGORIES: {
            return adapter.upsertMany(action.payload, state);
        }

        case categoriesActionTypes.RESET_CATEGORIES: {
            return undefined
        }
        default: {
            return state;
        }
    }
}


// get the selectors
const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = adapter.getSelectors();
   
  // select the array of user ids
  export const selectCategoriesIds = selectIds;
   
  // select the dictionary of user entities
  export const selectCategoriesEntities = selectEntities;
   
  // select the array of users
  export const selectAllCategories = selectAll;
   
  // select the total user count
  export const selectCategoriesTotal = selectTotal



  export interface CategoryIdState{
      id: string
  }

  let initialCategoryId: CategoryIdState = {
      id: null
  } 

  export function categoryIdReducer(state=initialCategoryId, action: categoriesActions){
    switch(action.type){
        case categoriesActionTypes.SAVE_CATEGORY_ID: {
            return {
                id: action.payload
            }
        }
        default: {
            return state;
        }
    }
  }