import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActiveBanner} from '~models/active-banner';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BannerService {
    private static ACTIVE_BANNER_URL = '/api/banners/active-banner';

    constructor(private http: HttpClient) {

    }

    getActiveBanner(restaurantId: any): Observable<ActiveBanner> {
        return this.http.get<ActiveBanner>(BannerService.ACTIVE_BANNER_URL + '?restaurantId=' + restaurantId);
    }
               
}
