import { Component, OnInit } from '@angular/core';
import { RestaurantDataProvider } from '~app/services/restaurant-data-provider.service';

@Component({
  selector: 'app-spl-header',
  templateUrl: './spl-header.component.html',
  styleUrls: ['./spl-header.component.scss']
})
export class SplHeaderComponent implements OnInit {
 
  restaurantName:string;

  constructor(private restaurantData: RestaurantDataProvider) { }

  ngOnInit() {
    this.restaurantName=this.restaurantData.restaurant.name;
  }

}
