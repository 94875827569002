import * as fromOrder from '../reducers/order.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface State{
    cart: fromOrder.RoundState
}

export const selectOrderState = createFeatureSelector<fromOrder.RoundState>('order')


export const selectOrderIds = createSelector(
    selectOrderState,
    (orderState) => fromOrder.selectOrderIds(orderState)
  )

  export const selectOrderEntities = createSelector(
    selectOrderState,
    (orderState) => fromOrder.selectOrderEntities(orderState)
  )

  export const selectOrderCount = createSelector(
    selectOrderState,
    (orderState) => fromOrder.selectOrderTotal(orderState)
  )

  export const selectOrderItems = createSelector(
    selectOrderIds,
    selectOrderEntities,
    (ids, orderEntities) => {
      let final_result = []
      for(let id of ids){
          final_result.push(orderEntities[id])
          final_result.reverse();
      }
      return final_result

    }
)

export const selectBillableItems = createSelector(
  selectOrderIds,
  selectOrderEntities,
  (ids, orderEntities) => {
    let final_result = []
    for(let id of ids){
        final_result.push(...orderEntities[id].items)
    }
    return final_result

  }
)

export const selectTotalBill = createSelector(
  selectBillableItems,
    (items) => {
        let bill = 0
        for(let it of items){
            bill += it.quantity*(it.price - it.price*it.offer/100)
        }
        return bill
    }
)

