import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(
  ) { }

  initialize(){
    return of(false).pipe(map(s => {return {source: 10, result: s}}))
  }
}
