import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {Question} from '~app/models/question';
import {SurveyResponseService} from '~app/services/survey-response.service';
import {TextResponse} from '~models/text-response';
import { IfStmt } from '@angular/compiler';

@Component({
    selector: 'app-text-box',
    templateUrl: './text-box.component.html',
    styleUrls: ['./text-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextBoxComponent implements OnInit {

    statusFlag:boolean=false;

    @Input()
    answerText: string;

    @Input()
    question: Question;

    @Input()
    displayNext: boolean;

    @Input()
    surveyResponseService: SurveyResponseService;

    @Output() textAreaAnswerEvent = new EventEmitter<string>();

    @Output()
    finalSubmitEvent = new EventEmitter();

    @Output()
    clickedNextButtonEvent = new EventEmitter();

    @Input()
    showSkeleton: boolean;

    FinalSubmit: boolean = false;

    textBoxForm: FormGroup;

    saving: boolean;

    private initForm(){
        this.textBoxForm = new FormGroup({
            textBox: new FormControl('', Validators.required)
        });
    }

    constructor(private _formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.initForm();
    }

    submitAnswer(event: any) {  
        if(this.statusFlag === false){
            const answer = this.textBoxForm.get('textBox').value; 
            this.surveyResponseService.recordResponse(this.question.id, new TextResponse(answer));
            this.statusFlag=true;
        }
        
    }

}
