import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MenuItem } from '~app/models/menu-item';
import { Observable } from 'rxjs';
import { AddItemtoCartAction, UpdateItemQuantityAction } from '~app/actions/cart.actions';
import { AppState } from '~app/reducers/main';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { selectByItemId } from '~app/selectors/menu.selectors';
import { MenuItemComponent } from '../../items/menu-item/menu-item.component';

@Component({
  selector: 'app-detailed-menu-item',
  templateUrl: './detailed-menu-item.component.html',
  styleUrls: ['./detailed-menu-item.component.scss'],

})
export class DetailedMenuItemComponent implements OnInit {


  vegIcon = '../assets/icons/Veg_icon.svg';

  nonVegIcon = '../assets/icons/Nonveg_icon.svg';

  @Input() menuItem$: Observable<MenuItem>;

  @ViewChild(MenuItemComponent,{static: false}) menuItemComponent: MenuItemComponent ;



  menuItem: MenuItem;

  restaurantId: string;

  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.paramMap.subscribe(params => {
      this.activatedRoute.parent.paramMap.subscribe(p => {
        this.restaurantId = p.get('restaurantId');
        let itemId = params.get('itemId');
        this.menuItem$ = this.store.pipe(select(selectByItemId, { id: itemId }))
      }
      )
    });

    this.menuItem$.subscribe(next => {
      this.menuItem = next;
    }
    );
  }

  ngOnInit() {

  }

  addItem() {
    this.store.dispatch(new AddItemtoCartAction(this.menuItem))

  }

  changeCount(operation: string) {
    this.store.dispatch(new UpdateItemQuantityAction({ item: this.menuItem, operation: operation }))
  }

  changeImage(selectedImageUrl: string){
    this.menuItemComponent.viewImage(selectedImageUrl)
   }


}
