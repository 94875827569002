import { CategoriesState, adapter } from '~app/reducers/categories.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCategories from '../reducers/categories.reducer';

export interface State{
  categories: fromCategories.CategoriesState
}

export const selectCategoriesState = createFeatureSelector<fromCategories.CategoriesState>('categories')

export const selectCategoriesIds = createSelector(
  selectCategoriesState,
  (catState) => fromCategories.selectCategoriesIds(catState)
)

export const selectCategoriesEntities = createSelector(
  selectCategoriesState,
  (catState) => fromCategories.selectCategoriesEntities(catState)
)
