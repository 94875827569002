

/*
On clicking Add button,
we need to add selected to the cart

ADD_ITEM_TO_CART 

On increment we should increment the quanity

On decrement we should decrement the quantity

CORNER CASE:
    If quanity is <0, then we should delete the item from the cart

UPDATE_ITEM_QUANITY

*/

import { Action } from '@ngrx/store';


export enum CartActionTypes{
    ADD_ITEM_TO_CART ='[Cart Component] add item to cart',
    ADD_TO_CART = '[Cart component] add to cart',
    UPDATE_ITEM_QUANITY = '[Cart Component] Update item quantity',
    UPDATE_QUANTITY = '[Cart component] update quantity',
    CLEAR_CART = '[Cart Component] clear cart data',
    REMOVE_ITEM_FROM_CART = '[Cart Component] remove item from cart'
}


export class AddItemtoCartAction implements Action{
    readonly type = CartActionTypes.ADD_ITEM_TO_CART

    constructor(public payload: any){}
}

export class AddtoCartAction implements Action{
    readonly type = CartActionTypes.ADD_TO_CART

    constructor(public payload: any){}
}

export class UpdateItemQuantityAction implements Action{
    readonly type = CartActionTypes.UPDATE_ITEM_QUANITY

    constructor(public payload: any){}
}

export class UpdateQuantityAction implements Action{
    readonly type = CartActionTypes.UPDATE_QUANTITY

    constructor(public payload: any){}
}
export class ClearCartAction implements Action{
    readonly type = CartActionTypes.CLEAR_CART
}

export class RemoveItemFromCart implements Action{
    readonly type = CartActionTypes.REMOVE_ITEM_FROM_CART

    constructor(public payload: any){}
}
export type CartActions = AddItemtoCartAction | 
 AddtoCartAction | 
 UpdateItemQuantityAction |
  ClearCartAction |
  RemoveItemFromCart