import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '~app/reducers/main';
import { LoadingIndicatorService } from './loading-indicator.service';
import { Observable } from 'rxjs';
import { Ingredient } from '~app/models/ingredient';
import { map } from 'rxjs/operators';
import { SaveIngredientsAction, ReloadIngredientsAction } from '~app/actions/ingredients.actions';
import { LoadingContextMap } from '~app/constants/loading-context.constants';

@Injectable({
  providedIn: 'root'
})
export class IngredientsService {

  private ingredientsUrl = 'api/ingredients'

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private loadingIndicatorService: LoadingIndicatorService
  ) { }

  initialize(): Observable<any>{
    this.loadingIndicatorService.set(LoadingContextMap.LOADING_INGREDIENTS)
    return this.http.get<Ingredient[]>(this.ingredientsUrl).pipe(map(i => {return {source: 5, result: i}}))
  }

  load(next: any){
    this.store.dispatch(new SaveIngredientsAction(next))
  }

  reload(next: any){
    this.store.dispatch(new ReloadIngredientsAction(next));
  }

}
