import { Action } from '@ngrx/store';

export enum MenuSettingsActionTypes {
    LOAD_MENU_SETTINGS = '[Categories component] load menu settings ',
    SAVE_MENU_SETTINGS = '[Categories component] save save settings',
    REST_MENU_SETTINGS = '[Categories component] rest menu categories',
    RELOAD_MENU_SETTINGS = '[Categories Component] reload menu settings',
    SAVE_RELOADED__MENU_SETTINGS = '[Categories Component] save reloaded menu settings'
}

export class LoadMenuSettingsAction implements Action{
    readonly type = MenuSettingsActionTypes.LOAD_MENU_SETTINGS
    constructor(public payload: any){}
}

export class SaveMenuSettingsAction implements Action{
    readonly type = MenuSettingsActionTypes.SAVE_MENU_SETTINGS
    constructor(public payload: any){}
}

export class ResetMenuSettingsAction implements Action{
    readonly type = MenuSettingsActionTypes.REST_MENU_SETTINGS
}

export class ReloadMenuSettingsAction implements Action{
    readonly type = MenuSettingsActionTypes.RELOAD_MENU_SETTINGS
}

export class SaveReloadedMenuSettingsAction implements Action{

    readonly type = MenuSettingsActionTypes.SAVE_RELOADED__MENU_SETTINGS

    constructor(public payload: any){}
}

export type MenuSettingsActions = LoadMenuSettingsAction  | 
SaveMenuSettingsAction | 
ResetMenuSettingsAction |
ReloadMenuSettingsAction |
SaveReloadedMenuSettingsAction

