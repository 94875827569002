export class Order{
    amount: number
    currency: string
    receipt: string
    payment_capture: number

    constructor(amnt: number, curr: string, rcpt: string, payc: number){
        this.amount = amnt
        this.currency = curr
        this.receipt = rcpt
        this.payment_capture = payc
    }
}

export interface RzpayOrder{
        id: string
        entity: string
        amount: number
        amount_paid: number
        amount_due: number
        currency: string
        receipt: string
        offer_id: number
        status: string
        attempts: number
        notes: any[]
        created_at: number
}