import {JsonApiModelConfig, JsonApiModel, Attribute, HasMany, BelongsTo} from 'angular2-jsonapi';
import {ImageDownloadDescriptor} from './image-download-descriptor.model';
import {Image} from './image.model';

@JsonApiModelConfig({
  type: 'service-items'
})
export class ServiceItem extends JsonApiModel {

  @Attribute()
  id:string;

  @Attribute()
  name?: string;

  @Attribute()
  description: string;

  @BelongsTo({key: 'image-download-descriptor'})
  imageDownloadDescriptor: ImageDownloadDescriptor;

  @BelongsTo({key: 'image'})
  image: Image;
}
