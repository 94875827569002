import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {
  getAllDataFromLocalForage,
  default as localForage,
} from 'ngrx-store-persist';

if (environment.production) {
  enableProdMode();
}

getAllDataFromLocalForage({
  // driver: localForage.INDEXEDDB,
  driver: localForage.LOCALSTORAGE,
  // driverOrder: [['indexeddb', 'sqlite', 'websql', 'localstorage']],
  keys: [
    'restaurant',
    'theme',
    'diner',
    'ingredients',
    'categories',
    'categoryId',
    'menu',
    'menuId',
    'cart',
    'order',
    'paymentOrder',
    'dinerIdentity', 
    'round',
    'serviceItems',
    'websocketLog',
    'menuSettings',
    'webSocketConnectionStatus',
    'basket',
    'roundOff',
    'paymentModes',
    'upi',
    'joke'
  ],
}).then(() => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
})


