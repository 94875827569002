import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

export class position{
  x:number;
  y:number;

  constructor(xInput:number,yInput:number){
    this.x = xInput;
    this.y = yInput;
  }
}

@Injectable({
  providedIn: 'root'
})
  
export class cartLocationService{

  private cartPostion = new Subject<position>();
  public firstPosition;


  cartPostion$ = this.cartPostion.asObservable();

  changePosition(position){
    this.cartPostion.next(position);
    this.firstPosition = position;
  }

}
