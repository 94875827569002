import { Action } from '@ngrx/store';

export enum LoadingIndicatorActionTypes{
    SHOW_INDICATOR = '[Indicator Component] show indicator',
    HIDE_INDICATOR = '[Indicator Component] hide indicator'
}

export class ShowIndicatorAction implements Action{
    readonly type  = LoadingIndicatorActionTypes.SHOW_INDICATOR
}

export class HideIndicatorAction implements Action{
    readonly type = LoadingIndicatorActionTypes.HIDE_INDICATOR
}

export type loadingActions = ShowIndicatorAction | HideIndicatorAction