import * as fromBasket from '../reducers/basket.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface State{
    cart: fromBasket.BasketState
  }
  
  export const selectBasketState = createFeatureSelector<fromBasket.BasketState>('basket')

  export const selectBasketIds = createSelector(
    selectBasketState,
    (basketState) => fromBasket.selectBasketIds(basketState)
  )

  export const selectBasketEntities = createSelector(
    selectBasketState,
    (basketState) => fromBasket.selectBasketEntities(basketState)
  )
