import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PartyService } from '~app/services/party.service';
import { Party } from '~app/models/party';
import { AuthService } from '~app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from '~app/services/notifications.service';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { SnackBarMessageMap } from '~app/constants/snackbar-message.constants';

@Component({
  selector: 'app-joinparty-create',
  templateUrl: './joinparty-create.component.html',
  styleUrls: ['./joinparty-create.component.scss']
})
export class JoinpartyCreateComponent implements OnInit {

  createPartyForm: FormGroup;

  createParty="/assets/images/socialize.png";

  constructor(
    private partyService: PartyService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public loadingIndicatorService: LoadingIndicatorService,
    public spinner: NgxSpinnerService,
    public notificationService: NotificationsService,
  ) { }

  ngOnInit() {
    this.initForm()
  }

  private initForm() {
    this.createPartyForm = new FormGroup({
        // tslint:disable-next-line:max-line-length
        partyName: new FormControl('', 
        Validators.compose([Validators.minLength(2), Validators.required]))
    });
 }

 onSubmit(){

  this.loadingIndicatorService.set(LoadingContextMap.CREATING)
  this.spinner.show()
  
  let partyName = this.createPartyForm.get('partyName').value
   
  let restaurantID = this.authService.getContext().restaurantId
  let cartID = this.authService.getContext().cartID

  let p: Party = {
    name: partyName,
    joineeCount: 1,
    cartID: cartID,
    restaurantID: restaurantID
  }
  this.partyService.create(p).subscribe(
    next => {
      this.loadingIndicatorService.set(LoadingContextMap.SUCCESS)
      localStorage.setItem("partyID", next.id)
      localStorage.setItem("isPartyRunning", "true")
      localStorage.setItem('partyName', partyName)

      let restaurantID = this.authService.getContext().restaurantId

      this.spinner.hide()
      this.router.navigate(['restaurant',restaurantID, 'join-party'])


    },
    error => {
      this.notificationService.showMessage(SnackBarMessageMap.SOMETHING_WRONG)
      this.spinner.hide()
    }
  )


 }

}
