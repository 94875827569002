import { AppState } from "~app/reducers/main"
import { createSelector } from '@ngrx/store'
import { Restaurant } from '~app/models/restaurant'


export const selectRestaurantFeature = (state: AppState) => state.restaurant

export const selectRestaurant = createSelector(
    selectRestaurantFeature,
    (restaurant: Restaurant) => restaurant
)