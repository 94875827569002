import {EntityState, Dictionary, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import { categoriesActions, categoriesActionTypes } from '~app/actions/categories.actions';
import { MenuItem } from '~app/models/menu-item';
import { menuActions, menuActionTypes } from '~app/actions/menu.actions';

export class MenuState implements EntityState<MenuItem>{
    ids: string[] | number[];
    entities: Dictionary<MenuItem>;
}

export const adapter: EntityAdapter<MenuItem> = createEntityAdapter<MenuItem>();

let initialState: MenuState = adapter.getInitialState();

export function menuReducer(state=initialState, action: menuActions){
    switch(action.type){
        case menuActionTypes.LOAD_MENU: {
            return state;
        }
        case menuActionTypes.SAVE_MENU: {
            let payload = action.payload
            let menuList = [] 

            for(let p of payload){
                menuList.push({
                    ...p,
                    quantity: 0
                })
            }

            
            return adapter.setAll(menuList, state);
        }
        case menuActionTypes.RESET_MENU: {
            return undefined
        }

        case menuActionTypes.UPDATE_QUANTITY: {
            let payload = action.payload
            // id <- payload.item, diff <- payload.operation
            let diff = 0 

            if(payload.operation==='+'){
                diff = 1
            }else{
                diff = -1
            }


            let changes = {
                quantity:  (payload.item.quantity + diff)>=0?(payload.item.quantity + diff):0
            }
            return adapter.updateOne({id: payload.item.id, changes: changes }, state)
        }

        case menuActionTypes.RESET_QUANTITY: {
            let payload = action.payload

            
            const updates = []

            for(let p of payload){
                updates.push({
                    id: p.id,
                    changes: {
                        quantity: 0
                    }
                })
               
            }
            return adapter.updateMany(updates, state)
        }


        case menuActionTypes.SAVE_RELOAD_MENU: {
            let payload = action.payload
            let menuList = [] 

            for(let p of payload){
                menuList.push({
                    ...p,
                })
            }

            
            return adapter.upsertMany(menuList, state);
        }

        default: {
            return state;
        }
    }
}

// get the selectors
const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = adapter.getSelectors();
   
  // select the array of user ids
  export const selectMenuIds = selectIds;
   
  // select the dictionary of user entities
  export const selectMenuEntities = selectEntities;
   
  // select the array of users
  export const selectAllMenu = selectAll;
   
  // select the total user count
  export const selectMenuTotal = selectTotal


  export interface MenuIDState{
      id: any
  }

  let initialMenuId: MenuIDState = {
      id: null
  }

  export function menuIdReducer(state= initialMenuId, action: menuActions){
      switch(action.type){
          case menuActionTypes.SAVE_MENU_ITEM_ID: {
            return {
                id: action.payload
            }
          }
          default: {
              return state;
          }
      }
  }