import {EventEmitter, Injectable, NgZone} from '@angular/core';
import {Menu} from '~models/menu';
import {RestaurantService} from '~services/restaurant.service';
import {MenuService} from '~services/menu.service';
import {Observable} from 'rxjs';
import {MessageConstants} from '~app/utils/MessageConstants';
import {ActiveBanner} from '~models/active-banner';
import {BannerService} from '~services/banner.service';
import { NotificationsService } from './notifications.service';
import { Router } from '@angular/router';
import { Specials } from '~app/models/specials';
import { TodaySpecialsService } from './today-specials.service';
import { TodaySpecial } from '~app/models/today-special';
import { Restaurant } from '~app/models/restaurant';

@Injectable({
    providedIn: 'root'
})
export class RestaurantDataProvider {

    dataUpdated: EventEmitter<string> = new EventEmitter();

    public restaurant: Restaurant;
    public menu: Menu;
    public todaySpecials: TodaySpecial;

    constructor(private restaurantService: RestaurantService,
                private menuService: MenuService, private notificationsService: NotificationsService, private router: Router, private ngZone: NgZone,
                private todaySpecialService: TodaySpecialsService) {
    }

    public hasData(): boolean {
        return this.restaurant !== undefined && this.menu !== undefined;
    }

    public loadRestaurantAndMenuByCode(restaurantCode: string): Observable<string> {
        
        return new Observable<string>((observer) => {
            this.restaurantService.byCode(restaurantCode)
                .subscribe(
                    restaurant => {
                        // Currenlty backend returns empty response for invalid restaurant codes
                        if (restaurant === undefined) {
                            observer.error(new Error(MessageConstants.INVALID_RESTAURANT_CODE));
                            return; 
                        }

                        this.restaurant = restaurant;
                        this.menuService.getMenu(this.restaurant.id)
                            .subscribe(
                                menu => {
                                    this.menu = menu;
                                    observer.next(this.restaurant.id);
                                    this.dataUpdated.emit(Date.now().toString());
                                    return;
                                },
                                error => {
                                    observer.error(new Error(MessageConstants.FAILED_TO_LOAD_MENU));
                                    return;
                                }
                            );

                        // this.todaySpecialService.getTodaySpecials(restaurant.id).subscribe(
                        //     next => {
                        //         this.todaySpecials = next;
                        //     },
                        //     error => {
                        //         alert("Error in getTodaySpecial");
                        //     }
                        // );
                    },
                    error => {
                        observer.error(new Error(MessageConstants.INVALID_RESTAURANT_CODE));
                        return;
                    }
                );
        });
    }

    public loadRestaurantAndMenuById(restaurantId: string): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.restaurantService.byId(restaurantId)
                .subscribe(
                    restaurant => {

                        // Currenlty backend returns empty response for invalid restaurant codes
                        if (restaurant === undefined) {
                            observer.error(new Error(MessageConstants.INVALID_RESTAURANT_CODE));
                            return;
                        }

                        this.restaurant = restaurant;

                        this.menuService.getMenu(this.restaurant.id)
                            .subscribe(
                                menu => {
                                    this.menu = menu;
                                    observer.next(true);
                                    this.dataUpdated.emit(Date.now().toString());
                                    return;
                                },
                                error => {
                                    observer.error(new Error(MessageConstants.FAILED_TO_LOAD_MENU));
                                    return;
                                }
                            );
                            // this.todaySpecialService.getTodaySpecials(restaurant.id).subscribe(
                            //     next => {
                            //         this.todaySpecials = next;
                            //     },
                            //     error => {
                            //        alert("Error in getTodaySpecial");
                            //     }
                            // );
                    },
                    error => {
                        observer.error(new Error(MessageConstants.INVALID_RESTAURANT_CODE));
                        return;
                    }
                );
        });
    }
}
