import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddRoundOffIemsAction } from '~app/actions/round-off.actions';
import { Restaurant } from '~app/models/restaurant';
import { RndOff } from '~app/models/round-off';
import { AppState } from '~app/reducers/main';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoundOffService {

  roundOffUrl = '/api/round-off-items'

  restaurant$: Observable<Restaurant>

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private authService: AuthService
  ) { 

  }

  initialize(): Observable<any>{

    let  roundOffID = this.authService.getContext().roundOffID 
    return this.http.get<any>(this.roundOffUrl+'/'+roundOffID).pipe(map(c => {return {source: 7, result: c}}));
  }

  load(next: any){

    let  roundOffID = this.authService.getContext().roundOffID 
  
    let rnOf: RndOff = {
      id: roundOffID,
      itemIDs: next
    }
    this.store.dispatch(new AddRoundOffIemsAction(rnOf))
  }

  getRoundOffItems(roundOffID: string): Observable<any>{
    return this.http.get<any>(this.roundOffUrl+'/'+roundOffID);
  }
}
