import { Action } from '@ngrx/store';


export enum dinerActionTypes{
    SAVE_DINER = '[Save Diner Component] save diner',
    UPDATE_DINER_IDENTITY = '[Session Component] Update diner identity',
    RESET_DINER_IDENTITY = '[Session Component] reset diner identity',
    COMPLETE_DINING = '[Session Component] Complete dining'

}

export class SaveDinerAction implements Action{
    readonly type = dinerActionTypes.SAVE_DINER;

    constructor(public payload: any){}
}

export class UpdateDinerIdentityAction implements Action{
    readonly type = dinerActionTypes.UPDATE_DINER_IDENTITY

    constructor(public payload:any){}
}

export class ResetDinerIdentity implements Action{
    readonly type = dinerActionTypes.RESET_DINER_IDENTITY
}

export class CompleteDiningAction implements Action{
    readonly type = dinerActionTypes.COMPLETE_DINING
}

export type dinerActions = SaveDinerAction | 
UpdateDinerIdentityAction |
ResetDinerIdentity|
CompleteDiningAction