
export interface PaymentOrder{
    id: string
    amount: number
    amount_paid: number
    amount_due: number
    currency: string
    receipt: string
    status: string
    attempts: number
    entity: string
    notes: any
    created_at: number
    orderID?:string
    paymentID?:string
    isPaid?:boolean
    mode:string
}


export class PaymentCapture{
    razorpayOrderID: string
    razorpayPaymentID: string
    razorpaySignature: string

    constructor(rzOrderID: string, rzPaymentID: string, rzSignature: string){
        this.razorpayOrderID = rzOrderID
        this.razorpayPaymentID = rzPaymentID
        this.razorpaySignature = rzSignature
    }
}