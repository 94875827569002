import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PaymentMode } from '~app/models/payment-modes';
import { Restaurant } from '~app/models/restaurant';
import { UPI, UpiDetail } from '~app/models/upi-details';
import { AppState } from '~app/reducers/main';
import { selectPaymentModes } from '~app/selectors/payment-modes.selectors';
import { NotificationsService } from '~app/services/notifications.service';
import { UpiDetailsService } from '~app/services/upi-details.service';

@Component({
  selector: 'app-payment-modes',
  templateUrl: './payment-modes.component.html',
  styleUrls: ['./payment-modes.component.scss']
})
export class PaymentModesComponent implements OnInit {

  @Input() bill : number
  
  cashPaymentIcon='../assets/icons/cash.png'
  cardPaymentIcon='../assets/icons/credit.png'
  upiPaymentIcon='../assets/icons/upi.svg'
  
  paymentModes$: Observable<PaymentMode[]>

  cashMode: PaymentMode
  cardMode: PaymentMode
  upiMode: PaymentMode

  // upiPaymentUrl = 'upi://pay?pa=9886100232@hdfcbank&amp;pn=SURESH PODETI&amp;am=1.56&amp;tn=Dining at peacock&amp;cu=INR'

  upiDetail: UpiDetail

  restaurant$: Observable<Restaurant>

  restaurant: Restaurant
    
  upi$: Observable<UPI>

  showUpiId = false;
  showUpiName = false;

  constructor(
    private store: Store<AppState>,
    private upiDetailService: UpiDetailsService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationsService: NotificationsService
  ) {
    this.paymentModes$ = this.store.pipe(select(selectPaymentModes))
    this.restaurant$ = this.store.pipe(select('restaurant'))
    this.upi$ = this.store.pipe(select('upi'))
   }

  ngOnInit() {
    this.paymentModes$.subscribe(
      next => {
        for(let p of next){
          if(p.name.toUpperCase()=='CASH'){
            this.cashMode = p
          }else if(p.name.toUpperCase()=='CARD'){
            this.cardMode = p
          }else if(p.name.toUpperCase() == 'UPI'){
            this.upiMode = p
          }

        }
      },
      error => {
        console.log("error in getting payment modes:", error)
      }
    )


    this.upiDetailService.getUpiDetails().subscribe(
      next => {
        this.upiDetail = next
      },
      error => {
        console.log("Error in getting upi details:", error)
      }
    )
      
    this.restaurant$.subscribe(
      next => {
        this.restaurant = next
      },
      error => {
        console.log("error in getting restaurant details:", error )
      }
    )

  }

  openUPIApp(upiDetail: UpiDetail, bill: number){
    let url = 'upi://pay?pa='+upiDetail.upiAddress+'&amp;pn='+upiDetail.holderName+'&amp;am='+bill+'&amp;tn=Dining at '+ this.restaurant.name+'&amp;cu=INR'

    return this.sanitizer.bypassSecurityTrustUrl(url)

  }

  upiPaymentVerify(){
    this.router.navigate(['../upi-payment-verify'],{relativeTo: this.activatedRoute})
  }

  toggleVisibility() {
    this.showUpiName = !this.showUpiName;
    this.showUpiId = !this.showUpiId;
  }

  copied(event: any) {
    // Handle copy success feedback here
    this.notificationsService.showMessageWithDuration('UPI ID Copied!')
  }

}
