import { Action } from '@ngrx/store';


export enum serviceItemsActionTypes {

    LOAD_SERVICE_ITEMS ='[Service item Component] Load Service-item' ,
    SAVE_SERVICE_ITEMS ='[Service item Component] Save Service-item' ,
    REST_SERVICE_ITEMS ='[Service item Component] Reset Service-item'

}

export class LoadServiceITemAction implements Action{
    readonly type = serviceItemsActionTypes.LOAD_SERVICE_ITEMS
}

export class SaveServiceItemsAction implements Action{
    readonly type = serviceItemsActionTypes.SAVE_SERVICE_ITEMS
    constructor(public payload: any){}
}

export class ResetServiceItemsAction implements Action{
    readonly type = serviceItemsActionTypes.REST_SERVICE_ITEMS
}

export type serviceItemsActions = 
LoadServiceITemAction | 
SaveServiceItemsAction |
ResetServiceItemsAction