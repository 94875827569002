import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UpdateUPIAction } from '~app/actions/upi.actions';
import { UpiDetail } from '~app/models/upi-details';
import { AppState } from '~app/reducers/main';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UpiDetailsService {

  upiDetailsUrl = 'api/upi-details'

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private store: Store<AppState>
  ) { }

  initialize(): Observable<any>{
    let restaurantID = this.authService.getContext().restaurantId
  
    return this.http.get<any>(this.upiDetailsUrl+'?restaurantID='+restaurantID).pipe(map(c => {return {source: 12, result: c}}));
  }
  
  load(next: any){
    if(next!=null){
      this.store.dispatch(new UpdateUPIAction(next));
    }
  }

  getUpiDetails(): Observable<UpiDetail>{
    let restaurantID = this.authService.getContext().restaurantId

    return this.http.get<UpiDetail>(this.upiDetailsUrl+'?restaurantID='+restaurantID)
  }
}




