import { UPI } from "~app/models/upi-details"
import { UPIActions, UPIPActionsTypes } from "../actions/upi.actions"

let initialState: UPI =  undefined

export function UPIReducer(state=initialState, action: UPIActions){

    switch(action.type){
        case UPIPActionsTypes.UPDATE_UPI: {
            let payload = action.payload
            return {
                id: payload.id,
                upiAddress: payload.upiAddress,
                holderName: payload.holderName
            }
        }
        
        default: {
            return state
        }
    }
   
}