import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
  }

  onSkip(){
    this.router.navigate(['../thankyou'], {relativeTo: this.activatedRoute})
  }

  goToKittyFeedback(){
    this.router.navigate(['../kitty-feedback'], {relativeTo: this.activatedRoute})
  }

}
