import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { RestaurantDataProvider } from '~app/services/restaurant-data-provider.service';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppState } from '~app/reducers/main';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ThemeState } from '~app/reducers/theme.reducer';
import { ChangeThemeAction } from '~app/actions/theme.actions';
import { Sidenavstate } from '~app/reducers/sidenavtoggle.reducer';
import { Sidenavtoggleaction, CloseSideNavbarAction } from '~app/actions/sidenav.action';
import { WebsocketEventService } from '~app/services/websocket-event.service';
import { WebsocketMessage, WMessage } from '~app/models/websocket-message';
import { ChangeWebSocketConnectionStatusAction, GotEventAction, WebsocketConnectionOnAction } from '~app/actions/websocket-log.actions';
import { EventList } from '~app/constants/event-types.constants';
import { WebSocketConnectionStatusState } from '~app/reducers/websocket-log.reducer';
import { AuthService } from '~app/services/auth.service';
import { Restaurant } from '~app/models/restaurant';
import { CallWaiterService } from '~app/services/call-waiter.service';
import { NotificationsService } from '~app/services/notifications.service';
import { SnackBarMessageMap } from '~app/constants/snackbar-message.constants';
import { selectCartCount } from '~app/selectors/cart.selectors';
import { MatSnackBar, MatSnackBarRef } from '@angular/material';
@Component({
  selector: 'app-restaurant-home',
  templateUrl: './restaurant-home.component.html',
  styleUrls: ['./restaurant-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantHomeComponent implements OnInit {

  open: boolean = true;
  isAuthenticated: boolean;
  webSocketConnection$:Observable<WebSocketConnectionStatusState>;

  cartCount$:Observable<number>

  isDarkTheme$: Observable<ThemeState>;
  toggle$: Observable<Sidenavstate>;
  restaurant: Restaurant;
  restaurant$: Observable<Restaurant>

  categoryId: string;

  message: WebsocketMessage

  isStaticQRCantOrder: boolean

  onlyMenu: boolean = false

  constructor(
    public restaurantData: RestaurantDataProvider,
    private router: Router,
    private store: Store<AppState>,
    private websocketEventService: WebsocketEventService,
    private authService: AuthService,
    private callWaiterService:CallWaiterService ,
    private notificationService: NotificationsService,
    private _snackBar: MatSnackBar
    ) {
      if(localStorage.getItem('auth')==null){
        this.router.navigate(['/'])
      }
      this.webSocketConnection$=this.store.pipe(select('webSocketConnectionStatus'))
      
      this.store.pipe(select('restaurant')).subscribe(
        next => {
          this.restaurant = next
        }
      )
    }

  ngOnInit() {
   this.store.pipe(select(selectCartCount))

    if(localStorage.getItem('onlyMenu')!=null && localStorage.getItem('onlyMenu')=='true'){
      this.onlyMenu = true
    }

    let currentUserSessionID = this.authService.getContext().sessionId

    if(this.restaurant.orderingEnabled && !this.restaurant.staticQRCantOrder && localStorage.getItem('onlyMenu')!=null && localStorage.getItem('onlyMenu')!='true'){
      this.websocketEventService.connect().subscribe(
        (next: WMessage) => {
          this.store.dispatch(new WebsocketConnectionOnAction())
          
          if (next.type && next.sessionID==currentUserSessionID) {
            
            console.log("message received:", next)
  
            let websocketMessage: WebsocketMessage = {
              id: next.eventID,
              type: next.type,
              restaurantID: next.restaurantID,
              sessionID: next.sessionID,
              data: next.data
            }
  
            //TODO: we need to check if this event is already there
           
            this.store.dispatch(new GotEventAction(websocketMessage))
  
          }
  
        }
     
      )
    }
   


    this.restaurant = this.restaurantData.restaurant;
        // Subscribe to restaurant details when updated
        this.restaurantData.dataUpdated.subscribe(updatedDate => {
            this.restaurant = this.restaurantData.restaurant;

            this.restaurantData.loadRestaurantAndMenuByCode(this.restaurant.code)
            .subscribe(
            result => {
                this.categoryId = this.restaurantData.menu.getCategories()[0].id;
                // this.router.navigate(['/restaurant', result, 'menu'], {queryParams: {category: catId}});
            }
        );
        });
      
    this.isDarkTheme$ = this.store.pipe(select('theme'))
    this.restaurant$ = this.store.pipe(select('restaurant'))
    this.toggle$ = this.store.pipe(select('sidenavtoggle'))
      
  }

  openSideDrawer(sidenav: any){
    // sidenav.toggle()
    this.store.dispatch(new Sidenavtoggleaction())
  }

  toggleTheme(){
    this.store.dispatch(new ChangeThemeAction())
  }
  togglesidenav(){
    this.store.dispatch(new Sidenavtoggleaction())
  }

  onClose(){
    this.store.dispatch(new CloseSideNavbarAction())
  }


  callWaiter(){
   this.callWaiterService.callwaiter().subscribe(
     next=>{
     this.notificationService.showMessage(SnackBarMessageMap.CALL_WAITER_SUCCESS)
     },
    error =>{
      // this.notificationService.showMessage(SnackBarMessageMap.SOMETHING_WRONG)
    }
   )
  }
}
