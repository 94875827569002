import { Injectable } from '@angular/core';
import { Datastore } from '~app/jsonapi/store-service';
import { Round } from '~app/jsonapi/round.model';
import { AppState } from '~app/reducers/main';
import { Store } from '@ngrx/store';
import { AddRoundAction, UpdateRoundAction } from '~app/actions/round.actions';

@Injectable({
  providedIn: 'root'
})
export class RoundsService {

  constructor(
    private dataStore: Datastore,
    private store: Store<AppState>
  ) { }

  update(payload: any){

    this.dataStore.findRecord(Round, payload.data.roundID).subscribe(
      next => {
        
      let round = {
        id: payload.data.roundID,
        roundNumber: next.number,
        status: next.status,
        lineItems: next.lineItems,
        orderID: payload.data.orderID,
        sessionID: payload.sessionID,
        restaurantID: payload.restaurantID,
        timeStamp: payload.data.timeStamp
      }
  
      this.store.dispatch(new AddRoundAction(round))
      },
      error => {
        console.log("error in getting round details by id")
      }
    )
    


  }


  updateRound(payload: any){
    let roundID = payload.data.roundID

    //TODO: Updating round goes here


    //STEP1: get the updated round from backend using roundID


    //STEP2: Update the store for round with given round ID

    this.dataStore.findRecord(Round, roundID).subscribe(
      next => {
        
      let round = {
        id: payload.data.roundID,
        roundNumber: next.number,
        status: next.status,
        lineItems: next.lineItems,
        orderID: payload.data.orderID,
        sessionID: payload.sessionID,
        restaurantID: payload.restaurantID,
        timeStamp: payload.data.timeStamp
      }
  
      this.store.dispatch(new UpdateRoundAction(round))
      },
      error => {
        console.log("error in updating round details by id:", error)
      }
    )

  }
}
