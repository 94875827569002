import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { KnowYourProduct } from '~app/models/know-your-product';
import { MenuItem } from '~app/models/menu-item';
import { KnowYourProductService } from '~app/services/know-your-product.service';
import { KnowYourProductCrossDialogComponent } from './know-your-product-cross-dialog/know-your-product-cross-dialog.component';
import { KnowYourProductUpsaleDialogComponent } from './know-your-product-upsale-dialog/know-your-product-upsale-dialog.component';


export interface item {
  index: number,
  veg: boolean,
  name: string,
  price: number,
  quantity: number,
  imageUrl?: string
  upSaleItemsIndex?: number[],
  crossSaleItemsIndex?: number[]
}



@Component({
  selector: 'app-know-your-product',
  templateUrl: './know-your-product.component.html',
  styleUrls: ['./know-your-product.component.scss']
})
export class KnowYourProductComponent implements OnInit {

  isCartShowed: boolean = false;
  isUpSaleDialogOpen:boolean =false;
  isCrossSaleDialogOpen:boolean = false ;
  isUpSaleItemAdded:boolean = false;
  isResponseSumbit:boolean = false ;
  isCokeAdded:boolean = false;
  isFrenchrfiesAdded:boolean =false;
  isCokeAndFrenchfriesAdded:boolean =false ;



  addedItems: item[] = []

  testimonials=[
    {
      "name":"Vikram",
      "restaurant": "Palnati's",
      "profile": '../assets/images/vikram.png',
      "statement": "I was using this product from the begining of my restaurant. Customers are liking it. Menu managment is easy. I need to specially mention sales techniques working amazingly well!"
    },
    {
      "name":"Ranjith",
      "restaurant": "Sampradaya",
      "profile": '../assets/images/ranjith.png',
      "statement": "KittyCat app is simple to use, it's amazing. Upsell techinique is really working well. We could able to improve our business by 5% every month. Thanks for KittyCat team!"
    },
    {
      "name":"Shameel",
      "restaurant": "Hadi's Cafe",
      "profile": '../assets/images/shameel.png',
      "statement": "Selling techniues are improving my business. I liked round off technique that is improving my bussiness. Small extra benifit daily leading to a bigger benifit for month."
    }
  ]

  items: item[] = [
    {
      "index": 1,
      "veg": true,
      "name": "veg classic burger (small)",
      "price": 99,
      "imageUrl": '../assets/images/burger.png',
      "quantity": 0,
      "upSaleItemsIndex": [2],
      "crossSaleItemsIndex": []
    }, {
      "index": 2,
      "veg": true,
      "name": "veg classic burger (medium)",
      "imageUrl": '../assets/images/burger.png',
      "price": 129,
      "quantity": 0,
      "upSaleItemsIndex": [],
      "crossSaleItemsIndex": [3, 4]
    },
    {
      "index": 3,
      "veg": true,
      "name": "french fries",
      "price": 60,
      "quantity": 0,
      "imageUrl": '../assets/images/french-fries.png',
      "upSaleItemsIndex": [],
      "crossSaleItemsIndex": []
    }, {
      "index": 4,
      "veg": true,
      "name": "coke (250 ml)",
      "price": 30,
      "imageUrl": '../assets/images/cold-drink.png',
      "quantity": 0,
      "upSaleItemsIndex": [],
      "crossSaleItemsIndex": []
    }
  ]


  vegIcon = '../assets/icons/Veg_icon.svg';

  lightingBoltIcon = '..//assets/icons/lightingBoltIcon.svg'

  nonVegIcon = '../assets/icons/Nonveg_icon.svg';
  knowYourProductForm: FormGroup;
  userMobileNumber: string;

  kOptions = ["<5", "<10", ">10"]


  constructor(public dialog: MatDialog,
    private knowYourProductService: KnowYourProductService,
    private activatedRoute: ActivatedRoute) {

  }

  initForm() {
    this.knowYourProductForm = new FormGroup({
      tables: new FormControl('',
        Validators.compose(
          [Validators.minLength(2),
          Validators.required,
          ]
        ))
    })
  }

  load(): void {
    this.activatedRoute.params.subscribe(paramsId => {
      this.userMobileNumber = paramsId.mobilenumber;
    }
    );
  }

  ngOnInit() {
    this.items
    this.initForm()
    this.load()
  }


  addItem(additem: item) {

   

    if (additem.upSaleItemsIndex.length > 0) {

      let uSaleItems = []

      for (let i of additem.upSaleItemsIndex) {
        uSaleItems.push(this.items.filter(e => e.index == i)[0])
      }
      const uSaleDialogRef = this.dialog.open(KnowYourProductUpsaleDialogComponent, {
        data: uSaleItems,
        width: "320px",
        disableClose: true
      });
      uSaleDialogRef.afterClosed().subscribe(result => {
        this.isUpSaleDialogOpen=true
        if (result.isUpSaleItemSelected) {
          this.addItemToCart(result.uitem)
          this.isUpSaleItemAdded=true
        }
        else{
          this.addItemToCart(additem)
        }
      });
    }



    else if (additem.crossSaleItemsIndex.length > 0) {
      let cSaleItems = []
      for (let i of additem.crossSaleItemsIndex) {
        cSaleItems.push(this.items.filter(e => e.index == i)[0])
      }
      const cSaleDialogRef = this.dialog.open(KnowYourProductCrossDialogComponent, {
        data: cSaleItems,
        width: "320px",
        disableClose: true
      });

      cSaleDialogRef.afterClosed().subscribe(result => {
        this.isCrossSaleDialogOpen =true

        if (result.isCrossSaleItemSelected) {

          if (result.isMultiSeleted) {
             for(let i of cSaleItems){
               this.addItemToCart(i)
             }
             this.isCokeAndFrenchfriesAdded =true ;
            this.addItemToCart(additem)
          } else {
            this.addItemToCart(result.citem)
            if(result.citem.index === 3){
              this.isCokeAdded = true
            }
            if(result.citem.index === 4){
               this.isFrenchrfiesAdded = true
            }
            this.addItemToCart(additem)
          }
        }
        else {
          this.addItemToCart(additem)
        }
      });
    }


    else {
      this.addItemToCart(additem)
    }
  }

  showCart() {
      this.isCartShowed = true;
      // document.getElementById('container-c').scroll({top:800,behavior:'smooth'});
  }

  submitResponse(event){
     if(!this.isResponseSumbit){
      let tables = this.knowYourProductForm.get('tables').value
      let mNumber = this.a0j9Decoder(this.userMobileNumber)
  
      if (tables !== "" && mNumber.length == 10) {
        let kResponse: KnowYourProduct = {
          tables: tables,
          mobileNumber: mNumber
        }
        console.log(kResponse)
       
        this.knowYourProductService.submitknowYourProductResponse(kResponse).subscribe(
          next => {
              this.isCartShowed = true; 
          },
          errpr =>{
            console.log("hee")
          }
        )
       
        this.isResponseSumbit=true
    }
    }
    
  }

  addItemToCart(additem: item) {
   
    let addItemIndex = this.items.findIndex((obj => obj.index == additem.index));
    this.items[addItemIndex].quantity = 1
    this.addedItems.push(this.items[addItemIndex])
    this.addedItems = [... new Map(this.addedItems.map(item => [item.index, item])).values()]
  }

  goToKittyCatOnPlayStore() {
    window.open(
      "https://play.google.com/store/apps/details?id=com.rupant.KittyCat&hl=en", "_blank");
  }

  a0j9Decoder(string: String) {
    let aToJString = 'ABCDEFGHIJ'
    let deCodedString = ""
    let stringText = string.toUpperCase()
    for (let i of stringText) {
      let index = aToJString.indexOf(i)
      if( index > -1){
        deCodedString += index.toString();
      } 
    }
    return deCodedString
  }

}
