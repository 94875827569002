import { Injectable } from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects'
import { switchMap, map, catchError } from 'rxjs/operators';
import { MenuSettingsActionTypes, ReloadMenuSettingsAction, SaveReloadedMenuSettingsAction, ResetMenuSettingsAction } from '~app/actions/menu-setting.actions';
import { MenuSettingsService } from '~app/services/menu-setting.service';

@Injectable()
export class MenuSettingEffects {

    constructor(
        private actions$: Actions, 
        private menuSettingsService: MenuSettingsService,
        ){}

    @Effect()
    ReloadMenuSettingsAction$ = this.actions$.pipe(
        ofType<ReloadMenuSettingsAction>(MenuSettingsActionTypes.RELOAD_MENU_SETTINGS),
        switchMap(() => this.menuSettingsService.getMenuSettings()
        .pipe(
            map(res => new SaveReloadedMenuSettingsAction(res.categories))
        ))
    )
}
