import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {

  qrCodeUrl  = '/api/generate-qr?trackingID='

  constructor(
    private http: HttpClient
  ) { }

  getQrImage(trackingID: string): Observable<Blob> {
    //TODO: tracking ID is hard code please change it
    return this.http.get(this.qrCodeUrl+trackingID, { responseType: 'blob'})
  }
}
