import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { JsonApiQueryData } from 'angular2-jsonapi';
import { Round } from '~app/jsonapi/round.model';
import { Datastore } from '~app/jsonapi/store-service';
import { AppState } from '~app/reducers/main';
import { PaymentService } from '~app/services/payment.service';
import { WindowRefService } from '~app/services/window-ref.service';
import { Observable } from 'rxjs';
import { PaymentOrder } from '~app/models/payment';
import { UpdatePaymentAction } from '~app/actions/payment.actions';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-payment-final',
  templateUrl: './payment-final.component.html',
  styleUrls: ['./payment-final.component.scss']
})
export class PaymentFinalComponent implements OnInit {

  rupantLogo = '../assets/icons/rupant_clr.png'
  rounds: Round[];
  totalBill: number;

  razorPayOrderID: string

  paymentOrder$: Observable<PaymentOrder>
  
  constructor(private winRef: WindowRefService,
               private store: Store<AppState>,
               private dataStore:Datastore,
               private paymentService:PaymentService,
               public router: Router,
               private activatedRoute: ActivatedRoute,
               private spinner: NgxSpinnerService
               ) 
               {
                this.paymentOrder$ = this.store.pipe(select('paymentOrder'))

                this.paymentOrder$.subscribe(
                  next =>{
                    this.razorPayOrderID = next.id
                  },
                  error => {
                    console.log("error in paynow:", error)
                  }
                )

                }

  ngOnInit() {
    
  }

  payWithRazor(razorpayOrderID: string) {
    const options: any = {
      // key: 'rzp_live_AKNanbajHYqGSd',
      // amount: 0 , // amount should be in paise format to display Rs 1255 without decimal point
      // currency: 'INR',
      name: 'KITTYCHEF', // company name or product name
      description: razorpayOrderID,  // product description
      image: '../../assets/icons/logo.png', // company logo or product image
      order_id: razorpayOrderID, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#bdbdbd'
      }
    };


    options.handler = ((response, error) => {
      options.response = response;

      let razorpay_order_id = response["razorpay_order_id"]
      let razorpay_payment_id = response["razorpay_payment_id"]
      let razorpay_signature = response["razorpay_signature"]


      // call your backend api to verify payment signature & capture transaction
      this.paymentService.verifyPayment(razorpay_order_id, razorpay_payment_id, razorpay_signature).subscribe(
        next => {
          this.store.dispatch(new UpdatePaymentAction({paymentID: razorpay_payment_id ,orderID :razorpay_order_id}))
          this.router.navigate(['../payment-success'], { relativeTo: this.activatedRoute});
          console.log("verification success:", next)
        },
        error => {
          console.log("Error in verifying payment:", error)
        }
      )
    });


    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      this.router.navigate(['../payment-initiate'], { relativeTo: this.activatedRoute});
      console.log('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

payNow(){
       this.payWithRazor(this.razorPayOrderID);
  }


  backToPaymentIntiate(){
    this.router.navigate(['../payment-initiate'], { relativeTo: this.activatedRoute});
  }
}
