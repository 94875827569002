import {Component, Input, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {Ingredient} from '~models/ingredient';

@Component({
    selector: 'app-ingredients',
    templateUrl: './ingredients.component.html',
    styleUrls: ['./ingredients.component.scss']
})
export class IngredientsComponent implements OnInit {

    @Input()
    ingredients: Ingredient[];

    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {

    }

    ngOnInit() {
        for (const ingredient of this.ingredients) {
            if (ingredient !== undefined) {
                this.matIconRegistry.addSvgIcon(ingredient.name,
                    this.domSanitizer.bypassSecurityTrustResourceUrl(ingredient.imageUrl));
            }
        }
    }

}
