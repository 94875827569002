import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth-interceptor';
import { ConnectionLostInterceptor } from './connection-lost.interceptor';



export const httpInterceptorProviders = [
//   { provide: HTTP_INTERCEPTORS, useClass: ConnectionLostInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
