import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuickItemSkeletonService {
  private quickItemSkeleton: boolean;
  constructor() { }

  get(){
    return this.quickItemSkeleton;
  }

  update(status: boolean){
    return this.quickItemSkeleton = status;
  }
}
