import {Tag} from '~models/tag';
import {MenuItem} from '~models/menu-item';
import {Ingredient} from '~models/ingredient';
import {ActiveBanner} from '~models/active-banner';
import { Specials } from './specials';

export class TodaySpecial{
    private TodaySpcialItemsMap = new Map<string, MenuItem>();
    private ingredientsMap = new Map<string, Ingredient>();

    private imagesUrlPath = '/api/images/';

    constructor(private restaurantId: string,
                private items: Specials,
                private ingredients: Ingredient[]
                ) {

        for (const ingredient of this.ingredients) {
            this.ingredientsMap.set(ingredient.id, ingredient);
        }

        for (const item of items.items) {
            item.mainImageUrl = item.imageUrls[0];

            this.TodaySpcialItemsMap.set(item.id, item);
            if (item.ingredientIds !== undefined && item.ingredientIds != null) {
                const itemIngredients: Ingredient[] = [];
                for (const ingId of item.ingredientIds) {
                    itemIngredients.push(this.ingredientsMap.get(ingId));
                }
                item.ingredients = itemIngredients;
            }
        }

    }

    getTodaySpecialItems(): MenuItem[] {
        return this.items.items;
    }

    getTodaySpecialItemById(itemId: string): MenuItem {
        return this.TodaySpcialItemsMap.get(itemId);
    }


    getRestaurantId(): string {
        return this.restaurantId;
    }

}
