import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {RestaurantDataProvider} from '~services/restaurant-data-provider.service';
import {ProgressBarService} from '~services/progress-bar.service';
import {NotificationsService} from '~services/notifications.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppState } from '~app/reducers/main';
import { Store } from '@ngrx/store';
import { Datastore } from '~app/jsonapi/store-service';

@Component({
    selector: 'app-code-search',
    templateUrl: './code-search.component.html',
    styleUrls: ['./code-search.component.scss']
})
export class CodeSearchComponent implements OnInit {

    restaurantSearchForm: FormGroup;

    saving: boolean = true
    loading: boolean = true

    constructor(routerInjector: Router,
                dependencyProviderInjector: RestaurantDataProvider,
                notificationsServiceInjector: NotificationsService,
                progressBarServiceInjector: ProgressBarService,
                spinner: NgxSpinnerService,
                store: Store<AppState>,
                dataStore:Datastore) {
    }

    ngOnInit() {
        this.initForm();
    }

    /*TODO: Add custom validator function*/
    private initForm() {
        this.restaurantSearchForm = new FormGroup({
            // tslint:disable-next-line:max-line-length
            restaurantCode: new FormControl('', Validators.compose([Validators.minLength(6), Validators.required]))
        });
    }
}
