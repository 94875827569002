import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '~app/reducers/main';
import { LoadingIndicatorService } from './loading-indicator.service';
import { SaveRestaurantAction } from '~app/actions/restaurant.actions';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { NearByRestaurant, Restaurant } from '~app/models/restaurant';

@Injectable({
    providedIn: 'root'
})
export class RestaurantService {

    private restaurantUrl = 'api/restaurants';
    private nearByRestaurantUrl = 'api/search/restaurants'
    private referenceRestaurant: Restaurant;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private store: Store<AppState>,
        private loadingIndicatorService: LoadingIndicatorService
        ) {
    }

    byCode(code: string): Observable<Restaurant> {

        return this.http.get<Restaurant[]>(this.restaurantUrl + '?code=' + code).pipe(
            map(r => r[0]),
            catchError(error => {
                return throwError('Something went wrong!');
            })
        );
    }

    byId(id: string): Observable<Restaurant> {
        if (this.referenceRestaurant !== undefined) {
            if (this.referenceRestaurant.id === id) {
                return new Observable<Restaurant>(s => {
                    s.next(this.referenceRestaurant);
                    s.complete();
                });
            }
            this.referenceRestaurant = undefined;
        }
        return this.http.get<Restaurant>(this.restaurantUrl + '/' + id).pipe(
            catchError(error => {
                return throwError('Something went wrong!');
            })
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {

    }

    initialize(){     
        this.loadingIndicatorService.set(LoadingContextMap.LOADING_RESTAURANT)  
        return this.http.get<Restaurant>(this.restaurantUrl + '/' + this.authService.getContext().restaurantId).pipe(map(r => {return {source: 1, result: r}}))
    }

    load(next: any){
            this.store.dispatch(new SaveRestaurantAction(next))
    }


    fetchNearByRestaurants(lat: number, lon:number, within: number, offset: number, limit: number): Observable<NearByRestaurant[]>{
        return this.http.get<NearByRestaurant[]>(this.nearByRestaurantUrl+'?latitude='+lat+'&longitude='+lon+'&within='+within+'&offset='+offset+'&limit='+limit)
    }



}
