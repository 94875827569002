import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer } from '@angular/core';
import { MenuItem } from '~models/menu-item';
import { Router } from '@angular/router';
import { QuickItemSkeletonService } from '~app/services/quick-item-skeleton.service';
import { AppState } from '~app/reducers/main';
import { select, Store } from '@ngrx/store';
import { SaveMenuItemIdAction } from '~app/actions/menu.actions';
import { orderService, orderData, orderItem, Course } from 'src/app/services/add-order-list.service'
import { AddItemtoCartAction, UpdateItemQuantityAction } from '~app/actions/cart.actions';
import { ShowIndicatorAction, HideIndicatorAction } from '~app/actions/loading-indicator.actions';
import { cartLocationService, position } from '~app/services/cart-location.service';
import { animation, animate, state, style, trigger, transition, AnimationBuilder, AnimationPlayer, query } from '@angular/animations';
import { SaveCategoryIdAction } from '~app/actions/categories.actions';
import { Observable, Subscription, timer, of } from 'rxjs';
import { ThemeState } from '~app/reducers/theme.reducer';
import { Restaurant } from '~app/models/restaurant';
import { CartItem } from '~app/models/cart';
import { UpdateBasket } from '~app/actions/basket.actions';
import { CartService } from '~app/services/cart.service';
import { copyArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material';
import { CorrelateItemsComponent } from '../../correlate-items/correlate-items.component';
import { CorrService } from '~app/services/corr.service';
import { UpsaleService } from '~app/services/upsale.service';
import { Upsale } from '~app/models/upsale';
import { selectByItemId } from '~app/selectors/menu.selectors';
import { UpscaleItemComponent } from '../../upscale-item/upscale-item.component';
import { filter } from 'rxjs-compat/operator/filter';


@Component({
  selector: 'app-menu-item-detailed',
  templateUrl: './menu-item-detailed.component.html',
  styleUrls: ['./menu-item-detailed.component.scss']
})
export class MenuItemDetailedComponent implements OnInit {

  @Input() item: MenuItem;
  @ViewChild('animationElement', { static: false })
  public animationElement;

  restaurant$: Observable<Restaurant>;

  cartPosition: position;
  player: AnimationPlayer;

  vegIcon = '../assets/icons/Veg_icon.svg';

  lightingBoltIcon='..//assets/icons/lightingBoltIcon.svg'

  nonVegIcon = '../assets/icons/Nonveg_icon.svg';

  showSkeleton: boolean;
  isDarkTheme$: Observable<ThemeState>;


  dendlAt$: Observable<string> // Deal Ends at: This is to update time left, if it is applicable
  countDown: Subscription
  tick = 1000

  i = 0

  isSkippedCorrelatedItem: boolean = false

  constructor(private router: Router,
    private quickItemSkeleton: QuickItemSkeletonService,
    private store: Store<AppState>,
    private orderService: orderService,
    private cartLocationService: cartLocationService,
    private builder: AnimationBuilder,
    private elementRef: ElementRef,
    private cartService: CartService,
    public dialog: MatDialog,
    private corrService: CorrService,
    private upsaleService: UpsaleService
    ) {

    this.restaurant$ = this.store.pipe(select('restaurant'))

    this.cartLocationService.cartPostion$.subscribe(
      next => {
        this.cartPosition = next;
      }
    )
    this.isDarkTheme$ = this.store.pipe(select('theme'))

    }

  ngOnInit() {
    this.showSkeleton = this.quickItemSkeleton.get();
    this.item;
    console.log(this.item)
    this.cartLocationService.cartPostion$.subscribe(
      next => {
        this.cartPosition = next;
      }
    )
    if (!this.cartPosition) {
      this.cartPosition = this.cartLocationService.firstPosition;
    }

    // if(this.item && this.item.dealEndsAt){
    //   this.dendlAt$ = of(this.item.dealEndsAt)

    //   this.countDown = timer(0, this.tick).subscribe(
    //     () => { 
    //       this.i += 1
    //       let d = new Date(this.item.dealEndsAt)
    //       d.setSeconds(d.getSeconds() - 1)
  
    //       this.dendlAt$ = of(d.toISOString())

    //     }
    //   )

    // }

  }

  openItem() {
    this.store.dispatch(new ShowIndicatorAction())
    this.store.dispatch(new SaveMenuItemIdAction(this.item.id))
    this.store.dispatch(new SaveCategoryIdAction(this.item.categoryId))
    this.store.dispatch(new HideIndicatorAction())
    this.router.navigate(['/restaurant', this.item.restaurantId, 'item', this.item.id]);

  }

  addItem(item: MenuItem) {
    this.showAnimation();
    this.player.onDone(() => {

      // We need to get data from backend basket and update with this change

      this.cartService.getCart().subscribe(
        next => {

          let c: CartItem[] = []
          
          if(next.length>0){
            c = next
          }
          c.push({
            itemID: item.id,
            quantity: 1
          })
          

          // Dispatch an action, and update the backend

          this.cartService.updateCart(c).subscribe(
            next => {

              this.store.dispatch(new AddItemtoCartAction(item));
              this.store.dispatch(new UpdateBasket(c))

              if(item.corrID){
                //open Correlate Items dialog 
                this.openCorrelateItemsDialog(item)

              }else{
                if(item.upsaleItemID){
                   // Open upsale item dialog
                   this.openUpsaleItemDialog(item)
                }
              }
             
            },
            error => {
              console.log("error in updating the cart:", error)
            }
          )

        },
        error => {
          console.log("error in getting cart from backend:", error)
        }
      )
   
    })
  }

  changeCount(item: MenuItem, operation: string) {

    //First get the cart from backend

    this.cartService.getCart().subscribe(
      next => {
        let c: CartItem[] = []
        
        if(next.length>0){
          // copy these item to c
         
          c = [...next]
        }


        //Get the index of an item with given item ID
        let idx
        
        for(let i=0;i<c.length;++i){
          if(c[i].itemID === item.id){
            idx = i
            break
          } 
        }


        if (operation === '-') {
          c[idx].quantity = c[idx].quantity - 1


          this.store.dispatch(new UpdateItemQuantityAction({ item: item, operation: operation }));
        }
        if (operation === '+') {
          c[idx].quantity = c[idx].quantity + 1

          this.showAnimation();
          this.player.onDone(() => {
            this.store.dispatch(new UpdateItemQuantityAction({ item: item, operation: operation }));
          });
        }


        // remove logic for quantity <0
         if(c[idx].quantity===0){
            c = [
              ...c.slice(0, idx),
              ...c.slice(idx+1,)
            ]
        }
        //Now, update this with the backend

        this.cartService.updateCart(c).subscribe(
          next => {
            // Update this with the store
            this.store.dispatch(new UpdateBasket(c))
          },
          error => {
            console.log("Error in updating cart to the backend:", error)
          }
        )



      },
      error => {

      }
    )
  }


  public showAnimation(): void {
    this.overlay(this.animationElement);

  }


  private overlay(element: ElementRef): void {

    let imgToCopy = this.elementRef.nativeElement.querySelector(`#add-cart`);

    let itemPosition = imgToCopy.getBoundingClientRect();

    let intialState: any = style({
      height: '10px',
      width: '10px',
      top: String(itemPosition.top +(itemPosition.height/2)) +`px`,
      left: String(itemPosition.left+(itemPosition.width/2)) + 'px',
      borderRadius:'50%',
      backgroundColor:'#f06493',
      // backgroundImage: 'url(' + this.item.imageUrls[0] + ')'
    });

    let endState: any = style({
      zIndex: -1,
      height: '4px',
      width: '4px',
      top: this.cartPosition.y.toString()+ 'px',
      left:this.cartPosition.x.toString() + 'px',
      borderRadius:'50%',
      backgroundColor:'#f06493',
      // backgroundImage: 'url(' + this.item.imageUrls[0] + ')',
    });

    const overlay = [
      query('.animation-square', intialState),
      query('.animation-square',
        animate('200ms linear', endState),
      )
    ];

    this.createAnimation(overlay, element.nativeElement);

  }

  private createAnimation(animation, element): void {

    const factory = this.builder.build(animation);
    this.player = factory.create(element);
    this.player.play();

  }


  viewImage(selectedImageUrl: string) {
    let menuImage = document.getElementById('menuImage') as HTMLImageElement;
    menuImage.src = selectedImageUrl;

  }

  addItemWithOutAnimation(item: MenuItem) {
   this.showAnimation();
   this.player.onDone(()=>{
    this.cartService.getCart().subscribe(
      next => {

        let c: CartItem[] = []
        
        if(next.length>0){
          c = next
        }
        c.push({
          itemID: item.id,
          quantity: 1
        })
        

        // Dispatch an action, and update the backend

        this.cartService.updateCart(c).subscribe(
          next => {

            this.store.dispatch(new AddItemtoCartAction(item));
            this.store.dispatch(new UpdateBasket(c))
            this.showAnimation();
  
            if(item.corrID){
              //open Correlate Items dialog 
              this.openCorrelateItemsDialog(item)

            }else{
              if(item.upsaleItemID){
                 // Open upsale item dialog
                 this.openUpsaleItemDialog(item)
              }
            }

          },
          error => {
            console.log("error in updating the cart:", error)
          }
        )

      },
      error => {
        console.log("error in getting cart from backend:", error)
      }
    )

   })
   
  }

  changeCountWthOutAnimation(item: MenuItem, operation: string) {

    this.cartService.getCart().subscribe(
      next => {
        let c: CartItem[] = []
        
        if(next.length>0){
          // copy these item to c
         
          c = [...next]
        }


        //Get the index of an item with given item ID
        let idx
        
        for(let i=0;i<c.length;++i){
          if(c[i].itemID === item.id){
            idx = i
            break
          } 
        }


        if (operation === '-') {
          c[idx].quantity = c[idx].quantity - 1


          this.store.dispatch(new UpdateItemQuantityAction({ item: item, operation: operation }));
        }
        if (operation === '+') {
          c[idx].quantity = c[idx].quantity + 1

            this.store.dispatch(new UpdateItemQuantityAction({ item: item, operation: operation }));
      
        }


        // remove logic for quantity <0
         if(c[idx].quantity===0){
            c = [
              ...c.slice(0, idx),
              ...c.slice(idx+1,)
            ]
        }
        //Now, update this with the backend

        this.cartService.updateCart(c).subscribe(
          next => {
            // Update this with the store
            this.store.dispatch(new UpdateBasket(c))
          },
          error => {
            console.log("Error in updating cart to the backend:", error)
          }
        )



      },
      error => {

      }
    )



  }

  openGalleryImage(item:MenuItem){
  let modal = document.getElementById("myModal");
  let captionText = document.getElementById("caption")
  let modalImg = document.getElementById("img01") as HTMLImageElement;
    modal.style.display = "block";
    modalImg.src = item.imageUrls[0];
    captionText.innerHTML=item.name;
  }
 
  closeGalleryImage(){
    let span = document.getElementsByClassName("close")[0] as HTMLImageElement;
    let modal = document.getElementById("myModal");
      modal.style.display = "none";
  }


  checkIfIsPast(d: string): boolean {
    let givenDate = new Date(d)
    let currDate = new Date()

    if(currDate>givenDate){
      return true
    }else {
      return false
    }
  }

  openCorrelateItemsDialog(item: MenuItem): void {

    //Check if item as corrID and non-empty correlated items are present
    if(item.corrID){
      this.corrService.getCorrItems(item.corrID).subscribe(
        next => {
          if(next && next.length>0){
            const dialogCorrelateItemsRef = this.dialog.open(CorrelateItemsComponent , {
              maxHeight:"60vh",
              data: {item: item}
            });


            dialogCorrelateItemsRef.afterClosed().subscribe((result) => {
              if(result && result.clickedOnNothanks){
                this.openUpsaleItemDialog(item)
              }
            })
          }
        },
        error => {
          console.log("Error in getting correlated items:", error)
        }
      )
    }

}


openUpsaleItemDialog(item: MenuItem){
  if(item.upsaleItemID){
    this.upsaleService.getUpsaleItem(item.upsaleItemID).subscribe(
      next => {
        let upsaleItem: Upsale = next

        // This is the upsale item: upsaleItem.itemID
        if(upsaleItem && upsaleItem.itemID){
          const dialogUpsaleItemRef = this.dialog.open(UpscaleItemComponent , {
            maxHeight:"60vh",
            data: {item: upsaleItem.itemID, originalPrice: item.price, orignalItem: item}
          });
        }
       

      },
      error => {
        console.log("Error in getting upsale item for "+ item.name)
      }
    )
  }
}

}