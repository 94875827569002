import { Action } from "@ngrx/store";


export enum SidenavactionTypes {
    SIDENAV_TOGGLE='[Toggle component] Sidenav toggling',
    CLOSE_SIDENAVBAR = '[Toggle Component] Close sidenavbar'

  }
 export class Sidenavtoggleaction implements Action {
    readonly type=SidenavactionTypes.SIDENAV_TOGGLE
  }
  
  export class CloseSideNavbarAction implements Action{
    readonly type = SidenavactionTypes.CLOSE_SIDENAVBAR
  }
  
 export type Sidenavaction=Sidenavtoggleaction | CloseSideNavbarAction