import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { Ingredient } from '~app/models/ingredient';
import { HttpClient } from '@angular/common/http';
import {TodaySpecial } from '~app/models/today-special';
import { Specials } from '~app/models/specials';
import { MenuItem } from '~app/models/menu-item';

@Injectable({
  providedIn: 'root'
})
export class TodaySpecialsService {
  private ingredientsUrl = 'api/ingredients';
  private todaySpecialsUrl = 'api/todays-specials';
 
  constructor(private http:HttpClient) { }

  getTodaySpecials(restaurantId: string){
    const itemsObservable = this.getSpecialItems(restaurantId);
    const ingredientsObservable = this.getIngredients();
    
    // TODO: handle errors
    return new Observable<TodaySpecial>(subscriber => {
        forkJoin([itemsObservable, ingredientsObservable]).subscribe(results => {
            const todaySpecials = new TodaySpecial(restaurantId, results[0], results[1]);
            subscriber.next(todaySpecials);
            subscriber.complete();
        });
    });
  }


private getSpecialItems(restaurantId: string): Observable<Specials> {
    return this.http.get<Specials>(this.todaySpecialsUrl + '?restaurantId=' + restaurantId);
}

private getIngredients(): Observable<Ingredient[]> {
    return this.http.get<Ingredient[]>(this.ingredientsUrl);
}
}
