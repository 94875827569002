import {JsonApiModelConfig, JsonApiModel, Attribute, HasMany, BelongsTo} from 'angular2-jsonapi';
import {ServiceItem} from './service-item.model';
import { isNgTemplate } from '@angular/compiler';

@JsonApiModelConfig({
  type: 'service-items-set'
})
export class ServiceItemsSet extends JsonApiModel {

  @HasMany({key: 'service-items'})
  serviceItems: ServiceItem[];

  // update(svi: ServiceItem[]){

  //   // service set
    
  //   let set = new Set<ServiceItem>();

  //   for(let s of svi){
  //     set.add(s);
  //   }

  //   // service items ids present in ids; which means those service items does not change
  //   this.serviceItems = this.serviceItems.filter(item => {
  //     const result = set.has(item);
      
  //     if(result){
  //       set.delete(item);
  //     }
        
  //     return true;
  //   });


  //   console.log("before for each:", this.serviceItems)
  //   set.forEach(
  //     id => this.serviceItems.push(id)
  //   )

  //   console.log(" aft4er for each:", this.serviceItems);

  //   // if there are any ids in the set; or set is not empty
    
  //   // until set is not empty; create new service items


  //   // update service items set
  //   this.save().subscribe();

  // }

}
