import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaymentOrder, PaymentCapture } from '~app/models/payment';

@Injectable({
    providedIn: 'root'
})

export class PaymentService {
    paymentInitUrl='/api/initiate-payment';
    verifyPaymentUrl='/api/verify-payment'

    constructor(
        private http: HttpClient
        ) {
    }

    paymentInitiate():Observable<PaymentOrder>{
        return this.http.get<PaymentOrder>(this.paymentInitUrl)
    }


    verifyPayment(razorpay_order_id: string, razorpay_payment_id: string, razorpay_signature: string ): Observable<any>{
        return this.http.post<any>(this.verifyPaymentUrl, new PaymentCapture(razorpay_order_id, razorpay_payment_id, razorpay_signature))
    }
}
