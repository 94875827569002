import { Action } from '@ngrx/store';

export enum menuActionTypes {
    LOAD_MENU = '[Menu component] load menu',
    SAVE_MENU = '[Menu component] save menu',
    SAVE_MENU_ITEM_ID = '[Menu component] save menu item id',
    RESET_MENU = '[Menu component] reset menu',
    UPDATE_QUANTITY = '[Menu component] update the quantity' ,
    RESET_QUANTITY = '[Menu component] reset quantity',
    MENU_RELOAD = '[Menu component] menu reload',
    LOAD_CATEGORIES = '[Menu component] load categories',
    LOAD_MENU_ITEMS = '[Menu component] load menu items',
    LOAD_INGREDIENTS = '[Menu component] load ingredients',
    SAVE_RELOAD_MENU = '[Menu component] save reload menu'
}


export class LoadMenuAction implements Action{
    readonly type = menuActionTypes.LOAD_MENU;

    constructor(public payload: any){}
}

export class SaveMenuAction implements Action{
    readonly type = menuActionTypes.SAVE_MENU;

    constructor(public payload: any){}
}

export class SaveMenuItemIdAction implements Action {
    readonly type = menuActionTypes.SAVE_MENU_ITEM_ID

    constructor(public payload: any){}
}

export class ResetMenuAction implements Action {
    readonly type = menuActionTypes.RESET_MENU
}

export class UpdateMenuItemQuantityAction implements Action{
    readonly type = menuActionTypes.UPDATE_QUANTITY;

    constructor(public payload: any){}

}

export class ResetQuantityAction implements Action{
    readonly type = menuActionTypes.RESET_QUANTITY

    constructor(public payload: any){}
}

export class MenuReloadAction implements Action{

    readonly type =  menuActionTypes.MENU_RELOAD

    constructor(public payload: any){}

}

export class ReloadCategoriesAction implements Action{

    readonly type = menuActionTypes.LOAD_CATEGORIES

    constructor(public payload: any){}

}

export class ReloadMenuItemsAction implements Action{

    readonly type = menuActionTypes.LOAD_MENU_ITEMS

    constructor(public payload: any){}

}

export class ReloadIngredientsAction implements Action{

    readonly type = menuActionTypes.LOAD_INGREDIENTS

    constructor(public payload: any){}
}


export class SaveReloadMenuAction implements Action{

    readonly type = menuActionTypes.SAVE_RELOAD_MENU

    constructor(public payload: any){}
}

export type menuActions = LoadMenuAction |
SaveMenuAction |
SaveMenuItemIdAction |
ResetMenuAction  | 
UpdateMenuItemQuantityAction |
ResetQuantityAction |
MenuReloadAction |
ReloadCategoriesAction |
ReloadMenuItemsAction |
ReloadIngredientsAction |
SaveReloadMenuAction;