import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs-compat';
import { Restaurant } from '~app/models/restaurant';
import { AppState } from '~app/reducers/main';
import { selectCartCount } from '~app/selectors/cart.selectors';
import { selectRestaurant } from '~app/selectors/restaurant.selectors';

@Component({
  selector: 'app-cart-snackbar',
  templateUrl: './cart-snackbar.component.html',
  styleUrls: ['./cart-snackbar.component.scss']
})
export class CartSnackbarComponent implements OnInit {

  cartCount$:Observable<number>
  restaurantID:string;

  constructor(private store: Store<AppState>,
   private cartSnackrRef:MatSnackBarRef<CartSnackbarComponent>,
    public router: Router,private route: ActivatedRoute,) { 
    this.store.pipe(select('restaurant')).subscribe(
      next=>{
       this.restaurantID=next.id
      }
    )
    
    this.cartCount$=this.store.pipe(select(selectCartCount))
  }
  

  ngOnInit() {
  }


  viewCart(){
    
    this.router.navigate(['restaurant',this.restaurantID,'cart-summary']);
    // this.cartSnackrRef.dismiss()

  }

}
