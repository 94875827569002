import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '~app/reducers/main';
import { LoadingIndicatorService } from './loading-indicator.service';
import { selectBusinessCategories } from '~app/selectors/menu.selectors';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SaveCategoryIdAction } from '~app/actions/categories.actions';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { Category } from '~app/models/category';

@Injectable({
  providedIn: 'root'
})
export class CategorySelectionService {

  categories$: Observable<Category[]>
  
  constructor(

    private store: Store<AppState>,
    private loadingIndicatorService: LoadingIndicatorService
  ) { }


  initialize(): Observable<any>{  
    
    this.loadingIndicatorService.set(LoadingContextMap.LOADING_CATEGORY_SELECTION)
  
    return this.store.pipe(select(selectBusinessCategories)).pipe(map(c => {return {source: 6, result: c}}))
  }

  load(next: any){
    this.store.dispatch(new SaveCategoryIdAction(next[0].id))
  }

}
