import { Injectable } from '@angular/core';
import { Order, RzpayOrder } from '~app/models/razorpay';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RazorpayService {

  rzpayUrl = 'https://api.razorpay.com/v1/orders'

  constructor(private http: HttpClient) { }

  getOrderRzpayId(amount: number): Observable<RzpayOrder>{

    //create body
    const body = new Order(amount, 'INR', 'rcptid_24', 1)

    // secret details goes here
    const username = 'rzp_live_IAPYQOftOiwlC8'
    const password = 'g1Ox9DaOVUXaPoANxUhiEpwo'

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Accept': 'application/json',
        'Authorization': 'Basic ' + btoa(username+':'+password),
        'Access-Control-Allow-Origin': '*'
      })
    };


  return this.http.post<RzpayOrder>(this.rzpayUrl, new Order(amount, 'INR', 'rcptid_32', 1), httpOptions)
  }
}
