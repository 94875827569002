
import * as fromWebsocketLogs from '../reducers/websocket-log.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';


export interface State{
  websocketLog: fromWebsocketLogs.WebsocketLogState
}

export const selectWebsocketLogState = createFeatureSelector<fromWebsocketLogs.WebsocketLogState>('websocketLog')

export const selectWebsocketLogIds = createSelector(
  selectWebsocketLogState,
    (wsl) => fromWebsocketLogs.selectWebsocketLogStateIds(wsl)
)

export const selectWebsocketLogEntities = createSelector(
  selectWebsocketLogState,
    (wsl) => fromWebsocketLogs.selectWebsocketLogStateEntities(wsl)
)

export const selectCapturedTimeStamp = createSelector(
  selectWebsocketLogState,
  (wls) => wls.capturedTimeStamp
)
