import {QuestionResponse} from '~models/question-response';

export class QuestionnaireResponse {
    responses: QuestionResponse[];

    static fromMap(responseMap: Map<string, QuestionResponse>): QuestionnaireResponse {

       const questionnaireResponse = new QuestionnaireResponse();
       questionnaireResponse.responses = [];
       responseMap.forEach((value: QuestionResponse, key: string) => {
            questionnaireResponse.responses.push(value);
       });

       return questionnaireResponse;
    }
}
