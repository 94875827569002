import { ThemeState } from './theme.reducer'
import { DinerState, DinerIdentity } from './diner.reducer'
import { IngredientsState } from './ingredients.reducer'
import { CategoriesState, CategoryIdState } from './categories.reducer'
import { MenuState, MenuIDState } from './menu.reducer'
import { Sidenavstate } from './sidenavtoggle.reducer'
import { CartState } from './cart.reducer'
import { LoadingIndicatorState } from './loading-indicator.reducer'
import { RoundState } from './round.reducer'
import { ServiceItemsState } from './service-items.reducer'
import { PaymentOrder } from '~app/models/payment'
import { WebSocketConnectionStatusState, WebsocketLogState } from './websocket-log.reducer'
import { dinerActionTypes } from '~app/actions/diner.actions'
import { Restaurant } from '~app/models/restaurant'
import { MenuSettingsState } from './menu-settings.reducer'
import { BasketState } from './basket.reducer'
import { RoundOffState } from './round-off.reducer'
import { PaymentModesState } from './payment-modes.reducer'
import { UPI } from '~app/models/upi-details'
import { Joke } from '~app/models/joke'

export class AppState{
    restaurant: Restaurant
    theme: ThemeState
    diner: DinerState
    ingredients: IngredientsState
    categories: CategoriesState
    categoryId: CategoryIdState
    menu: MenuState
    menuId: MenuIDState
    sidenavtoggle:Sidenavstate
    cart: CartState
    loadIndicator: LoadingIndicatorState
    paymentOrder: PaymentOrder
    dinerIdentity: DinerIdentity
    round: RoundState
    serviceItems:ServiceItemsState
    websocketLog: WebsocketLogState
    menuSettings:MenuSettingsState
    webSocketConnectionStatus:WebSocketConnectionStatusState

    basket: BasketState

    roundOff: RoundOffState

    paymentModes: PaymentModesState

    upi: UPI

    joke: Joke

}

export function clearState(reducer) {
    return function (state, action) {
  
      if (action.type === dinerActionTypes.COMPLETE_DINING) {
        state = undefined;
      }
  
      return reducer(state, action);
    };
  }