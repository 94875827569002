import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-report-problem-page',
  templateUrl: './report-problem-page.component.html',
  styleUrls: ['./report-problem-page.component.scss']
})
export class ReportProblemPageComponent implements OnInit {

    reportProblemForm = new FormGroup({
    title: new FormControl(''),
    description: new FormControl(''),
    choosefile: new FormControl(''),
  });
  constructor() { }

  ngOnInit() {
  }

}
