import * as fromRound from '../reducers/round.reducer';
import { createFeatureSelector, createSelector} from '@ngrx/store';
import {  selectMenuEntities } from './menu.selectors';


export interface State {
  cart: fromRound.RoundState
}

export const selectRoundState = createFeatureSelector<fromRound.RoundState>('round')


export const selectRoundIds = createSelector(
  selectRoundState,
  (roundState) => fromRound.selectRoundIds(roundState)
)

export const selectRoundEntities = createSelector(
  selectRoundState,
  (roundState) => fromRound.selectRoundEntities(roundState)
)

export const selectRoundCount = createSelector(
  selectRoundState,
  (roundState) => fromRound.selectRoundTotal(roundState)
)


export const selectBusinessRounds = createSelector(
  selectRoundIds,
  selectRoundEntities,
  selectMenuEntities,
  (roundIds,rounds,menu) => {
    let finalResult: fromRound.RoundToAdd[] = [];

    for (let RId of roundIds) {
      finalResult.push(rounds[RId])
    }


    let a = []
    let b = []
    for (let round of finalResult) {
        for (let item of round.lineItems) {
          console.log(item)
            item = {
              ...item, name:menu[item.itemID].name
            }
          a.push(item)
        }
    round = {
        ...round, lineItems: a
         }
    a = []
    
    b.push(round)
    }

    finalResult = b
    b=[]
    console.log("ss",finalResult)
    finalResult.reverse()
    return finalResult
  }
)


export const isOrderCompleted = createSelector(
  selectBusinessRounds,
  (rounds) =>{

     let orderStatusFlag:boolean=true;
      
     for( let round of rounds){
        if(round.status === 'ORDERED'){
          orderStatusFlag=false; 
          break 
        }

     }
   
    return orderStatusFlag
  }
)

