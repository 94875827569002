import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RestaurantDataProvider } from '~app/services/restaurant-data-provider.service';

@Component({
  selector: 'app-thankyou-kitty',
  templateUrl: './thankyou-kitty.component.html',
  styleUrls: ['./thankyou-kitty.component.scss']
})
export class ThankyouKittyComponent implements OnInit {

  thankYouImage="/assets/images/thankyou.png";

  timeLeft:number=0;

  constructor(private route: ActivatedRoute, public router: Router) {
    
   }

  ngOnInit() {
    // need to link with session
    this.timeLeft=30;
    setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      }
      if(this.timeLeft < 1){
        this.timeLeft=0;
      }
    },600)
  }

}
