import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-camera-access-dialog',
  templateUrl: './camera-access-dialog.component.html',
  styleUrls: ['./camera-access-dialog.component.scss']
})
export class CameraAccessDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<CameraAccessDialogComponent>
  ) { }

  ngOnInit() {
  }

}
