import { Injectable } from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects'
import { ingredientsActionTypes, SaveIngredientsAction } from '~app/actions/ingredients.actions';
import { switchMap, map } from 'rxjs/operators';
import { MenuService } from '~app/services/menu.service';

@Injectable()
export class IngredientsEffects {

    constructor(private actions$: Actions, private menuService: MenuService){}

    @Effect()
    LoadIngredientsAction$ = this.actions$.pipe(
        ofType(ingredientsActionTypes.LOAD_INGREDIENTS),
        switchMap(() => this.menuService.getIngredients()
        .pipe(
            map(res => new SaveIngredientsAction(res))
        )),
        
    )
}
