import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from '~models/menu-item';
import { Router } from '@angular/router';
import { QuickItemSkeletonService } from '~app/services/quick-item-skeleton.service';
import { AppState } from '~app/reducers/main';
import { select, Store } from '@ngrx/store';
import { SaveMenuItemIdAction } from '~app/actions/menu.actions';
import { orderService, orderData, orderItem, Course } from 'src/app/services/add-order-list.service'
import { AddItemtoCartAction, UpdateItemQuantityAction } from '~app/actions/cart.actions';
import { ShowIndicatorAction, HideIndicatorAction } from '~app/actions/loading-indicator.actions';
import { cartLocationService, position } from '~app/services/cart-location.service';
import { animation, animate, state, style, trigger, transition, AnimationBuilder, AnimationPlayer, query } from '@angular/animations';
import { SaveCategoryIdAction } from '~app/actions/categories.actions';
import { Observable, Subscription, timer, of } from 'rxjs';
import { ThemeState } from '~app/reducers/theme.reducer';
import { Restaurant } from '~app/models/restaurant';
import { CartItem } from '~app/models/cart';
import { UpdateBasket } from '~app/actions/basket.actions';
import { CartService } from '~app/services/cart.service';
import { copyArrayItem } from '@angular/cdk/drag-drop';
import { MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';

@Component({
  selector: 'app-correlate-item',
  templateUrl: './correlate-item.component.html',
  styleUrls: ['./correlate-item.component.scss']
})
export class CorrelateItemComponent implements OnInit {

  @Input() item: MenuItem;

  @Input() index: number;

  @Input() intendedItem: MenuItem;

  @Output()
  newItemEvent = new EventEmitter<boolean>();

  restaurant$: Observable<Restaurant>;


  vegIcon = '../assets/icons/Veg_icon.svg';

  lightingBoltIcon='..//assets/icons/lightingBoltIcon.svg'

  nonVegIcon = '../assets/icons/Nonveg_icon.svg';


  dendlAt$: Observable<string> // Deal Ends at: This is to update time left, if it is applicable
  countDown: Subscription
  tick = 1000

  i = 0

  constructor(private router: Router,
    private quickItemSkeleton: QuickItemSkeletonService,
    private store: Store<AppState>,
    private orderService: orderService,
    private cartService: CartService,
    public dialogRef: MatDialogRef<CorrelateItemComponent>,
    private loadingIndicatorService: LoadingIndicatorService,
    private spinner: NgxSpinnerService,
   

    
    ) {


    this.restaurant$ = this.store.pipe(select('restaurant'))
    }

  ngOnInit() {
    this.item;

  }



  addItemWithOutAnimation(item: MenuItem) {
    this.loadingIndicatorService.set("Adding item to cart...")
    this.spinner.show()

    this.cartService.getCart().subscribe(
      next => {

        let c: CartItem[] = []
        
        if(next.length>0){
          c = next
        }
        c.push({
          itemID: item.id,
          quantity: 1,
          referrer: 2, // Its a cross sale item. ref: 0 - regular, 1 - upsale, 2. crosssale, 3. round off
          intendedItemID: this.intendedItem.id,
          intendedPrice: this.intendedItem.price*(1-this.intendedItem.offer/100),
          optPrice: this.intendedItem.price*(1-this.intendedItem.offer/100) + item.price*(1 - item.offer/100)
        })
        

        // Dispatch an action, and update the backend

        this.cartService.updateCart(c).subscribe(
          next => {

            this.store.dispatch(new AddItemtoCartAction(item));
            this.store.dispatch(new UpdateBasket(c))

           this.newItemEvent.emit(true)

          },
          error => {
            console.log("error in updating the cart:", error)
          }
        )
        this.spinner.show()

      },
      error => {
        console.log("error in getting cart from backend:", error)
        this.loadingIndicatorService.set("Error in adding to cart...")
        this.spinner.hide()
      }
    )
  }

  changeCountWthOutAnimation(item: MenuItem, operation: string) {
    if(operation === '-'){
      this.loadingIndicatorService.set("removing item from cart...")
    }
    if(operation === '+'){
      this.loadingIndicatorService.set("Adding item to cart...")
    }
    
    this.spinner.show()

    this.cartService.getCart().subscribe(
      next => {
        let c: CartItem[] = []
        
        if(next.length>0){
          // copy these item to c
         
          c = [...next]
        }


        //Get the index of an item with given item ID
        let idx
        
        for(let i=0;i<c.length;++i){
          if(c[i].itemID === item.id){
            idx = i
            break
          } 
        }


        if (operation === '-') {
          c[idx].quantity = c[idx].quantity - 1


          this.store.dispatch(new UpdateItemQuantityAction({ item: item, operation: operation }));
        }
        if (operation === '+') {
          c[idx].quantity = c[idx].quantity + 1

            this.store.dispatch(new UpdateItemQuantityAction({ item: item, operation: operation }));
      
        }


        // remove logic for quantity <0
         if(c[idx].quantity===0){
            c = [
              ...c.slice(0, idx),
              ...c.slice(idx+1,)
            ]
        }
        //Now, update this with the backend

        this.cartService.updateCart(c).subscribe(
          next => {
            // Update this with the store
            this.store.dispatch(new UpdateBasket(c))
          },
          error => {
            console.log("Error in updating cart to the backend:", error)
          }
        )

        this.spinner.hide()

      },
      error => {
        this.loadingIndicatorService.set("Error in updatingto cart...")
        this.spinner.hide()

      }
    )


  }

  checkIfIsPast(d: string): boolean {
    let givenDate = new Date(d)
    let currDate = new Date()

    if(currDate>givenDate){
      return true
    }else {
      return false
    }
  }

}
