import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '~app/reducers/main';
import { Observable } from 'rxjs';
import { RoundState} from '~app/reducers/order.reducer';
import { selectOrderItems } from '~app/selectors/order.selectors';
import { last, takeLast } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Round } from '~app/jsonapi/round.model';
import { Datastore } from '~app/jsonapi/store-service';
import { JsonApiQueryData } from 'angular2-jsonapi';
import { RoundToAdd } from '~app/reducers/round.reducer';
import { selectBusinessRounds} from '~app/selectors/round.selector';
import { CartState } from '~app/reducers/cart.reducer';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss']
})
export class CartSummaryComponent implements OnInit {

  rounds$: Observable<RoundToAdd[]>;
  cartCount$: Observable<CartState>;

  constructor(private store: Store<AppState>,
              public router: Router,
              private activatedRoute: ActivatedRoute,
              private dataStore:Datastore) {
    this.rounds$ = this.store.pipe(select(selectBusinessRounds))
    this.cartCount$ = this.store.pipe(select('cart'))
   }

  ngOnInit() {
   

  }
  
  
}
