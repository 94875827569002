import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from '~app/models/menu-item';

export class orderItem{
     status:string;
     Item:MenuItem;
     count:number;
}
export class Course{
  course:String;
  items:orderItem[];
  status:string;
}
export class orderData {
  currentCourse:Course;
  previousCourse:Course[];
}

@Injectable({
  providedIn: 'root'
})
export class orderService {
  
  orderData=new orderData;
  
  constructor() { }

  private orderListSource = new BehaviorSubject(this.orderData);
  
  currentOrderList = this.orderListSource.asObservable();

  changeOrder(orderData:orderData) {
    this.orderListSource.next(orderData);
  } 

}
