import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CategoriesService } from './categories.service';
import { MenuItem } from '~app/models/menu-item';
import { MenuItemService } from './menu-item.service';
import { AuthService } from './auth.service';
import { Ingredient } from '~app/models/ingredient';
import { IngredientsService } from './ingredients.service';
import { Category } from '~app/models/category';

@Injectable({
  providedIn: 'root'
})
export class MenuLoadService {

  private menuItemsUrl = 'api/items';
  private categoriesUrl = 'api/categories';
  private tagsUrl = 'api/labels';
  private ingredientsUrl = 'api/ingredients';

  constructor(
    private http: HttpClient,
    private categoriesService : CategoriesService,
    private menuItemService : MenuItemService,
    private authService : AuthService,
    private ingredientService : IngredientsService
  ) { }


  loadCategories(){
    this.http.get<Category[]>(this.categoriesUrl).subscribe(
      next => {
        this.categoriesService.reload(next)
      },
      error => {
        console.log("Error in reloading categories:", error)
      }
    )
  }

  loadMenuItems(){

    let restaurantId = this.authService.getContext().restaurantId;
    this.http.get<MenuItem[]>(this.menuItemsUrl + '?restaurantId=' + restaurantId).subscribe(
      next => {
        this.menuItemService.reload(next)
      },
      error => {
        console.log("Error in reloading menu items:", error)
      }
    )
  }

  loadIngredients(){
    this.http.get<Ingredient[]>(this.ingredientsUrl).subscribe(
      next => {
        this.ingredientService.reload(next);
      },
      error => {
        console.log("Error in reloading ingredients:", error)
      }
    )
  }
}
