import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent implements OnInit {

  isForDelivery: boolean = false
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<OrderConfirmationComponent>
  ) { }

  ngOnInit() {
    if(localStorage.getItem('isForDelivery')!=null && localStorage.getItem('isForDelivery')=='true'){
      this.isForDelivery = true
    }
  }

  close(){
    this.dialogRef.close({confirm: false})
  }

  confirm(){
    this.dialogRef.close({confirm: true})
  }

}
