import {JsonApiDatastoreConfig, JsonApiDatastore, DatastoreConfig} from 'angular2-jsonapi';
import {HttpClient} from '@angular/common/http';
import {ServiceItem} from './service-item.model';
import {ServiceItemsSet} from './service-item-set.model';
import {ImageDownloadDescriptor} from './image-download-descriptor.model';
import {Injectable} from '@angular/core';
import {Image} from './image.model';
import { Session } from 'protractor';
import { SessionProfile } from './session-Profile.model';
import {  Round } from './round.model';
import { Order } from './order.model';
import { ServiceItemRequest } from './service-item-request.model';

const config: DatastoreConfig = {
  baseUrl: '/api/v1',
  models: {
    'service-items': ServiceItem,
    'image-download-descriptors': ImageDownloadDescriptor,
    'service-items-set': ServiceItemsSet,
    'images': Image,
    'session-profiles':SessionProfile,
    'rounds':Round,
    'orders':Order,
    'service-item-requests': ServiceItemRequest
  }
};

@Injectable({
    providedIn: 'root'
  }
)
@JsonApiDatastoreConfig(config)
export class Datastore extends JsonApiDatastore {

  constructor(http: HttpClient) {
    super(http);
  }

}
