import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { read } from 'fs';
import { Observable } from 'rxjs';
import { AuthContext } from '~app/models/auth-context';
import { DinerIdentity } from '~app/reducers/diner.reducer';
import { AppState } from '~app/reducers/main';
import { QrCodeService } from '~app/services/qr-code.service';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})

export class QrCodeComponent implements OnInit {

  imageToShow: any

  dinnerIdentity$:Observable<DinerIdentity>

  deviceTrackingCode:string;

  constructor(
    private qrCodeService: QrCodeService,
    private store:Store<AppState>,
    private sanitizer: DomSanitizer
  
  ) { 
    this.dinnerIdentity$ = this.store.pipe(select('dinerIdentity'))
    this.dinnerIdentity$.subscribe(
      next=>{
        this.deviceTrackingCode=next.trackingCode
      }
    )

  }

  ngOnInit(): void {

          this.qrCodeService.getQrImage(this.deviceTrackingCode).subscribe(
            next => {
              this.createImageFromBlob(next)
            },
            error => {
              console.log("Error in getting qr code:", error)
            }
          )
  }


  createImageFromBlob(image: Blob) {
    let reader = new FileReader()
    reader.addEventListener("load", () => {
       this.imageToShow = this.sanitizer.bypassSecurityTrustUrl(reader.result.toString())
    }, false);
 
    if (image) {
        reader.readAsDataURL(image);
    }
 }

 RefreshQrCode(){

  this.qrCodeService.getQrImage(this.deviceTrackingCode).subscribe(
    next => {
      this.createImageFromBlob(next)
    },
    error => {
      console.log("Error in getting qr code:", error)
    }
  )
 }


}

