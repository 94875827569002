import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { OrderActionTypes, SendtoKitchenAction, PlaceOrderAction } from '~app/actions/order.actions';
import { map, mergeMap } from 'rxjs/operators';
import { ClearCartAction } from '~app/actions/cart.actions';
import { ResetQuantityAction } from '~app/actions/menu.actions';

@Injectable()
export class OrderEffects {

    constructor(private actions$: Actions){}

    @Effect()
    SendtoKitchenAction$ = this.actions$.pipe(
        ofType<SendtoKitchenAction>(OrderActionTypes.SEND_TO_KITCHEN),
        map(action => action.payload),
        mergeMap((payload) => [
            new ResetQuantityAction(payload),
            new ClearCartAction(),
            new PlaceOrderAction(payload),  
        ])

    )
}
