import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../reducers/main';
import { selectCapturedTimeStamp } from '../selectors/websocket-log.selectors';

@Injectable({
  providedIn: 'root'
})
export class CapturedTimestampService {

  capturedTimeStamp: string = undefined

  constructor(
    private store: Store<AppState>
  ) { 
    this.store.pipe(select(selectCapturedTimeStamp)).subscribe(
      next => {
        if(next){
          let dTime = new Date(next)
          this.capturedTimeStamp = Math.floor(dTime.getTime()/ 1000).toString()
        }
      },
      error => {
        console.log("Error in getting captured time stamp:", error)
      }
    )
  }
}
