import {JsonApiModelConfig, JsonApiModel, Attribute, HasMany, BelongsTo} from 'angular2-jsonapi';
import { ServiceItem } from './service-item.model';



@JsonApiModelConfig({
    type: 'service-item-requests'
})

export class ServiceItemRequest extends JsonApiModel {

    @Attribute()
    id: string;

    @Attribute()
    restaurantId: string;

    @Attribute()
    sessionId: string;

    @HasMany({ key : 'items'})
    items: ServiceItem[];

    @HasMany()
    ItemIDs:string[];

}
