import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fab-button',
  templateUrl: './fab-button.component.html',
  styleUrls: ['./fab-button.component.scss']
})
export class FabButtonComponent implements OnInit {

  @Input()
  value : number; //TODO: This name needs to be changed

  @Input()
  categoryLength: number; //TODO: This name needs to be changed

  constructor() { }

  ngOnInit() {
    console.log("print it:", this.value, this.categoryLength)
  }

}
