import { RoundActions, RoundActionTypes } from '~app/actions/round.actions';
import { EntityState, Dictionary, EntityAdapter, createEntityAdapter } from '@ngrx/entity';


export interface RoundToAdd{
    id: string
    status: string
    roundNumber: number
    lineItems: ListItem[]
    orderID?: string
    tableID?:string
    table?:string
    sessionID?:string
    restaurantID?: string
    timeStamp?: string
}

export class ListItem {
    itemID:string;
    quantity:number;
    unitPrice?:number;
    name?:string;
}


export class RoundState implements EntityState<RoundToAdd>{
    ids: string[] | number[];
    entities: Dictionary<RoundToAdd>;
}


export const adapter: EntityAdapter<RoundToAdd> = createEntityAdapter<RoundToAdd>();

let initialState: RoundState = adapter.getInitialState();


export function roundReducer(state=initialState, action: RoundActions){

    switch(action.type){
        case RoundActionTypes.ADD_ROUND: {
            
            return adapter.addOne(action.payload, state)
        }
        
        case RoundActionTypes.RESET_ROUNDS: {
            return undefined
        }

        case RoundActionTypes.UPDATE_ROUND: {
            return adapter.setOne(action.payload, state)
        }

        default : {
            return state
        }
    }

}

// get the selectors
const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = adapter.getSelectors();


  // select the array of user ids
  export const selectRoundIds = selectIds;
   
  // select the dictionary of user entities
  export const selectRoundEntities = selectEntities;
   
  // select the array of users
  export const selectAllRounds = selectAll;
   
  // select the total user count
  export const selectRoundTotal = selectTotal