import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import { Injectable } from '@angular/core';
import { AuthContext } from '../models/auth-context';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthResponse } from '~app/models/auth-response';
import { NotificationsService } from './notifications.service';
import { SnackBarMessageMap } from '~app/constants/snackbar-message.constants';
import { Restaurant, GuestIdentifier } from '~app/models/restaurant';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authUrl = 'api/est-session';
  private verifyScanUrl = 'api/verify-scan';
  tc:string;

  constructor(
    private http: HttpClient, 
    private spinner: NgxSpinnerService,
    private notificationsService: NotificationsService
    ) {
  }

  authenticate(scanCode: string, ext?: boolean, restaurantID?: string): Observable<AuthResponse> {
    let code = scanCode.split(':');
    let   trackingID = code[0];
    this.tc = trackingID
    let secret = code[1];

    let latitude = code[2]

    let longitude = code[3]
    

    let url = this.authUrl + '?trackingID=' + trackingID

    if(secret){
      url = url + '&secret=' + secret
    }

    if(longitude && latitude){
      url = url + '&lat='+latitude + '&lon='+longitude
    }

    if(ext){
      url = url +'&isExt='+true

      if(restaurantID){
        url = url + '&restaurantID='+restaurantID
      }
    }
    
    return this.http.get<AuthResponse>(url);
  }

  authConext(authObservable: Observable<AuthResponse>): Observable<AuthContext> {
    // TODO: handle errors
    return new Observable<any>(subscriber => {
      authObservable.subscribe(
        next => {
          const decoded = jwt_decode(next.jwt);

          let authContext: AuthContext = {
            sessionId: decoded['dinerSession']['knownSessionId'],
            restaurantId: decoded['dinerSession']['restaurantId'],
            orderId: decoded['dinerSession']['orderId'],
            cartID: decoded['dinerSession']['cartID'],
            paymentID: decoded['dinerSession']['paymentID'],
            roundOffID: decoded['roundOffID'],
            trackingCode:this.tc,
            jwt: next.jwt,
            exp: decoded['exp'],
            pricingTier: next.pricingTier
          }

          subscriber.next(authContext);
          this.storeDetails(authContext)
          subscriber.complete();
        },
        error => {
          this.spinner.hide()
          this.notificationsService.showMessage(SnackBarMessageMap.WRONG_QR_SCAN)
        }

      );
    });
  }

  authenticateDiner(scanCode: string, ext?: boolean, restaurantID?: string): Observable<AuthContext> {
    return this.authConext(this.authenticate(scanCode, ext, restaurantID));
  }

  storeDetails(authContext: AuthContext) {
    localStorage.setItem('auth', JSON.stringify(authContext));
  }

  removeDetails(){
    localStorage.removeItem('auth')
  }

  getContext(): AuthContext {
    return JSON.parse(localStorage.getItem('auth'));
  }

  hasJwtToken():boolean{
    let result: boolean = false

    if(this.getContext() && this.getContext().jwt){
      result = true
    }

    return result
  }

  verifyScan(trackingID: string): Observable<GuestIdentifier>{
    return this.http.get<GuestIdentifier>(this.verifyScanUrl+'?trackingID='+trackingID)
  }
  

}
