import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SaveCategoryIdAction } from '~app/actions/categories.actions';
import { Category } from '~app/models/category';
import { CategoryIdState } from '~app/reducers/categories.reducer';
import { AppState } from '~app/reducers/main';
import { selectBusinessCategories } from '~app/selectors/menu.selectors';

@Component({
  selector: 'app-select-category-dialog',
  templateUrl: './select-category-dialog.component.html',
  styleUrls: ['./select-category-dialog.component.scss']
})
export class SelectCategoryDialogComponent implements OnInit {
  
  categories$: Observable<Category[]>
  selectedCategory$: Observable<CategoryIdState>;

  constructor(public dialogRef: MatDialogRef<SelectCategoryDialogComponent>,
              private store: Store<AppState>,
              private matIconRegistry: MatIconRegistry, 
              private domSanitizer: DomSanitizer, 
             ) { 
              this.categories$ = this.store.pipe(select(selectBusinessCategories))
              this.selectedCategory$=this.store.pipe(select('categoryId'))

             }

  ngOnInit() {
    this.initializeMatIcons()
  }

  initializeMatIcons(): void {
    this.categories$.subscribe(
        next => {
            for(let category of next){
                if (category !== undefined) {
                    this.matIconRegistry.addSvgIcon(category.name,this.domSanitizer.bypassSecurityTrustResourceUrl(category.imageUrl));
                }  
            }
        } 
    )
 }


 
 onCategorySelection(categoryId:string){
  this.store.dispatch(new SaveCategoryIdAction(categoryId))
  localStorage.setItem("isCategoryDialogOpened","true")
  this.dialogRef.close()
 }
 

}