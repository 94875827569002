import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { Restaurant } from '~app/models/restaurant';
import { UpiPayment } from '~app/models/upi-details';
import { AppState } from '~app/reducers/main';
import { selectTotalBill } from '~app/selectors/order.selectors';
import { selectRestaurant } from '~app/selectors/restaurant.selectors';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { NotificationsService } from '~app/services/notifications.service';
import { UpiPaymentVerifyService } from '~app/services/upi-payment-verify.service';
import { UpiPaymentDetailsDialogComponent } from '../upi-payment-details-dialog/upi-payment-details-dialog.component';

export interface paymentMode{
  name:string,
  imageUrl:string
}

@Component({
  selector: 'app-upi-payment-verify',
  templateUrl: './upi-payment-verify.component.html',
  styleUrls: ['./upi-payment-verify.component.scss']
})
export class UpiPaymentVerifyComponent implements OnInit {

  upiPaymentVerifyForm: FormGroup;
  paymentModes:paymentMode[]=[ {name:"Google Pay",imageUrl:"../assets/icons/gPay.svg"} ,
                               {name:"PhonePe",imageUrl:"../assets/icons/phonePe.svg"},
                               {name:"Paytm",imageUrl:"../assets/icons/paytm.svg"},
                               {name:"Other UPI",imageUrl:"../assets/icons/upi.svg"},
                              ]

  lastDigitsOfUpiPayment: number 

  totalBill$: Observable<number>

  totalBill: number;

  restaurantObj$: Observable<Restaurant>;


  private initForm() {
    this.upiPaymentVerifyForm = new FormGroup({
        // tslint:disable-next-line:max-line-length
        PaymentMode: new FormControl('', 
        Validators.compose([Validators.required])),
        paidAmount: new FormControl('', 
        Validators.compose([Validators.required]))
    });
 }
  constructor(
    private upiPaymentVerifyService: UpiPaymentVerifyService,
    public dialog: MatDialog,
    public loadingIndicatorService: LoadingIndicatorService,
    public spinner: NgxSpinnerService,
    private notificationsService: NotificationsService,
    private store: Store<AppState>,
    private router: Router,
    private activatedRoute: ActivatedRoute 
  ) { 
    this.totalBill$ = this.store.pipe(select(selectTotalBill))
    this.restaurantObj$ = this.store.pipe(select(selectRestaurant))
  }

  ngOnInit() {
    this.initForm()
  }

  onSubmit(){

    const emptyCartconfirmDialogRef = this.dialog.open(UpiPaymentDetailsDialogComponent, {
         
    });
    emptyCartconfirmDialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {

        this.loadingIndicatorService.set(LoadingContextMap.UPDATING)
        this.spinner.show()

        let paymentType = this.upiPaymentVerifyForm.get('PaymentMode').value
        let amount = this.upiPaymentVerifyForm.get('paidAmount').value
        let upipymnt: UpiPayment = {
          paymentType: "UPI",
          ref: paymentType+'-'+this.lastDigitsOfUpiPayment,
          amount: +amount
        }
    
    
        this.upiPaymentVerifyService.postUpiPaymentDetails(upipymnt).subscribe(
          next => {
            this.loadingIndicatorService.set(LoadingContextMap.SUCCESS)
            this.spinner.hide()

            this.router.navigate(['../payment-initiate'],{relativeTo: this.activatedRoute})
          },
          error => {
            this.notificationsService.showMessage((error as Error).message)
          }
        )
      }
    })


  }
  onOtpChange($event){
    this.lastDigitsOfUpiPayment = $event
  }


}
