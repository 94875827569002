import * as fromCart from '../reducers/cart.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectByItemId, selectMenuEntities } from './menu.selectors';
import { selectBasketIds, selectBasketEntities } from './basket.selectors';
export interface State{
    cart: fromCart.CartState
  }
  
  export const selectCartState = createFeatureSelector<fromCart.CartState>('cart')

  export const selectCartIds = createSelector(
    selectCartState,
    (cartState) => fromCart.selectCartIds(cartState)
  )

  export const selectCartEntities = createSelector(
    selectCartState,
    (cartState) => fromCart.selectCartEntities(cartState)
  )

  // export const selectCartCount = createSelector(
  //   selectCartState,
  //   (cartState) => fromCart.selectCartTotal(cartState)
  // )

  export const selectCartItems = createSelector(
    selectBasketIds,
      selectMenuEntities,
      selectBasketEntities,
      (ids, menu, bEntities) => {
        let final_result = []
        for(let id of ids){
            final_result.push({...menu[id], quantity: bEntities[id].quantity, referrer: bEntities[id].referrer, intendedItemID: bEntities[id].intendedItemID, intendedPrice: bEntities[id].intendedPrice, optPrice: bEntities[id].optPrice})
        }
        return final_result
      }
  )

  export const selectCartCount= createSelector(
      selectBasketIds,
      selectBasketEntities,
      (ids, bEntities) => {
        let cartCount:number=0;
        for(let id of ids){
            cartCount = cartCount+bEntities[id].quantity
        }
        return cartCount
      }
  )

  export const selectTotalCartBill = createSelector(
    selectCartItems,
      (items) => {
          let bill = 0
          for(let it of items){
              bill += it.quantity*(it.price-(it.price*it.offer/100))
          }
          return bill
      }
  )
