import { Action } from '@ngrx/store';


export enum JokeActionTypes{
    UPDATE_JOKE ='[Joke Component] update joke',
}

export class UpdateJokeAction implements Action{

    readonly type = JokeActionTypes.UPDATE_JOKE

    constructor(public payload: any){}

}

export type JokeActions = UpdateJokeAction