import { restaurantActions, restaurantActionTypes } from '~app/actions/restaurant.actions';
import { Restaurant } from '~app/models/restaurant';


export let initialState: Restaurant = {
    id: null,
    name: null,
    tagLine: null,
    description: null,
    pureVeg: null,
    code: null,
    serves: null,
    logoUrl: null,
    imageUrl: null,
    titleImageUrl:null,
    orderingEnabled:false,
    staticQRCantOrder: false,
    deliveryContact: null,
    videoStories:[],

    roundOffID: null,

    pricingTier: null,
}

export function restaurantReducer(state=initialState, action: restaurantActions){
    switch(action.type){
        case restaurantActionTypes.SAVE_RESTAURANT: {
            let restaurant: Restaurant = action.payload;
            return restaurant;
        }
        default: {
            return state;
        }
    }
}