import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { CallWaiterResponse } from '~app/models/call-waiter';
import { KnowYourProduct } from '~app/models/know-your-product';

@Injectable({
    providedIn: 'root'
})

export class KnowYourProductService {
    knowYourProductUrl='/api/leads';

    constructor(private http: HttpClient) {
    }

    submitknowYourProductResponse(response:KnowYourProduct):Observable<KnowYourProduct>{
        return this.http.post<KnowYourProduct>(this.knowYourProductUrl,response)
    }

}
