import { Action } from '@ngrx/store';

export enum themeActionTypes {
    CHANGE_THEME = '[Theme Component] change theme'
}

export class ChangeThemeAction implements Action{
    readonly type = themeActionTypes.CHANGE_THEME;
}

export type themeActions = ChangeThemeAction; 