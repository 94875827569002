import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-geo-location-access',
  templateUrl: './geo-location-access.component.html',
  styleUrls: ['./geo-location-access.component.scss']
})
export class GeoLocationAccessComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<GeoLocationAccessComponent>
  ) { }

  ngOnInit() {
  }

}
