export enum GenericConstants{
    WEBSOCKET_URL =  'wss://kittychef.in/api/ws'
}








