import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-nutrition-card',
    templateUrl: './nutrition-card.component.html',
    styleUrls: ['./nutrition-card.component.scss']
})
export class NutritionCardComponent implements OnInit {
    ngOnInit(): void {
        // throw new Error("Method not implemented.");
    }

    nutritions = {
        'cal': {'nutrient': 'Calories', 'value': '0.15', 'unit': 'KCal'},
        'carbo': {'nutrient': 'Carbs', 'value': '1.20', 'unit': 'Grams'},
        'protein': {'nutrient': 'Protein', 'value': '1.32', 'unit': 'Grams'},
        'fat': {'nutrient': 'Fat', 'value': '4.00', 'unit': 'Grams'},
    };

    constructor() {
    }

}
