import { WebsocketMessage } from '../models/websocket-message';
import { EntityState, Dictionary, createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { WebsocketActions, WebSocketActionTypes } from '~app/actions/websocket-log.actions';

export class WebsocketLogState implements EntityState<WebsocketMessage>{
    ids: string[] | number[];
    entities: Dictionary<WebsocketMessage>;
    capturedTimeStamp: string
}

export const adapter: EntityAdapter<WebsocketMessage> = createEntityAdapter<WebsocketMessage>();

let initialState: WebsocketLogState = adapter.getInitialState({capturedTimeStamp: undefined});

export function WebsocketLogStateReducer(state=initialState, action:WebsocketActions){
    switch(action.type){
        case WebSocketActionTypes.SAVE_WEBSOCKET_LOG: 
        case WebSocketActionTypes.GOT_ROUND_ADDED_MESSAGE: 
        case WebSocketActionTypes.GOT_ROUND_UPDATED_MESSAGE: {
            let payload = action.payload

            return adapter.addOne(payload, {...state, capturedTimeStamp: payload.data.timeStamp})
        }

        default: {
            return state
        }
    }
}

// get the selectors
const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = adapter.getSelectors();
   
  // select the array of user ids
  export const selectWebsocketLogStateIds = selectIds;
   
  // select the dictionary of user entities
  export const selectWebsocketLogStateEntities = selectEntities;
   
  // select the array of users
  export const selectAllWebsocketLogStates = selectAll;
   
  // select the total user count
  export const selectWebsocketLogStatesTotal = selectTotal

  export interface WebSocketConnectionStatusState {
    isActive: boolean
}


  let initialWebSocketConnectionStatusState: WebSocketConnectionStatusState = {
    isActive: false
}

export function WebSocketConnectionStatusReducer(state=initialWebSocketConnectionStatusState, action: WebsocketActions){
    switch(action.type){
        case WebSocketActionTypes.WEBSOCKET_CONNECTION_OFF:{
            return {
                isActive: false
            }
        }

        case WebSocketActionTypes.WEBSOCKET_CONNECTION_ON: {
            return {
                isActive: true
            }
        }
        default: {
            return state;
        }
    }
}
