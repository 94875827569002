
import { Action } from '@ngrx/store';

export enum PaymentModeActionTypes{
    SAVE_PAYMENT_MODES = '[PaymentMode component] save payment modes',
}

export class SavePaymentModesAction implements Action{
    readonly type = PaymentModeActionTypes.SAVE_PAYMENT_MODES

    constructor(public payload: any){}
}

export type paymentModeActions = SavePaymentModesAction