import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { item } from '../know-your-product.component';

@Component({
  selector: 'app-know-your-product-cross-dialog',
  templateUrl: './know-your-product-cross-dialog.component.html',
  styleUrls: ['./know-your-product-cross-dialog.component.scss']
})
export class KnowYourProductCrossDialogComponent implements OnInit {

  burgerImageUrl='../assets/images/burger.png' ;
  coldDrinkImageUrl='../assets/images/cold-drink.png' ;
  frenchFriesImageUrl='../assets/images/french-fries.png' ;

  constructor(public dialogRef: MatDialogRef<KnowYourProductCrossDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public cItems: item[]) { }

  ngOnInit() {
  }

  selectCrossSaleItem(addeditem:item){
    this.dialogRef.close({isCrossSaleItemSelected: true ,isMultiSelected:false,citem:addeditem})
  }

  multiSelectCrossSaleItems(){
    this.dialogRef.close({isCrossSaleItemSelected: true ,isMultiSeleted:true})
  }

  closeDialog(addeditem:item){
    this.dialogRef.close({isCrossSaleItemSelected: false})
  }

}
