import { Injectable } from '@angular/core';
import { CorrItem } from '~app/models/corr';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CorrService {

  corrUrl = 'api/corr-items'

  constructor(
    private http: HttpClient
  ) { }


  getCorrItems(corrID: string): Observable<CorrItem[]>{
    return this.http.get<CorrItem[]>(this.corrUrl+'/'+corrID)
  }
}
