import {Component, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef, HostListener, ViewEncapsulation, ChangeDetectionStrategy} from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';
import {ActivatedRoute, Router, Route} from '@angular/router';
import {RestaurantDataProvider} from '~services/restaurant-data-provider.service';
import {MenuItem} from '~models/menu-item';
import {Location} from '@angular/common';
import {NotificationsService} from '~services/notifications.service';
import { AppState } from '~app/reducers/main';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectByItemId, selectSimiarItems } from '~app/selectors/menu.selectors';
import { CategoryIdState } from '~app/reducers/categories.reducer';
import { AddItemtoCartAction, UpdateItemQuantityAction } from '~app/actions/cart.actions';
import { SaveCategoryIdAction } from '~app/actions/categories.actions';
@Component({
    selector: 'app-detailed-menu',
    templateUrl: './detailed-menu.component.html',
    styleUrls: ['./detailed-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class DetailedMenuComponent implements OnInit, OnChanges {

    
    vegIcon = '../assets/icons/Veg_icon.svg';

    nonVegIcon = '../assets/icons/Nonveg_icon.svg';

    desc = 'It can be compared to mixing a curry, later combining it with semi-cooked rice separately';

    similarItems: MenuItem[];
    similarItems$: Observable<MenuItem[]>

    selectedCategoryId: string;
    selectedCategoryId$: Observable<CategoryIdState>

    menuItem: MenuItem;
    menuItem$: Observable<MenuItem>
    restaurantId: string
    itemId: string;
    menuImages: string[];
    mainMenuImageUrl:string;
    orderItems:MenuItem[]=[];



    constructor(public router: Router,
                private activatedRoute: ActivatedRoute,
                private notificationsService: NotificationsService,
                private store: Store<AppState>) {
                    this.activatedRoute.paramMap.subscribe(params => {
                        this.activatedRoute.parent.paramMap.subscribe(p => {
                            this.restaurantId = p.get('restaurantId');
                            let itemId = params.get('itemId');
                            this.menuItem$ = this.store.pipe(select(selectByItemId, {id: itemId}))
                        }
                        )
                    });
                    
        this.selectedCategoryId$ = this.store.pipe(select('categoryId'));

        this.menuItem$.subscribe(next=>{
            this.menuItem = next;
              }  
            );
              
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.load();
    }


    ngOnInit(): void {
        this.store.dispatch( new SaveCategoryIdAction(this.menuItem.categoryId))
        this.selectedCategoryId$.subscribe(
            next => {
                this.selectedCategoryId = next.id
                this.similarItems$ = this.store.pipe(select(selectSimiarItems, {id:  this.selectedCategoryId}))
            },
            error => {
                console.log("Error in getting selected category id:", error)
            }

        )  
        this.load();
    }

    load(): void {
        this.activatedRoute.paramMap.subscribe(params => {
                this.activatedRoute.parent.paramMap.subscribe(p => {
                    this.restaurantId = p.get('restaurantId');
                    this.itemId = params.get('itemId');
                })
            },
            error => {
                this.notificationsService.showMessage((error as Error).message);
            }
        );
    }


    backQuickMenu(){
        this.store.dispatch(new SaveCategoryIdAction(this.menuItem.categoryId));
        this.router.navigate(['../../menu'], { relativeTo: this.activatedRoute});
    }

}
