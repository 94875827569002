import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {Menu} from '~models/menu';
import {Tag} from '~models/tag';
import {MenuItem} from '~models/menu-item';
import {Ingredient} from '~models/ingredient';
import {BannerService} from '~services/banner.service';
import { Category } from '~app/models/category';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private menuItemsUrl = 'api/items';
    private categoriesUrl = 'api/categories';
    private tagsUrl = 'api/labels';
    private ingredientsUrl = 'api/ingredients';
    private referenceMenu: Menu;

    constructor(private http: HttpClient, private bannerService: BannerService) {

    }

    getMenu(restaurantId: string): Observable<Menu> {
        // TODO: remove it probably after data passing
        if (this.referenceMenu !== undefined) {
            if (this.referenceMenu.getRestaurantId() === restaurantId) {
                return new Observable<Menu>(s => {
                    s.next(this.referenceMenu);
                    s.complete();
                });
            }
            this.referenceMenu = undefined;
        }

        const itemsObservable = this.getItems(restaurantId);
        const categoriesObservable = this.getCategories();
        const labelsObservable = this.getLabels();
        const ingredientsObservable = this.getIngredients();
        const activeBannerObservable = this.bannerService.getActiveBanner(restaurantId);
        // TODO: handle errors
        return new Observable<Menu>(subscriber => {
            forkJoin([itemsObservable, categoriesObservable, labelsObservable, ingredientsObservable, activeBannerObservable]).subscribe(results => {
                const menu = new Menu(restaurantId, results[0], results[1], results[2], results[3], results[4]);
                this.referenceMenu = menu;
                subscriber.next(menu);
                subscriber.complete();
            });
        });
    }

    getItems(restaurantId: string): Observable<MenuItem[]> {
        return this.http.get<MenuItem[]>(this.menuItemsUrl + '?restaurantId=' + restaurantId);
    }

    getCategories(): Observable<Category[]> {
        return this.http.get<Category[]>(this.categoriesUrl);
    }

    private getLabels(): Observable<Tag[]> {
        return this.http.get<Tag[]>(this.tagsUrl);
    }

    getIngredients(): Observable<Ingredient[]> {
        return this.http.get<Ingredient[]>(this.ingredientsUrl);
    }

}
