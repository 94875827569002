import { Action } from "@ngrx/store";

export enum UPIPActionsTypes{
    UPDATE_UPI = '[UPI Component] Update upi details',
}

export class UpdateUPIAction implements Action{
    readonly type = UPIPActionsTypes.UPDATE_UPI

    constructor(public payload: any){}
}



export type UPIActions =  UpdateUPIAction