import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { UpdateJokeAction } from '~app/actions/joke.actions';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { Joke } from '~app/models/joke';
import { AppState } from '~app/reducers/main';
import { JokeService } from '~app/services/joke.service';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';

@Component({
  selector: 'app-jokes',
  templateUrl: './jokes.component.html',
  styleUrls: ['./jokes.component.scss']
})
export class JokesComponent implements OnInit {

  joke$: Observable<Joke>
  joke: Joke

  jokeImage = '../assets/images/joke.png'

  constructor(
    private jokeService: JokeService,
    private store: Store<AppState>,
    public loadingIndicatorService: LoadingIndicatorService,
    public spinner: NgxSpinnerService,
  ) {
    this.joke$ = this.store.pipe(select('joke'))
   }

  ngOnInit() {
  }

  refresh(){
    this.loadingIndicatorService.set(LoadingContextMap.LOADING)
    this.spinner.show()

    this.jokeService.getRandomJoke().subscribe(
      next => {
        this.store.dispatch(new UpdateJokeAction(next))
        this.spinner.hide()
      },
      error => {
        this.spinner.hide()
      }
    )
  }

  selectLanguage(lang: string){
    localStorage.setItem("jokesLanguage", lang)
  }

  check(lang: string){
    let ln = localStorage.getItem('jokesLanguage')

    let result = false
    if(ln!=null){
      if(ln==lang){
        return true
      }
    }

    return result
  }

}
