import { Action } from '@ngrx/store';
import { categoriesReducer } from '~app/reducers/categories.reducer';

export enum categoriesActionTypes {
    LOAD_CATEGORIES = '[Categories component] load categories',
    SAVE_CATEGORIES = '[Categories component] save categories',
    SAVE_CATEGORY_ID = '[Categories component] save category id',
    RESET_CATEGORIES = '[Categories component] reset categories',
    SAVE_RELOAD_CATEGORIES = '[Categories component] save reload categories'
}


export class LoadCategoriesAction implements Action{
    readonly type = categoriesActionTypes.LOAD_CATEGORIES;
}

export class SaveCategoriesAction implements Action{
    readonly type = categoriesActionTypes.SAVE_CATEGORIES;

    constructor(public payload: any){}
}

export class SaveCategoryIdAction implements Action{
    readonly type = categoriesActionTypes.SAVE_CATEGORY_ID

    constructor(public payload: any){}
}

export class ResetCategoriesAction implements Action{
    readonly type = categoriesActionTypes.RESET_CATEGORIES
}

export class ReloadCategoriesAction implements Action{

    readonly type = categoriesActionTypes.SAVE_RELOAD_CATEGORIES

    constructor(public payload: any){}
}

export type categoriesActions = LoadCategoriesAction |
 SaveCategoriesAction | 
 SaveCategoryIdAction |
 ResetCategoriesAction |
 ReloadCategoriesAction;