import { Component, OnInit, ViewChild, ElementRef, SimpleChanges, HostListener, Input } from '@angular/core';
import { MenuItem } from '~app/models/menu-item';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { Router, ActivatedRoute } from '@angular/router';
import { RestaurantDataProvider } from '~app/services/restaurant-data-provider.service';
import { NotificationsService } from '~app/services/notifications.service';
import {Location} from '@angular/common';
import { SplGParentChildService } from '~app/services/spl-gparent-child.service';

@Component({
  selector: 'app-spl-detailed-menu',
  templateUrl: './spl-detailed-menu.component.html',
  styleUrls: ['./spl-detailed-menu.component.scss']
})
export class SplDetailedMenuComponent implements OnInit {

  //This is for scrolling 
  @ViewChild('stickyMenu', {static: true}) menuElement: ElementRef;

  @Input()
  menuItem: MenuItem;

  orderItems:MenuItem[];

  sticky: boolean = false;
  elementPosition: any;
  image:string;
  // ends here
  
  vegIcon = '../assets/icons/Veg_icon.svg';

  nonVegIcon = '../assets/icons/Nonveg_icon.svg';

  desc = 'It can be compared to mixing a curry, later combining it with semi-cooked rice separately';

  menuImagesUrls:string[];
  mainMenuImageUrl:string;

  itemId: string;
  restaurantId: string;

  constructor(public router: Router,
              private restaurantData: RestaurantDataProvider,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private notificationsService: NotificationsService,
              private splGrandChild: SplGParentChildService,) {
  }


  ngOnInit(): void {
    //   this.load();
    this.menuImagesUrls=this.menuItem.imageUrls.slice(0,3);
    this.mainMenuImageUrl=this.menuItem.mainImageUrl;
  }

  load(): void {
      this.activatedRoute.paramMap.subscribe(params => {
              this.itemId = params.get('itemId');
              this.restaurantId = params.get('restaurantId');

    //           this.restaurantData.loadRestaurantAndMenuById(this.restaurantId).subscribe(
    //               response => {
    //                   this.menuItem = this.restaurantData.menu.getItemById(this.itemId);
    //                   this.similarItems = this.restaurantData.menu.getItemsByCategory(this.menuItem.categoryId);
    //                   // Make sure we empty this every time because it is appending items
    //                   this.galleryImages = [];
    //                   this.menuItem.imageUrls.slice(0,3).forEach(u => {
    //                       this.galleryImages.push({
    //                           url: u,
    //                           small: u,
    //                           big: u,
    //                           medium: u,
    //                       });
    //                   });
    //                   this.galleryImages=this.galleryImages.slice(0,3);
    //               });
    //           },
    //       error => {
    //           this.notificationsService.showMessage((error as Error).message);
    //       }
    //   );
      });
  }

  navigateToQuickMenu() {
    //   console.log("going back:", this.location);
    //   this.location.back();
    this.splGrandChild.emitChildEventOnItemClick(false);
   
  }
//   openItem() {
//     //   this.router.navigate(['/restaurant', restaurantId, 'item', itemId]);
//      this.splGrandChild.emitChildEventOnItemClick(true);
//   }

  // ngAfterViewInit(){
  //     this.elementPosition = this.menuElement.nativeElement.offsetTop;
  // }

  // @HostListener('window:scroll')
  // onScroll(){
  //   const windowScroll = window.pageYOffset;
  //   if(windowScroll >= this.elementPosition){
  //     this.sticky = true;
  //   } else {
  //     this.sticky = false;
  //   }
  // }
  viewImage(selectedImageUrl: string){
      this.mainMenuImageUrl=selectedImageUrl;
  }
  addOrder(){  
    console.log("menu",this.menuItem);
    this.orderItems.push(this.menuItem);
    console.log("checkItems",this.orderItems);
    this.orderItems = Array.from(new Set(this.orderItems));
    console.log("check unique",this.orderItems);
}

}

