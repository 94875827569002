import { Component, OnInit, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { MenuItem } from '~app/models/menu-item';
import { ActiveBanner } from '~app/models/active-banner';
import { RestaurantDataProvider } from '~app/services/restaurant-data-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '~app/services/notifications.service';
import { QuickItemSkeletonService } from '~app/services/quick-item-skeleton.service';
import { Restaurant } from '~app/models/restaurant';
import { Category } from '~app/models/category';

@Component({
  selector: 'app-spl-quick-menu',
  templateUrl: './spl-quick-menu.component.html',
  styleUrls: ['./spl-quick-menu.component.scss']
})
export class SplQuickMenuComponent implements OnInit {

  restaurant: Restaurant;
  menuItems: MenuItem[];
  categories: Category[];
  banner: ActiveBanner;

  restaurantId: string;
  selectedCategoryId: string;

  constructor(private restaurantData: RestaurantDataProvider,
              private activatedRoute: ActivatedRoute,
              private notificationsService: NotificationsService,
              private changeDetectorRef: ChangeDetectorRef,
              private activateRoute: ActivatedRoute,
              private router: Router, private quickItemSkeleton: QuickItemSkeletonService) {
  }

  ngOnInit() {
  }

//   ngOnChanges(changes: SimpleChanges): void {
//       this.quickItemSkeleton.update(false);
//       console.log('Category id', this.selectedCategoryId);
//       this.copyRestaurantMenu();
//       this.quickItemSkeleton.update(true);
//   }

//   private copyRestaurantMenu() {
//       this.restaurant = this.restaurantData.restaurant;
//       this.categories = this.restaurantData.menu.getCategories();
//       this.menuItems = this.restaurantData.menu.getItemsByCategory(this.selectedCategoryId);
//       this.banner = this.restaurantData.menu.getActiveBanner();
//   }

  // Have to use state management technique as an alternative
//   private reloadRestaurantData() {

//       // this.quickItemSkeleton.update(true);
//       this.activatedRoute.paramMap.subscribe(routeParams => {
//           this.restaurantId = routeParams.has('restaurantId') ? routeParams.get('restaurantId') : null;
//       });

//       this.restaurantData.loadRestaurantAndMenuById(this.restaurantId).subscribe(
//           response => {
//               this.copyRestaurantMenu();
//               this.changeDetectorRef.detectChanges();
//           },
//           error => {
//               this.notificationsService.showMessage((error as Error).message);
//           }
//       );

      
//       this.quickItemSkeleton.update(false);
//   }

  /*function onTabChange(tabIndex){
      const personId = <get person id>;
      window.history.replaceState({}, '',`/person/${personId}/${tabIndex}`);
  }*/

//   categoryIdChangeHandler(categoryId: string) {
//       this.selectedCategoryId = categoryId;
//       this.menuItems = this.restaurantData.menu.getItemsByCategory(this.selectedCategoryId);
//       this.router.navigate([this.router.url.substr(0, this.router.url.indexOf('?category='))],
//           {queryParams: {category: categoryId}});
//   }

}
