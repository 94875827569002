import { Component, OnInit } from '@angular/core';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { PartyQR } from '~app/models/party';
import { NotificationsService } from '~app/services/notifications.service';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { AuthService } from '~app/services/auth.service';
import { SnackBarMessageMap } from '~app/constants/snackbar-message.constants';
import { CartService } from '~app/services/cart.service';
import { CartPort } from '~app/models/cart';
import { AuthContext } from '~app/models/auth-context';
import { Router } from '@angular/router';

@Component({
  selector: 'app-joinparty-scan',
  templateUrl: './joinparty-scan.component.html',
  styleUrls: ['./joinparty-scan.component.scss']
})
export class JoinpartyScanComponent implements OnInit {

  constructor(
    public notificationService: NotificationsService,
    public loadingIndicatorService: LoadingIndicatorService,
    public spinner: NgxSpinnerService,
    private authService: AuthService,
    private cartService: CartService,
    private router: Router,
  ) { }

  ngOnInit() {
  }


  scanSuccessHandler(data){
    this.loadingIndicatorService.set(LoadingContextMap.QR_SCAN)
    this.spinner.show()

    let d: PartyQR = JSON.parse(data)

    let restaurantId = this.authService.getContext().restaurantId

    if(d.restaurantId === restaurantId){

      let cp: CartPort = {
        from: this.authService.getContext().cartID,
        to: d.cartId,
        of: d.userId,
        for: d.partyId
      }

      this.cartService.cartport(cp).subscribe(
        next => {
          this.loadingIndicatorService.set(LoadingContextMap.SUCCESS)
          this.spinner.hide()

          // cartID should be changed
          // Set party

          let auth: AuthContext = JSON.parse(localStorage.getItem("auth"))

          let newAuth: AuthContext = {
            ...auth,
            cartID: d.cartId,
          }

        localStorage.setItem('auth', JSON.stringify(newAuth))
        localStorage.setItem('partyID', d.partyId)

        this.router.navigate(['restaurant', restaurantId, 'join-party'])

        localStorage.setItem('isPartyRunning', 'true')
        },
        error => {
          console.log("Error in porting the carts:", error)
          this.spinner.hide()
        }
      )

    }else{
      this.spinner.hide()
      this.notificationService.showMessage(SnackBarMessageMap.WRONG_PARTY_QR_SCAN)
    }

  }

}
