import { EntityState, Dictionary, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CartItem } from '~app/models/cart';
import { BasketActions, BasketActionTypes } from '~app/actions/basket.actions';

export class BasketState implements EntityState<CartItem>{
    ids: string[] | number[];
    entities: Dictionary<CartItem>;
}

export const adapter: EntityAdapter<CartItem> = createEntityAdapter<CartItem>({
    selectId: (ci: CartItem) => ci.itemID,
  });

let initialState: BasketState = adapter.getInitialState();

export function basketReducer(state=initialState, action: BasketActions){
    
    switch(action.type){
        case BasketActionTypes.UPDATE_BASKET: {
            return adapter.setAll(action.payload,state)
        }

        case BasketActionTypes.CLEAR_BASKET: {
            return undefined
        }

        default: {
            return state
        }
    }
}

// get the selectors
const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = adapter.getSelectors();
   
  // select the array of user ids
  export const selectBasketIds = selectIds;
   
  // select the dictionary of user entities
  export const selectBasketEntities = selectEntities;
   
  // select the array of users
  export const selectAllBasket = selectAll;
   
  // select the total user count
  export const selectBasketTotal = selectTotal