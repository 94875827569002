import { Injectable } from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects'
import { switchMap, map } from 'rxjs/operators';
import { MenuService } from '~app/services/menu.service';
import { categoriesActionTypes, SaveCategoriesAction } from '~app/actions/categories.actions';

@Injectable()
export class CategoriesEffects {

    constructor(private actions$: Actions, private menuService: MenuService){}

    @Effect()
    LoadCategoriesAction$ = this.actions$.pipe(
        ofType(categoriesActionTypes.LOAD_CATEGORIES),
        switchMap(() => this.menuService.getCategories()
        .pipe(
            map(res => new SaveCategoriesAction(res))
        )),
        
    )
}
