import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-kittypos',
  templateUrl: './privacy-policy-kittypos.component.html',
  styleUrls: ['./privacy-policy-kittypos.component.scss']
})
export class PrivacyPolicyKittyposComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
