import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-cart-empty-dialog',
  templateUrl: './cart-empty-dialog.component.html',
  styleUrls: ['./cart-empty-dialog.component.scss']
})
export class CartEmptyDialogComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<CartEmptyDialogComponent>) { }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close({confirm: false})
  }

  confirm(){
    this.dialogRef.close({confirm: true})
  }

}
