import { Injectable } from '@angular/core';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class ConnectivityOfflineService {

  durationInSeconds = 0
  horizontalPosition: MatSnackBarHorizontalPosition = 'center'
  verticalPosition: MatSnackBarVerticalPosition = 'bottom'

  message = 'You are currently offline!'
  action = 'CLOSE'
  
  constructor(
    private snackBar: MatSnackBar
  ) { }

  show() {
    let snackBarRef = this.snackBar.open(this.message, this.action,
        {
            duration: this.durationInSeconds*1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });

    return snackBarRef
  }

}
