import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Restaurant } from '~app/models/restaurant';
import { AppState } from '~app/reducers/main';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {

  restaurant$:  Observable<Restaurant>;

  constructor(private router: Router,
    private store: Store<AppState>,
          ) { 
            this.restaurant$=this.store.pipe(select('restaurant'))

  }

  ngOnInit() {
  }


  redirectToGoogleReview(reviewurl:string){
        window.open(reviewurl);
  }

}