import {Tag} from '~models/tag';
import {MenuItem} from '~models/menu-item';
import {Ingredient} from '~models/ingredient';
import {ActiveBanner} from '~models/active-banner';
import { Category } from './category';

export class Menu {
    private itemsMap = new Map<string, MenuItem>();
    private tagsMap = new Map<string, Tag>();
    private categoriesMap = new Map<string, Category>();
    private itemsByCategoryMap = new Map<string, MenuItem[]>();
    private ingredientsMap = new Map<string, Ingredient>();

    private imagesUrlPath = '/api/images/';

    constructor(private restaurantId: string,
                private items: MenuItem[],
                private categories: Category[],
                private labels: Tag[],
                private ingredients: Ingredient[],
                private activeBanner: ActiveBanner) {

        for (const ingredient of ingredients) {
            this.ingredientsMap.set(ingredient.id, ingredient);
        }

        for (const label of labels) {
            this.tagsMap.set(label.id, label);
        }

        for (const item of items) {
            const itemTags: Tag[] = [];
           // const imageUrls: string[] = [];
            for (const tagId of item.labels) {
                itemTags.push(this.tagsMap.get(tagId));
            }
            item.tags = itemTags;
            item.category = this.categoriesMap.get(item.categoryId);
            item.mainImageUrl = item.imageUrls[0];

            this.itemsMap.set(item.id, item);
            if (!this.itemsByCategoryMap.has(item.categoryId)) {
                this.itemsByCategoryMap.set(item.categoryId, []);
            }
            this.itemsByCategoryMap.get(item.categoryId).push(item);

            if (item.ingredientIds !== undefined && item.ingredientIds != null) {
                const itemIngredients: Ingredient[] = [];
                for (const ingId of item.ingredientIds) {
                    itemIngredients.push(this.ingredientsMap.get(ingId));
                }
                item.ingredients = itemIngredients;
            }
        }

        for (const category of categories) {
            if (this.itemsByCategoryMap.has(category.id)) {
                this.categoriesMap.set(category.id, category);
            }
        }

        this.activeBanner = activeBanner;
    }

    getItems(): MenuItem[] {
        return this.items;
    }

    getItemById(itemId: string): MenuItem {
        return this.itemsMap.get(itemId);
    }

    getItemsByCategory(categoryId: string): MenuItem[] {
        const catId: string = categoryId;
        return this.itemsByCategoryMap.get(catId);
    }

    getCategories(): Category[] {
        return [ ...this.categoriesMap.values() ];
    }

    getRestaurantId(): string {
        return this.restaurantId;
    }

    getActiveBanner(): ActiveBanner {
        return this.activeBanner;
    }

}
