import {Component, Input, OnInit, ViewEncapsulation, ChangeDetectionStrategy} from '@angular/core';
import {MenuItem} from '~models/menu-item';
import {Router} from '@angular/router';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-similar-items',
    templateUrl: './similar-items.component.html',
    styleUrls: ['./similar-items.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SimilarItemsComponent implements OnInit {
   
    @Input() similarItems$: Observable<MenuItem[]>;

    vegIcon = '../assets/icons/Veg_icon.svg';
    nonVegIcon = '../assets/icons/Nonveg_icon.svg';


    constructor(public router: Router) {
    }

    ngOnInit() {
    }

    openItem(restaurantId, itemId) {
        this.router.navigate(['/restaurant', restaurantId, 'item', itemId]);
    }
}
