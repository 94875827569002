import { Sidenavaction, SidenavactionTypes } from '~app/actions/sidenav.action';


export interface Sidenavstate {
    toggle:boolean;
}
let initialstate  : Sidenavstate={
    toggle:false,
}
export function sidenavreducer(state=initialstate  , action: Sidenavaction) {
    switch(action.type){
        case SidenavactionTypes.SIDENAV_TOGGLE:{
            return {
                toggle: !state.toggle
              }
        }

        case SidenavactionTypes.CLOSE_SIDENAVBAR: {
            return {
                toggle: false
            }
        }
        default :{
            return state
        }
    }
  }