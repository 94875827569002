import { JokeActions, JokeActionTypes } from "~app/actions/joke.actions"
import { Joke } from "~app/models/joke"


let initialstate  : Joke= undefined

export function jokereducer(state=initialstate  , action: JokeActions) {
    switch(action.type){
        case JokeActionTypes.UPDATE_JOKE:{
            return action.payload
        }

        default :{
            return state
        }
    }
  }