import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CarousalComponent} from '~components/kitty-home/carousal/carousal.component';
import {ScannerComponent} from '~components/kitty-home/scanner/scanner.component';
import {CodeSearchComponent} from '~components/kitty-home/code-search/code-search.component';
import {LocationSearchComponent} from '~components/kitty-home/location-search/location-search.component';
import {HomeComponent} from '~components/kitty-home/home/home.component';
import {DetailedMenuComponent} from '~components/menu/detailed-menu/detailed-menu.component';
import {QuickMenuComponent} from '~components/menu/quick-menu/quick-menu.component';
import {KittyOpinionsComponent} from '~components/kitty-opinions/kitty-opinions.component';
import {RestaurantFeedbackComponent} from '~components/restaurant-feedback/restaurant-feedback.component';
import {BusinessFooterComponent} from '~components/business-header-footer/business-footer/business-footer.component';
import {ThankyouBusinessComponent} from './components/thankyou/thankyou-business/thankyou-business.component';
import {ThankyouKittyComponent} from './components/thankyou/thankyou-kitty/thankyou-kitty.component';
import { OnboardComponent } from './components/kitty-home/onboard/onboard.component';
import { RestaurantHomeComponent } from './components/restaurant-home/restaurant-home.component';
import { ServiceableComponent } from './components/serviceable/serviceable.component';
import { OrderSummaryComponent } from './components/order-summary/order-summary.component';
// import { PaymentscreenComponent } from './components/paymentscreen/paymentscreen.component';
import { CartSummaryComponent } from './components/cart-summary/cart-summary.component';
import { ReportProblemPageComponent } from './components/report-problem-page/report-problem-page.component';
import { PaymentInitiateScreenComponent } from './components/payment-initiate/paymentscreen.component';
import { PaymentFinalComponent } from './components/payment-final/payment-final.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { CompleteDiningComponent } from './components/complete-dining/complete-dining.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { ScanComponent } from './components/scan/scan.component';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { PaymentFailureComponent } from './components/payment-failure/payment-failure.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { DiningOptionsComponent } from './components/dining-options/dining-options.component';
import { NearByRestaurantsComponent } from './components/near-by-restaurants/near-by-restaurants.component';
import { DeliveryDetailsComponent } from './components/delivery-details/delivery-details.component';
import { AuthTokenComponent } from './components/auth-token/auth-token.component';
import { JoinpartyComponent } from './components/joinparty/joinparty.component';
import { JoinpartyCreateComponent } from './components/joinparty/joinparty-create/joinparty-create.component';
import { JoinpartyScanComponent } from './components/joinparty/joinparty-scan/joinparty-scan.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { UpiPaymentVerifyComponent } from './components/upi-payment-verify/upi-payment-verify.component';
import { PrivacyPolicyKittyposComponent } from './components/privacy-policy-kittypos/privacy-policy-kittypos.component';
import { JokesComponent } from './components/jokes/jokes.component';
import { KnowYourProductComponent } from './components/know-your-product/know-your-product.component';
import { BusinessPageComponent } from './components/business-page/business-page.component';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';

const routes: Routes = [
    // {path: 'scan-qr', component: CarousalComponent},


    {path: 'restaurant/:restaurantId', component: RestaurantHomeComponent, children: [
       {path: 'menu', component: QuickMenuComponent},
       {path:'home',component: BusinessPageComponent},
       {path: 'item/:itemId', component: DetailedMenuComponent},
       {path:'serviceables',component : ServiceableComponent},
       {path:'feedback',component:RestaurantFeedbackComponent},
       {path:'kitty-feedback',component: KittyOpinionsComponent},
       {path:'order-summary',component:OrderSummaryComponent},
       {path:'payment-initiate',component:PaymentInitiateScreenComponent,},
       {path:'payment',component:PaymentFinalComponent},
       {path:'payment-success',component:PaymentSuccessComponent},
       {path:'payment-failure',component:PaymentFailureComponent},
       {path:'upi-payment-verify',component: UpiPaymentVerifyComponent},
       {path:'cart-summary',component:CartSummaryComponent},
       {path:'report-problem',component:ReportProblemPageComponent},
       {path:'complete-dining',component: CompleteDiningComponent},
       {path:'qr-code',component:QrCodeComponent},
       {path:'join-party',component:JoinpartyComponent},
       {path:'jokes',component:JokesComponent},
       {path:'join-party-create',component:JoinpartyCreateComponent},
       {path:'join-party-scan',component:JoinpartyScanComponent},
       {path:'feedback-form',component:FeedbackFormComponent},
       {path:'contact-us',component:ContactUsComponent},
       {path: 'thankyou', component: ThankyouKittyComponent},
    ]},

    

    {path: '', component: HomeComponent, children: [
        {path: '', component: ScanComponent},
        {path: 'scanner', component: ScannerComponent}, //TODO: No longer needed, but kept it for some time
        {path: 'scan', component: ScanComponent},
        {path: 'dining-options', component: DiningOptionsComponent},
        {path: 'delivery-details', component: DeliveryDetailsComponent},
        {path: 'nearby-restaurants', component: NearByRestaurantsComponent},
        {path: 'auth-token', component: AuthTokenComponent},
        {path: 'session', component: OnboardComponent},
        {path: 'privacy-policy', component: PrivacyPolicyComponent},
        {path: 'leads/kittycat/:mobilenumber',component:KnowYourProductComponent} ,
        {path: 'privacy-policy-kittypos', component: PrivacyPolicyKittyposComponent},
    ]},

    {path: '**', component: ThankyouComponent}



    // {path: 'restaurant/:restaurantId', component: QuickMenuComponent, children: [
    //     {path: 'item/:itemId', component: DetailedMenuComponent},
    //     {path: 'rate-kitty-home', component: RateRestaurantComponent},
    //     {path: 'kitty-opinion', component: KittyOpinionsComponent},
    //     {path: 'restaurant-feedback/:restaurantId', component: RestaurantFeedbackComponent},
    //     {path: 'dummy', component: DummyComponent},
    //     {path: 'footer', component: BusinessFooterComponent},
    //     {path: 'thankyou-business', component: ThankyouBusinessComponent},
    //     {path: 'thankyou-kitty', component: ThankyouKittyComponent},
    //     {path: 'kitty-list',component:KittyListComponent},
    // ]},
    // {path: 'restaurant/:restaurantId/item/:itemId', component: DetailedMenuComponent},
    // {path: 'rate-kitty-home', component: RateRestaurantComponent},
    // {path: 'kitty-opinion', component: KittyOpinionsComponent},
    // {path: 'restaurant-feedback/:restaurantId', component: RestaurantFeedbackComponent},
    // {path: 'dummy', component: DummyComponent},
    // {path: 'footer', component: BusinessFooterComponent},
    // {path: 'thankyou-business', component: ThankyouBusinessComponent},
    // {path: 'thankyou-kitty', component: ThankyouKittyComponent},
    // {path: 'kitty-list',component:KittyListComponent},
    // {path: '**', component: HomeComponent}

    /*{path: 'kitty-home-menu/:restaurantId', component: RestaurantHomeComponent},*/
];

@NgModule({
    imports: [RouterModule.forRoot(
        routes,
        {enableTracing: true, scrollPositionRestoration: 'top'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
