/*
QuestionId string `json:"questionId"`
	SingleSelectionResponse *MultipleChoiceSingleSelectionQuestionResponse `json:"singleSelectionResponse"`
	MultiSelectionResponse  *MultipleChoiceMultiSelectionQuestionResponse `json:"multiSelectionResponse"`
	RangeResponse *RangeQuestionResponse `json:"rangeResponse"`
	TextResponse *TextQuestionResponse `json:"textResponse"`
 */
export class SingleSelectionResponse {
    constructor(choiceId: string) {
        this.selectedChoiceId = choiceId;
        //  this.selectedChoiceId = choiceId;
    }

    selectedChoiceId: string;
    // selectedIndex: number;
}
