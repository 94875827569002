import {Component, Input, OnInit} from '@angular/core';
import { Restaurant } from '~app/models/restaurant';

@Component({
    selector: 'app-restaurant-header',
    templateUrl: './restaurant-header.component.html',
    styleUrls: ['./restaurant-header.component.scss']
})
export class RestaurantHeaderComponent implements OnInit {

    @Input() restaurant: Restaurant;

    constructor() {
    }

    ngOnInit() {
    }

}
