import { Component, OnInit } from '@angular/core';
import { AppState } from '~app/reducers/main';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectServiceItems } from '~app/selectors/service-items.selectors';
import { ServiceItem } from '~app/models/service-item';
import { Datastore } from '~app/jsonapi/store-service';
import { ServiceItemRequest } from '~app/jsonapi/service-item-request.model';
import * as ServiceItemJsonApi  from '~app/jsonapi/service-item.model';
import { Restaurant } from '~app/models/restaurant';

@Component({
  selector: 'app-serviceable',
  templateUrl: './serviceable.component.html',
  styleUrls: ['./serviceable.component.scss']
})
export class ServiceableComponent implements OnInit {


  selectedServiceItems:ServiceItem[] = [];
  restaurant$:Observable<Restaurant>;


  noServiceablesImage = '../assets/images/no_serviceables.png'

  serviceables$: Observable<ServiceItem[]>;

  constructor(private store:Store<AppState> ,
              private dataStore:Datastore) { 
                this.serviceables$=this.store.pipe(select(selectServiceItems))
                this.restaurant$=this.store.pipe(select('restaurant'))

   }

  ngOnInit() {
  

  }


sendServiceItemsRequest(){
  console.log("submit",this.selectedServiceItems);
  let serviceItems = [] ;
  this.store.pipe(select('dinerIdentity')).subscribe(
    next =>{

      for(let serviceItem of this.selectedServiceItems){
         let item = new ServiceItemJsonApi.ServiceItem(this.dataStore)
         item.id=serviceItem.id
         serviceItems.push(item)
      }

    //sending request of service items 
      let serviceItemRequest=this.dataStore.createRecord(ServiceItemRequest,{
        sessionId:next.sessionID,
        restaurantId:next.restaurantID,
      })
      serviceItemRequest.items= serviceItems
      serviceItemRequest.save().subscribe(
        next =>{
          console.log("successful sending service items request")
        },
        error =>{
          console.log("error in sending of service-items request",error)
        }
       )

    }
  )
}

selectServiceable(serviceable:ServiceItem){
  let index = this.selectedServiceItems.indexOf(serviceable);
  if(index === -1){
    this.selectedServiceItems.push(serviceable);
  }
  if(index > -1){
    this.selectedServiceItems.splice(index,1);
  }
  this.selectedStyle(serviceable);
}



selectedStyle(serviceable:ServiceItem) {
  let style: any;
  let style1 = {
    'opacity': '1'
  };
  let style2 = {
    'opacity': '0.3'
  };
  style = style2;
  this.selectedServiceItems.forEach(
    element => {
      if (element === serviceable) {
        style = style1;
      }
    }
  );
  return style;
}
}
