import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Party } from '~app/models/party';

@Injectable({
  providedIn: 'root'
})
export class PartyService {

  partyUrl = 'api/party'

  constructor(
    private http: HttpClient,
  ) { }

  create(p: Party):Observable<any>{
    return this.http.post<any>(this.partyUrl, p)
  }

  getParty(pID: string): Observable<Party>{
    return this.http.get<Party>(this.partyUrl+'/'+pID)
  }

  update(){
    //PUT update the existing party
  }




}
