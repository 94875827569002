import {Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterContentInit, AfterViewInit} from '@angular/core';
import { AppState } from '~app/reducers/main';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectRestaurant } from '~app/selectors/restaurant.selectors';
import { Restaurant } from '~app/models/restaurant';
import { ActivatedRoute, Router } from '@angular/router';
import { url } from 'inspector';
import { QuickMenuComponent } from '~app/components/menu/quick-menu/quick-menu.component';
import { selectCartCount } from '~app/selectors/cart.selectors';
import { cartLocationService, position } from '~app/services/cart-location.service';

@Component({
    selector: 'app-business-header',
    templateUrl: './business-header.component.html',
    styleUrls: ['./business-header.component.scss']
})
export class BusinessHeaderComponent implements OnInit,AfterViewInit {
    restaurantObj$: Observable<Restaurant>;
    @ViewChild(QuickMenuComponent,{static:false} ) quickmenu :QuickMenuComponent;
    badgeValue$: Observable<number>

    @Output()
    toggle = new EventEmitter();
    

    constructor(private store: Store<AppState>,
        private cartLocationService:cartLocationService,
        private elementRef:ElementRef,
        public router: Router,
        private route: ActivatedRoute) {
   
 }

    ngOnInit() {
       
        // this.restaurantObj$ = this.store.pipe(select('restaurant'))
        this.restaurantObj$ = this.store.pipe(select(selectRestaurant))
        this.badgeValue$ = this.store.pipe(select(selectCartCount))
        

    }

    openSideNav(){
        this.toggle.emit();
    }
  
    viewHome(id:string){
        this.router.navigate(['restaurant',id,'home']);

    }

    ngAfterViewInit() {
        this.setCartPosition();
      }

    setCartPosition():void{
        let cartDiv = this.elementRef.nativeElement.querySelector("#cart-icon").getBoundingClientRect();
        let x = cartDiv.left+10;
        let y = cartDiv.top;
        this.cartLocationService.changePosition(new position(x,y));
      }
}
