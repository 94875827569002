import {JsonApiModelConfig, JsonApiModel, Attribute, HasMany, BelongsTo} from 'angular2-jsonapi';
import { Round } from './round.model';


@JsonApiModelConfig({
    type: 'orders'
})

export class Order extends JsonApiModel {

    @Attribute()
    orderTotal: number;

    @Attribute()
    status:string;

    @Attribute()
    netPrce:number;

    @HasMany()
    rounds:Round[];

    
}