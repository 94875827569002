
import {Action} from '@ngrx/store';

export enum restaurantActionTypes {
    SAVE_RESTAURANT = '[restaurant Component] save restaurant'
}


export class SaveRestaurantAction implements Action{
    readonly type = restaurantActionTypes.SAVE_RESTAURANT;

    constructor(public payload: any){}
}

export type restaurantActions = SaveRestaurantAction;