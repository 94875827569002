import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import { ProgressBarService } from '~app/services/progress-bar.service';
import { Observable } from 'rxjs';
import { AppState } from '~app/reducers/main';
import { select, Store } from '@ngrx/store';
import { CategoryIdState } from '~app/reducers/categories.reducer';
import { Category } from '~app/models/category';

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CategoriesComponent implements OnInit, OnChanges {

    @Input() 
    categories$: Observable<Category[]>

    @Output() selectedCategoryIdEmitter = new EventEmitter<string>();
 
    selectedCategoryId: string;
    selectedCategoryNumber: number;
    totalCategories: number;
    

    showCategoriesSkeleton: boolean = false;
    selectedCategory$: Observable<CategoryIdState>;

    constructor(private matIconRegistry: MatIconRegistry, 
        private domSanitizer: DomSanitizer, 
        private store:Store<AppState>,
        private progressBarService: ProgressBarService) {
    }

    ngOnInit() {
        this.initializeMatIcons();
        this.selectedCategory$=this.store.pipe(select('categoryId'))
        
    }

    onCategorySelection(categoryId: string, index: number) {
        this.selectedCategoryId = categoryId;
        this.selectedCategoryNumber = index + 1; // this is for fab, 1 added because its starts from 0
        this.selectedCategoryIdEmitter.emit(categoryId);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initializeMatIcons();
    }

    initializeMatIcons(): void {
        this.categories$.subscribe(
            next => {
                for(let category of next){
                    if (category !== undefined) {
                        this.matIconRegistry.addSvgIcon(category.name,
                            this.domSanitizer.bypassSecurityTrustResourceUrl(category.imageUrl));
                    }  
                }
            }
        )
    }

}
