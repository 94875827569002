import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent implements OnInit {

  latitude = -28.68352;
  longitude = -147.20785;
  mapType = 'satellite';

  constructor() { }

  ngOnInit() {
  }

  getCoordinates() {
       navigator.geolocation.getCurrentPosition((pos) => {
           console.log(pos)
       });
  }

}
