import {RangeResponse} from '~models/range-response';
import {TextResponse} from '~models/text-response';
import {MultiSelectionResponse} from '~models/multi-selection-response';
import {SingleSelectionResponse} from '~models/single-selection-response';

export class QuestionResponse {

    constructor(questionId: string, response: any) {
        this.questionId = questionId;
        if (response instanceof SingleSelectionResponse) {
            this.singleSelectionResponse = response;
        } else if (response instanceof MultiSelectionResponse) {
            this.multiSelectionResponse = response;
        } else if (response instanceof RangeResponse) {
            this.rangeResponse = response;
        } else if (response instanceof TextResponse) {
            this.textResponse = response;
        }

        console.log('QR QR QR QR QR QR QR', this);
    }


    questionId: string;
    singleSelectionResponse: SingleSelectionResponse;
    multiSelectionResponse: MultiSelectionResponse;
    rangeResponse: RangeResponse;
    textResponse: TextResponse;
}