import { Component, OnInit, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '~app/reducers/main';
import { RoundToAdd } from '~app/reducers/round.reducer';

@Component({
  selector: 'app-order-course',
  templateUrl: './order-course.component.html',
  styleUrls: ['./order-course.component.scss']
})
export class OrderCourseComponent implements OnInit {

  @Input()
  round: RoundToAdd
  
  statusFlag=0;

  itemCount:number=4;

  courseStatus:boolean=false;

  constructor( private store: Store<AppState>) { }

  ngOnInit() {
    console.log("fff",this.round)
  }



}
