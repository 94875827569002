import { Component, OnInit, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs-compat';
import { MenuItem } from '~app/models/menu-item';
import { AppState } from '~app/reducers/main';
import { selectCategoryItemsByFocusedId, selectItemsByIDs } from '~app/selectors/menu.selectors';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CorrService } from '~app/services/corr.service';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-correlate-items',
  templateUrl: './correlate-items.component.html',
  styleUrls: ['./correlate-items.component.scss']
})
export class CorrelateItemsComponent implements OnInit {

  correlateditems$: Observable<MenuItem[]>

  corrItems: MenuItem[]

  selectedAtleastOneCorrItem: boolean = false

  constructor(
    public dialogRef: MatDialogRef<CorrelateItemsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
    private corrService: CorrService
    ) {

    // this.correlateditems$=this.store.pipe(select(selectCategoryItemsByFocusedId))

  }

  ngOnInit() {
    this.corrService.getCorrItems(this.data.item.corrID).subscribe(
      next => {
        //We need are going to get only ids of correlated items
        let ids: string[] = []

        for(let n of next){
          ids.push(n.itemID)
        }

        // Now, ids array is ready we need to get correlated items

        this.correlateditems$ = this.store.pipe(select(selectItemsByIDs, {ids: ids}))
      },
      error => {
        console.log("Error in getting correlated items:", error)
      }
    )
  }

  close(){
    this.dialogRef.close({clickedOnNothanks: true})
  }

  closeOK(){
    this.dialogRef.close()
  }

  corSelectionStatus(status: boolean){
    this.selectedAtleastOneCorrItem = status
  }
}
