import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'digitsRoundOff'
})
export class DigitsRoundOffPipe implements PipeTransform {

  transform(value: any, ...args: any[]): number {

    return Math.round(value);
  }

}
