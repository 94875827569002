import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '~app/services/auth.service';
import { Observable } from 'rxjs';
import { Party } from '~app/models/party';
import { PartyService } from '~app/services/party.service';

@Component({
  selector: 'app-joinparty',
  templateUrl: './joinparty.component.html',
  styleUrls: ['./joinparty.component.scss']
})
export class JoinpartyComponent implements OnInit {


  isPartyCreated: boolean = false

  elementType = 'url';

  value: any

  partyName = "party name"

  party = "/assets/images/socialize.png";


  partyObj: Party


  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private partyService: PartyService
  ) {
    let restaurantID = this.authService.getContext().restaurantId
    let userID = this.authService.getContext().guestID
    let cartID = this.authService.getContext().cartID
    let partyID = localStorage.getItem('partyID')

    this.value = JSON.stringify({
      restaurantId: restaurantID,
      userId: userID,
      cartId: cartID,
      partyId: partyID
    });


    let isPartyRunning = JSON.parse(localStorage.getItem("isPartyRunning"))
    
    if(isPartyRunning){
      this.isPartyCreated = true

      this.partyService.getParty(partyID).subscribe(
        next => {
          this.partyObj = next
        },
        error => {
          console.log("error in getting party:", error)
        }
      )
    }

  }


  ngOnInit() {
  }

  goToJoinPartyCreate() {
    this.router.navigate(['../join-party-create'], { relativeTo: this.activatedRoute });
  }

  goToJoinPartyScan() {
    this.router.navigate(['../join-party-scan'], { relativeTo: this.activatedRoute });
  }

}
