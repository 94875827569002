import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MenuSettings } from '~app/models/menu-settings';
import { AuthService } from './auth.service';
import { catchError, map, tap } from 'rxjs/operators';
import { SaveMenuSettingsAction } from '~app/actions/menu-setting.actions';
import { AppState } from '~app/reducers/main';
import { Store } from '@ngrx/store';
import { LoadingIndicatorService } from './loading-indicator.service';
import { LoadingContextMap } from '~app/constants/loading-context.constants';

@Injectable({
    providedIn: 'root'
})

export class MenuSettingsService {
    private menuSettingsUrl = '/api/restaurants/';
    constructor( private http: HttpClient ,
                 private authService:AuthService ,
                 private store: Store<AppState>,
                 private loadingIndicatorService: LoadingIndicatorService,
                 ){}


    initialize(): Observable<any>{
        const restaurantID = this.authService.getContext().restaurantId
        this.loadingIndicatorService.set(LoadingContextMap.LOADING_CATEGORIES)


        return this.http.get(this.menuSettingsUrl + restaurantID + '/menu-settings').pipe(map(m => {return {source: 4, result: m}}))

    }


    load(next: any){
        this.store.dispatch(new SaveMenuSettingsAction(next.categories))
    }

    getMenuSettings(): Observable<any>{
        const restaurantID = this.authService.getContext().restaurantId

        return this.http.get<any>(this.menuSettingsUrl + restaurantID + '/menu-settings')
    }
    
    
}
    