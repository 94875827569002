import { dinerActions, dinerActionTypes } from '~app/actions/diner.actions';

export interface DinerState{
    id: string
    dinerName: string
}

export interface DinerIdentity{
    sessionID: string
    restaurantID: string
    orderID: string
    trackingCode?:string
}

let DinerStateInitialState: DinerState = undefined

let DinerIdentityInitialState: DinerIdentity = {
    sessionID: undefined,
    restaurantID: undefined,
    orderID: undefined,
    trackingCode:undefined
}

export function dinerReducer(state=DinerStateInitialState, action: dinerActions){
    switch(action.type){
        case dinerActionTypes.SAVE_DINER: {
            let data = action.payload
            return {
                id: data.id,
                dinerName: data.name
            }
        }

        default: {
            return state;
        }

    }
}

export function dinerIdentityReducer(state=DinerIdentityInitialState, action: dinerActions){
    switch(action.type){
        case dinerActionTypes.UPDATE_DINER_IDENTITY: {
            let data = action.payload

            return {
                sessionID: data.sessionID,
                restaurantID: data.restaurantID,
                orderID: data.orderID,
                trackingCode:data.trackingCode
            }
        }

        case dinerActionTypes.RESET_DINER_IDENTITY: {
            return undefined
        }

        default : {
            return state
        }
    }
}