import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { item } from '../know-your-product.component';

@Component({
  selector: 'app-know-your-product-upsale-dialog',
  templateUrl: './know-your-product-upsale-dialog.component.html',
  styleUrls: ['./know-your-product-upsale-dialog.component.scss'],
})
export class KnowYourProductUpsaleDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<KnowYourProductUpsaleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public uSaleItems: item[]) { }

    upSaleimageUrl='../assets/images/upsell.png'

  ngOnInit() {

  }

  selectUpSaleItem(addeditem:item){
    this.dialogRef.close({isUpSaleItemSelected: true ,uitem:addeditem})
  }

  closeDialog(){
    this.dialogRef.close({isUpSaleItemSelected: false})
  }


}
