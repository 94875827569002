import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuItem } from '~app/models/menu-item';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {

  @Input() description:string;

  desc:string ="this item is tasty"

  constructor() { }

  ngOnInit() {
  }

}
