import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ProgressBarService {

    public progressBarHidden: boolean;

    constructor() {
        this.progressBarHidden = true;
    }

    public showProgressBar() {
        this.progressBarHidden = false;
    }

    /**
     * value : true means progress bar will be hidden
     * value : false means progress bar will be shown
     * param value  to pass
     */
    public hideProgressBar() {
        this.progressBarHidden = true;
    }
}
