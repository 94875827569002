import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UpiPayment } from '~app/models/upi-details';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UpiPaymentVerifyService {

  upipaymentVerifyUrl = 'api/payments'

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  postUpiPaymentDetails(upipayment: UpiPayment): Observable<any>{
    let paymentID = this.authService.getContext().paymentID
    return this.http.put<any>(this.upipaymentVerifyUrl+'/'+paymentID, upipayment)
  }
}
