import {Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer} from '@angular/core';
import {MenuItem} from '~models/menu-item';
import {Router} from '@angular/router';
import { QuickItemSkeletonService } from '~app/services/quick-item-skeleton.service';
import { AppState } from '~app/reducers/main';
import { select, Store } from '@ngrx/store';
import { SaveMenuItemIdAction } from '~app/actions/menu.actions';
import {orderService,orderData,orderItem,Course} from 'src/app/services/add-order-list.service'
import { AddItemtoCartAction, UpdateItemQuantityAction } from '~app/actions/cart.actions';
import { ShowIndicatorAction, HideIndicatorAction } from '~app/actions/loading-indicator.actions';
import { cartLocationService, position } from '~app/services/cart-location.service';
import { animation, animate, state, style, trigger, transition, AnimationBuilder, AnimationPlayer, query } from '@angular/animations';
import { SaveCategoryIdAction } from '~app/actions/categories.actions';
import { Observable } from 'rxjs';
import { ThemeState } from '~app/reducers/theme.reducer';
import { Restaurant } from '~app/models/restaurant';



@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit{
    

    @Input() item: MenuItem;
    @ViewChild('animationElement',{static:false})
    public animationElement;

    restaurant$:Observable<Restaurant> ;

    cartPosition:position;
    player:AnimationPlayer;

    vegIcon = '../assets/icons/Veg_icon.svg';

    nonVegIcon = '../assets/icons/Nonveg_icon.svg';

    showSkeleton: boolean;
    isDarkTheme$: Observable<ThemeState>;

    constructor(private router: Router, 
        private quickItemSkeleton: QuickItemSkeletonService,
        private store: Store<AppState>,
        private orderService: orderService,
        private cartLocationService:cartLocationService,
        private builder: AnimationBuilder,
        private elementRef:ElementRef) {

            this.restaurant$=this.store.pipe(select('restaurant'))
            
            this.cartLocationService.cartPostion$.subscribe(
                next =>{
                    this.cartPosition = next;
                }
            )
            this.isDarkTheme$ = this.store.pipe(select('theme'))

    }

    ngOnInit() {
        this.showSkeleton = this.quickItemSkeleton.get();
        this.item;
        this.cartLocationService.cartPostion$.subscribe(
            next =>{
                this.cartPosition = next;
            }
        )
        if(!this.cartPosition){
            this.cartPosition = this.cartLocationService.firstPosition;
          }
    }

    openItem() {
        this.store.dispatch(new ShowIndicatorAction())
        this.store.dispatch(new SaveMenuItemIdAction(this.item.id))
        this.store.dispatch(new SaveCategoryIdAction(this.item.categoryId))
        this.store.dispatch(new HideIndicatorAction())
        this.router.navigate(['/restaurant', this.item.restaurantId, 'item', this.item.id]);
        
    }

    addItem(item: MenuItem){
        this.showAnimation();
        this.player.onDone(()=>{
            this.store.dispatch(new AddItemtoCartAction(item));
         })
    }

    changeCount(item: MenuItem, operation: string){
        if( operation === '-'){
            this.store.dispatch(new UpdateItemQuantityAction({item: item, operation: operation}));
        }
        if(operation === '+'){
            this.showAnimation();
            this.player.onDone(()=>{
                this.store.dispatch(new UpdateItemQuantityAction({item: item, operation: operation}));
            });
        }
     
    }


  public showAnimation(): void {
    this.overlay(this.animationElement);

  }


  private overlay(element: ElementRef): void {

    let imgToCopy = this.elementRef.nativeElement.querySelector(`#mat-card`);

    let itemPosition = imgToCopy.getBoundingClientRect();

    console.log("menu",itemPosition);

   let  intialState:any=style({
        height:(itemPosition.height-55).toString()+'px',
        width:itemPosition.width.toString()+'px',
        top:itemPosition.top.toString()+'px',
        left:itemPosition.left.toString()+'px',
        backgroundImage:'url('+this.item.imageUrls[0]+')'
        });
       
    let endState:any=style({ 
            zIndex:-1,
            height:'1px',
            width:'1px',
            top: this.cartPosition.y.toString()+'px',
            left: this.cartPosition.x.toString()+'px',
            backgroundImage:'url('+this.item.imageUrls[0]+')',
           });

    const overlay = [
      query('.animation-square', intialState),
      query('.animation-square',
          animate('200ms linear', endState),
      )
    ];

     this.createAnimation(overlay, element.nativeElement);
     
  }

  private createAnimation(animation, element): void {
   
    const factory = this.builder.build(animation);
    this.player = factory.create(element);
    this.player.play();
   
  }


  viewImage(selectedImageUrl: string){
    let menuImage = document.getElementById('menuImage') as HTMLImageElement;
    menuImage.src = selectedImageUrl;

   }

}
