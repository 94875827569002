import { Component, OnInit } from '@angular/core';
import { AppState } from '~app/reducers/main';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PreloadingService } from '~app/services/preloading.service';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { Restaurant } from '~app/models/restaurant';
import { AuthService } from '~app/services/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-dining-options',
  templateUrl: './dining-options.component.html',
  styleUrls: ['./dining-options.component.scss']
})
export class DiningOptionsComponent implements OnInit {

  restaurant$: Observable<Restaurant>

  trackingID: string

  constructor(
    private store: Store<AppState>,
    private preloadingService: PreloadingService,
    public loadingIndicatorService: LoadingIndicatorService,
    public spinner: NgxSpinnerService,
    public router: Router,
    public route: ActivatedRoute,
    private authService: AuthService,
    private cookies: CookieService
  ) {
    this.restaurant$ = this.store.pipe(select('restaurant'))
   }

  ngOnInit() {
  }

  showOnlyMenu(){
    localStorage.setItem('onlyMenu', "true")
    this.spinner.show()
    this.preloadingService.initializeForOnlyMenu()
  }

  activateTableTopOrdering(){
    this.authService.removeDetails()
    this.cookies.delete('access_token')
    this.router.navigate(['/auth-token'])
  }

}
