import {Component, OnInit} from '@angular/core';
import {faHashtag, faQrcode, faSearch} from '@fortawesome/free-solid-svg-icons';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    faQrcode = faQrcode;
    faHashtag = faHashtag;
    faSearch = faSearch;

    selectedOutlet: string;
    private routePaths: string[];

    constructor(public router: Router) {

        router.events.subscribe(event => {

            if (event instanceof NavigationEnd) {
                this.routePaths = event.url.split('/');
                this.selectedOutlet = this.routePaths[this.routePaths.length - 1];
            }
        });
    }

    ngOnInit() {

    }
}
