import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '~app/reducers/main';
import { Store } from '@ngrx/store';
import { CompleteDiningAction } from '~app/actions/diner.actions';

@Component({
  selector: 'app-complete-dining',
  templateUrl: './complete-dining.component.html',
  styleUrls: ['./complete-dining.component.scss']
})
export class CompleteDiningComponent implements OnInit {

  constructor(
    private router: Router,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
  }

  CompleteDining(){

    //This will remove all the localStorage
    localStorage.clear()

    // This will remove ngrx stored data
    this.store.dispatch(new CompleteDiningAction())
    
    this.router.navigate(['/'])



  }

}
