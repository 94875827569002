import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';
import { AuthService } from '~app/services/auth.service';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { Store } from '@ngrx/store';
import { AppState } from '~app/reducers/main';
import { UpdateDinerIdentityAction, CompleteDiningAction } from '~app/actions/diner.actions';
import { NotificationsService } from '~app/services/notifications.service';
import { SnackBarMessageMap } from '~app/constants/snackbar-message.constants';
import { CameraAccessDialogComponent } from '~app/components/camera-access-dialog/camera-access-dialog.component';
import { SaveRestaurantAction } from '~app/actions/restaurant.actions';
import { AuthContext } from '~app/models/auth-context';
import { noUndefined } from '@angular/compiler/src/util';
import { TimeStampValue } from '~app/utils/common-functions';
import { GeoLocationAccessComponent } from '~app/components/geo-location-access/geo-location-access.component';
import { PreloadingService } from '~app/services/preloading.service';
import { UpdateBasket } from '~app/actions/basket.actions';

@Component({
    selector: 'app-carousal',
    templateUrl: './carousal.component.html',
    styleUrls: ['./carousal.component.scss']
})
export class CarousalComponent implements OnInit {

    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    imageLabel: string;

    openedToScan: boolean = false

    constructor(
        private router: Router,
        public authService:AuthService,
        public loadingIndicatorService: LoadingIndicatorService,
        public dialog: MatDialog,
        public spinner: NgxSpinnerService,
        public store: Store<AppState>,
        public notificationService: NotificationsService,
        private preloadingService: PreloadingService
        ) {
    }

    ngOnInit() {

        this.galleryOptions = [
            {
                width: '100%',
                height: '55vh',
                imageAnimation: NgxGalleryAnimation.Fade,
                imageAutoPlay: true,
                imageSwipe: true,
                preview: false,
                thumbnails: false,
                imageArrows: false,
                imageBullets: true
            }
        ];

        // this.galleryImages = [
        //     {
        //         small: 'https://res.cloudinary.com/demo/image/upload/docs/pizza_wine.jpg',
        //         medium: 'https://res.cloudinary.com/demo/image/upload/docs/pizza_wine.jpg',
        //         big: 'https://res.cloudinary.com/demo/image/upload/docs/pizza_wine.jpg',
        //         label: 'Happy dinners'
        //     },
        //     {
        //         small: 'https://res.cloudinary.com/demo/image/upload/w_500,ar_1,c_crop,g_auto:wine_glass/docs/pizza_wine.jpg',
        //         medium: 'https://res.cloudinary.com/demo/image/upload/w_500,ar_1,c_crop,g_auto:wine_glass/docs/pizza_wine.jpg',
        //         big: 'https://res.cloudinary.com/demo/image/upload/w_500,ar_1,c_crop,g_auto:wine_glass/docs/pizza_wine.jpg',
        //         label: 'Made with love'
        //     },
        //     {
        //         small: 'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/SouthIndian_a5ivgy',
        //         medium: 'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/SouthIndian_a5ivgy',
        //         big: 'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/SouthIndian_a5ivgy',
        //         label: "It's digital experience"
        //     },
        //     // {
        //     //     small: 'https://res.cloudinary.com/demo/image/upload/w_500,ar_1,c_crop,g_auto:wine_glass/docs/pizza_wine.jpg',
        //     //     medium: 'https://res.cloudinary.com/demo/image/upload/w_500,ar_1,c_crop,g_auto:wine_glass/docs/pizza_wine.jpg',
        //     //     big: 'https://res.cloudinary.com/demo/image/upload/w_500,ar_1,c_crop,g_auto:wine_glass/docs/pizza_wine.jpg',
        //     //     label: 'Hello world'
        //     // },
        //     // {
        //     //     small: 'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/SouthIndian_a5ivgy',
        //     //     medium: 'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/SouthIndian_a5ivgy',
        //     //     big: 'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/SouthIndian_a5ivgy',
        //     //     label: 'See this'
        //     // }
        // ];


        this.galleryImages = [
            {
                small: '/assets/images/happyDinners.jpg',
                medium: '/assets/images/happyDinners.jpg',
                big: '/assets/images/happyDinners.jpg',
                label: 'Happy dinners'
            },

            // {
            //     small: '/assets/images/madeWithLove.jpg',
            //     medium: '/assets/images/madeWithLove.jpg',
            //     big: '/assets/images/madeWithLove.jpg',
            //     label: 'Made with love'
            // },
            // {
            //     small: '/assets/images/kittyDigitalExperience.jpg',
            //     medium: '/assets/images/kittyDigitalExperience.jpg',
            //     big: '/assets/images/kittyDigitalExperience.jpg',
            //     label: "Kittychef's digital experience"
            // },
            // {
            //     small: 'https://res.cloudinary.com/demo/image/upload/w_500,ar_1,c_crop,g_auto:wine_glass/docs/pizza_wine.jpg',
            //     medium: 'https://res.cloudinary.com/demo/image/upload/w_500,ar_1,c_crop,g_auto:wine_glass/docs/pizza_wine.jpg',
            //     big: 'https://res.cloudinary.com/demo/image/upload/w_500,ar_1,c_crop,g_auto:wine_glass/docs/pizza_wine.jpg',
            //     label: 'Hello world'
            // },
            // {
            //     small: 'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/SouthIndian_a5ivgy',
            //     medium: 'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/SouthIndian_a5ivgy',
            //     big: 'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/SouthIndian_a5ivgy',
            //     label: 'See this'
            // }
        ];




    }

    imageChangedEvent(image: NgxGalleryImage) {
        this.imageLabel = image.label;
    }

    openScanner(){
        // this.router.navigate(['scanner'])
        this.openedToScan = true
    }

    public scanSuccessHandler(code: string) {

        // Clear out any previous session data (ngrx, localStorage)
        localStorage.clear()
        this.store.dispatch(new CompleteDiningAction())

        let constraints={video:true};
        navigator.mediaDevices.getUserMedia(constraints)
        .then((stream)=>{console.log("camera access allowed")})
        .catch((err)=>
         {if(err.name=="NotAllowedError"){this.openDialog()}
        });       

        //TODO: Now, Code is going to be url, and we need to extract the trackingID value and secret value
        const url = new URL(code)

        const trackingID = url.searchParams.get('trackingID');
        const secret = url.searchParams.get('secret')

        let newCode = undefined

        if(trackingID!=''){
            newCode = trackingID
        }

        if(secret!=''){
            newCode = newCode+':'+secret
        }

        this.VendorQRScanHandler(newCode)
       
    }

    openDialog() {
        this.dialog.open(CameraAccessDialogComponent, {
          data: {
          }
        });
      }


    openHomeDelivery(){

        // Clear out any previous session data (ngrx, localStorage)
        localStorage.clear()
        this.store.dispatch(new CompleteDiningAction())

        this.loadingIndicatorService.set(LoadingContextMap.LOADING)
        this.spinner.show()

        navigator.geolocation.getCurrentPosition((position) => { 
            let lon = position.coords.longitude; 

            let lat = position.coords.latitude;
            
            //Route to a page where we display near by restaurants

            // this.router.navigate(['/nearby-restaurants'], { queryParams: { longitude: lon, latitude: lat} })

            this.router.navigate(['/nearby-restaurants'])


            //store longitude and latitude to localStorage
            localStorage.setItem("longitude", String(lon))
            localStorage.setItem("latitude", String(lat))


            this.spinner.hide()

            //TODO: On clicking on the restaurant, do the same thing as when we scan the parcel qr code
            });
        

       
    }
    


    VendorQRScanHandler(code){

        // Clear out the old session
        this.store.dispatch(new CompleteDiningAction())
        
        this.loadingIndicatorService.set(LoadingContextMap.QR_SCAN)
        this.spinner.show()
    
    
        this.authService.authenticateDiner(code).subscribe(
            next => {
                this.loadingIndicatorService.set(LoadingContextMap.QR_SCAN_SUCCESS)
    
                this.store.dispatch(new UpdateDinerIdentityAction({ sessionID: next.sessionId, restaurantID: next.restaurantId, orderID: next.orderId ,trackingCode:next.trackingCode, cartID: next.cartID}))
    
                //Dispatch an action to initialize cart, (i.e empty cart)
                this.store.dispatch(new UpdateBasket([]))
    
                this.router.navigate(['session'])
    
                this.spinner.hide()
            },
            error => {
                this.spinner.hide()
                this.notificationService.showMessage(SnackBarMessageMap.WRONG_QR_SCAN)
            }
        )
    
        this.spinner.hide()
    }
}
