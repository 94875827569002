import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CartItem, CartPort } from '~app/models/cart';
import { AuthContext } from '~app/models/auth-context';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cartUrl = 'api/cart'

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }


  getCart(): Observable<CartItem[]>{

    let cartID = this.authService.getContext().cartID

    return this.http.get<CartItem[]>(this.cartUrl+'/'+cartID)
  }

  updateCart(items: CartItem[]): Observable<any>{
    let cartID = this.authService.getContext().cartID

    let restaurantID
    let guestID

    let auth = localStorage.getItem("auth")

    if(auth){
      let context: AuthContext = JSON.parse(localStorage.getItem('auth'));
      restaurantID = context.restaurantId
      guestID = context.guestID
    }

    let c = {
      guestID: guestID,
      restaurantID: restaurantID,
      items: items
    }

    return this.http.put<any>(this.cartUrl+'/'+cartID, c)
  }


  cartport(cp: CartPort): Observable<any>{
    return this.http.put<any>(this.cartUrl, cp)
  }
}
