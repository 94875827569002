import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Store } from '@ngrx/store';
import { AppState } from '~app/reducers/main';
import { CompleteDiningAction } from '~app/actions/diner.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor{
    constructor(
        private router: Router,
        private spinner: NgxSpinnerService,
        private store: Store<AppState>
        ){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

            // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
            return next.handle(req).pipe(catchError(x=> this.handleAuthError(x))); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {


        //handle your auth error or rethrow
        if (err.status === 401 || err.status === 403) {
            
            localStorage.clear()
            this.store.dispatch(new CompleteDiningAction())

            this.router.navigateByUrl(`/`);
            this.spinner.hide();
            // if you've caught / h`andled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return of(err.message); // or EMPTY may be appropriate here
        }
        return throwError(err);
    }

}