import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from '../menu/upscale-item/upscale-item.component';

@Component({
  selector: 'app-rounding-off',
  templateUrl: './rounding-off.component.html',
  styleUrls: ['./rounding-off.component.scss']
})
export class RoundingOffComponent implements OnInit {

  noSearchResultsImage = '../assets/images/noSearchResults.png'

  constructor(
    public dialogRef: MatDialogRef<RoundingOffComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close()
  }

}
