import { EntityState, Dictionary, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { PaymentMode } from '~app/models/payment-modes';
import { paymentModeActions, PaymentModeActionTypes } from '~app/actions/payment-modes.actions';

export class PaymentModesState implements EntityState<PaymentMode>{
    ids: string[] | number[];
    entities: Dictionary<PaymentMode>;
}


export const adapter: EntityAdapter<PaymentMode> = createEntityAdapter<PaymentMode>();

let initialState: PaymentModesState = adapter.getInitialState();


export function paymentModeReducer(state=initialState, action: paymentModeActions){

    switch(action.type){
        case PaymentModeActionTypes.SAVE_PAYMENT_MODES: {
            
            return adapter.addMany(action.payload, state)
        }
        
        default : {
            return state
        }
    }

}

// get the selectors
const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = adapter.getSelectors();


  // select the array of user ids
  export const selectPaymentModeIds = selectIds;
   
  // select the dictionary of user entities
  export const selectPaymentModeEntities = selectEntities;
   
  // select the array of users
  export const selectAllPaymentModes = selectAll;
   
  // select the total user count
  export const selectPaymentModeTotal = selectTotal