import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '~app/reducers/main';
import { LoadingIndicatorService } from './loading-indicator.service';
import { Observable } from 'rxjs';
import { MenuItem } from '~app/models/menu-item';
import { map } from 'rxjs/operators';
import { SaveMenuAction, SaveReloadMenuAction } from '~app/actions/menu.actions';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { SaveCategoryIdAction } from '~app/actions/categories.actions';
import { selectCategoriesOrder } from '~app/selectors/menu-settings.selectors';
import { CategoryIndex, MenuSettings } from '~app/models/menu-settings';


@Injectable({
  providedIn: 'root'
})
export class MenuItemService {

  private menuItemsUrl = 'api/items'

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private store: Store<AppState>,
    private loadingIndicatorService: LoadingIndicatorService
  ) { }

  initialize(): Observable<any>{
    const restaurantID = this.authService.getContext().restaurantId
    this.loadingIndicatorService.set(LoadingContextMap.LOADING_MENU_ITEMS)
    return this.http.get(this.menuItemsUrl + '?restaurantId=' + restaurantID).pipe(map(m => {return {source: 3, result: m}}))
  }

  load(next: any){
    let menu:MenuItem[]=next.filter(e=>e.disabled=== false)
    this.store.dispatch(new SaveMenuAction(menu))

    //TODO: Dispatch first category id; What categoryID to dispatch -> moved to preloading

      // let offerItems = menu.filter(e => e.offer>0 && !e.disabled)
      // let specialItems=menu.filter(e => (e.recommended || e.mustTry ) && !e.disabled)
  
      // if(offerItems.length>0){
      //   this.store.dispatch(new SaveCategoryIdAction('77e6838a-7571-4677-97f2-57b49d507b37'))
      // }else{
      //   if(specialItems.length>0){
      //     this.store.dispatch(new SaveCategoryIdAction('4ec2b543-b14f-4ccd-a951-10f0b780f25f'))
      //   }
      //   else{
      //     this.store.dispatch(new SaveCategoryIdAction(menu[0].categoryId))
      //   }
        
      // }


  }

  reload(next: any){
    this.store.dispatch(new SaveReloadMenuAction(next))
  }
}