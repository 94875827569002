import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from '~app/models/menu-item';

@Component({
  selector: 'app-spl-menu-items',
  templateUrl: './spl-menu-items.component.html',
  styleUrls: ['./spl-menu-items.component.scss']
})
export class SplMenuItemsComponent implements OnInit {

  @Input() items: MenuItem[];

    @Input()
    isSpecial: boolean;

    constructor() {
    }

    ngOnInit() {
    }

}
