export const LoadingContextMap = {
    QR_SCAN: 'Verifying QR Scan...',
    QR_SCAN_SUCCESS: 'QR Scan Success. ✅',
    UPDATING_GUEST: 'Updating guest details...🍕',
    LOADING_RESTAURANT: 'Loading restaurant details...🍔',
    LOADING_CATEGORIES: 'Loading Categories...🥯',
    LOADING_MENU_ITEMS: 'Loading Menu items...🥮',
    LOADING_INGREDIENTS: 'Loading Ingredients...🍩',
    LOADING_SERVICEABLES: 'Loading Serviceables...',

    UPDATING: 'Updating...',
    LOADING: 'Loading...',

    SUCCESS: "Success ✅",

    FAILURE: "Failure ❌",

    CREATING_SESION: 'Creating Session...',

    LOADING_CATEGORY_SELECTION: 'Loading Category Selection...' ,


    CREATING: "Creating...",

    REFRESHING: "Refreshing..."


}