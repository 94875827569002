import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CartActionTypes, AddItemtoCartAction, AddtoCartAction, UpdateItemQuantityAction, UpdateQuantityAction } from '~app/actions/cart.actions';
import { map, flatMap, mergeMap } from 'rxjs/operators';
import { UpdateMenuItemQuantityAction } from '~app/actions/menu.actions';

@Injectable()
export class CartEffects {

    constructor(private actions$: Actions){}

    @Effect()
    AddItemtoCartAction$ = this.actions$.pipe(
        ofType<AddItemtoCartAction>(CartActionTypes.ADD_ITEM_TO_CART),
        map(action => action.payload),
        mergeMap((payload) => [
            // AddtoCart action payload.id
            new AddtoCartAction(payload),

            // menu quantity update action, payload.id, quantity new value
            new UpdateMenuItemQuantityAction({item: payload, operation: '+'})
        ])
    )

    @Effect()
    UpdateItemQuantityAction$ = this.actions$.pipe(
        ofType<UpdateItemQuantityAction>(CartActionTypes.UPDATE_ITEM_QUANITY),
        map(action => action.payload), // payload.item, payload.operation
        mergeMap((payload) => [
            new UpdateQuantityAction(payload),
            new UpdateMenuItemQuantityAction({item: payload.item, operation: payload.operation})
        ])

    )
}
