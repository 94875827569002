import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SurveyService} from '~services/survey.service';
import {Survey} from '~models/survey';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {SurveyResponseService} from '~app/services/survey-response.service';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-kitty-opinions',
    templateUrl: './kitty-opinions.component.html',
    styleUrls: ['./kitty-opinions.component.scss'],
})
export class KittyOpinionsComponent implements OnInit {
    private static readonly KittyOpinionsSurveyUrn = 'urn:rupant:kittychef:surveys:kitty-rating-v1';

    // questionnire length
    questionnaireLength: number;

    // answer emitted from the children (radio, text, slider)
    radioButtonAnswer: string;

    textAreaAnswer: string;

    sliderAnswer: string;

    // map{question.id : answer.id}
    questionAnswerMap = new Map();

    selected = new FormControl(0);

    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    survey: Survey;

    @Input()
    selectedIndex: number;
    surveyResponseService: SurveyResponseService;

    showSkeleton: boolean;


    nextStep(selectedValue: number) {
        if (selectedValue == undefined)
            selectedValue = 0;
        this.selected.setValue(selectedValue + 1);
    }

    prevStep(selectedValue) {
        this.selected.setValue(selectedValue - 1);

    }

    // this is for boy helping old women
    height: string = '80px';
    width: string = '60px';
    marginLeft: number = 0;

    styleObject(): Object {
        return {height: this.height, width: this.width}
    }

    boyMotion(): Object {
        return {marginLeft: this.marginLeft.toString() + 'px'}
    }


    constructor(
        private _formBuilder: FormBuilder,
        private surveyService: SurveyService,
        private activateRoute: ActivatedRoute,
        public router: Router,
        private location: Location,
        private http: HttpClient) {

    }

    ngOnInit() {
        this.firstFormGroup = this._formBuilder.group({
            firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
            secondCtrl: ['', Validators.required]
        });

        // TODO: may be move it up in the food chain and load it before the survey component is loaded?
        this.surveyService.byUrn(KittyOpinionsComponent.KittyOpinionsSurveyUrn).subscribe(
            survey => {
                this.showSkeleton = true;
                this.survey = survey;
                this.questionnaireLength = survey.questionnaire.sections.length - 1;
                this.showSkeleton = false;
                this.surveyResponseService = new SurveyResponseService(this.survey.id, this.http);

            }
        );

    }

    navigateToQuickMenu() {
        this.location.back();
    }

    /*
      Fix closure:
      {'question': 'Thanks for helping others to see better menu.', 'hint': '', 'imageUrl': '/assets/icons/thankyou.png'},
     */

    submitSurvey() {
        this.router.navigate(['../thankyou'],{relativeTo:this.activateRoute});
        this.surveyResponseService.submitResponse(null).subscribe(
            next => {
                console.log('Submitted');
                this.router.navigate(['../thankyou'],{relativeTo:this.activateRoute});
            },
            error => {
                console.log('error occured while submitting', error);
            }
        );
    }

    navigateToNext(){
        
    }
}
