import { Action } from '@ngrx/store';


export enum RoundOffActionTypes {
    ADD_ROUND_OFF_ITEMS = '[RoundOff Component] add round off items',
    UPDATE_ROUND_OFF_ITEMS = '[RoundOff Component] Update Round off items'
}

export class AddRoundOffIemsAction implements Action{
    readonly type = RoundOffActionTypes.ADD_ROUND_OFF_ITEMS

    constructor(public payload: any){}
}

export class UpdateRoundOffItemsAction implements Action{
    readonly type = RoundOffActionTypes.UPDATE_ROUND_OFF_ITEMS

    constructor(public payload: any){}
}

export type RoundOffActions = AddRoundOffIemsAction | UpdateRoundOffItemsAction
