import {Survey} from '~models/survey';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SurveyService {
    private surveysUrl = 'api/surveys';

    constructor(private http: HttpClient) {
    }

    byUrn(forUrn: string): Observable<Survey> {
        // TODO: handle invalid urn
        return this.http.get<Survey[]>(this.surveysUrl + '?surveyFor=' + forUrn).pipe(
            map(r => r[0]),
            catchError(error => {
                return throwError('Something went wrong!');
            })
        );
    }
}
