import * as fromRoundOff from '../reducers/round-off.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRestaurant } from './restaurant.selectors';
import { MenuItem } from '~app/models/menu-item';
import { selectBusinessItems } from './menu.selectors';

export interface State{
    roundOff: fromRoundOff.RoundOffState
  }
  
  export const selectRoundOffState = createFeatureSelector<fromRoundOff.RoundOffState>('roundOff')

  export const selectRoundOffIds = createSelector(
    selectRoundOffState,
    (roundOffState) => fromRoundOff.selectRoundOffIds(roundOffState)
  )

  export const selectRoundOffEntities = createSelector(
    selectRoundOffState,
    (roundOffState) => fromRoundOff.selectRoundOffEntities(roundOffState)
  )

  export const selectRoundOffItems = createSelector(
    selectRestaurant,
    selectBusinessItems,
    selectRoundOffEntities,
    (restaurant, menu, roEntities) => {

      let roID = restaurant.roundOffID

      let result: MenuItem[] = []

      if(roID){
        let itemIDs = roEntities[roID].itemIDs

        for(let item of itemIDs){
          for(let m of menu){
            if(m.id === item.itemID){
              result.push(m)
            }
          }
        }

      }

      return result
    }
  )