import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService {

  private dataSource = new BehaviorSubject<string>("Loading...");
  
  constructor() { }

  set(context: string){
    this.dataSource.next(context)
  }

  get():Observable<string>{
    return this.dataSource.asObservable()
  }
}
