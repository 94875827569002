import {Answerable} from '~models/answerable';

export class Question {

    public static readonly QUESTION_TYPE_SINGLE_SELECTION = 'SINGLE_SELECTION';
    public static readonly QUESTION_TYPE_MULTI_SELECTION = 'MULTIPLE_SELECTION';
    public static readonly QUESTION_TYPE_RANGE = 'RANGE';
    public static readonly QUESTION_TYPE_TEXT_BOX = 'TEXT_BOX'; // 'TEXT_BOX';

    id: string;
    type: string;
    text: string;
    hint: string;
    backgroundImageId: string;
    backgroundImageUrl: string;
    answerable: Answerable;

    public isRadio(): boolean {
        return this.type === Question.QUESTION_TYPE_SINGLE_SELECTION;
    }

    public isSlider(): boolean {
        return this.type === Question.QUESTION_TYPE_RANGE;
    }

    public isTextBox(): boolean {
        return this.type === Question.QUESTION_TYPE_TEXT_BOX;
    }

    public isCheckBox(): boolean {
        return this.type === Question.QUESTION_TYPE_MULTI_SELECTION;
    }
}
