import {Component} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {ProgressBarService} from '~services/progress-bar.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-kitty-header',
    templateUrl: './kitty-header.component.html',
    styleUrls: ['./kitty-header.component.scss']
})
export class KittyHeaderComponent {

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    constructor(public breakpointObserver: BreakpointObserver,
                public progressBarService: ProgressBarService,
                public router: Router) {
    }

}
