import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CarousalComponent } from '~components/kitty-home/carousal/carousal.component';
import { ScannerComponent } from '~components/kitty-home/scanner/scanner.component';
import { NgQrScannerModule } from 'ang-qrscanner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CodeSearchComponent } from '~components/kitty-home/code-search/code-search.component';
import { LocationSearchComponent } from '~components/kitty-home/location-search/location-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { HomeComponent } from '~components/kitty-home/home/home.component';
import { RestaurantHeaderComponent } from '~components/restaurant-header/restaurant-header.component';
import { HttpClientModule } from '@angular/common/http';
import { RatingsPipe } from './pipes/ratings.pipe';
import { KittyHeaderComponent } from '~components/kitty-header/kitty-header.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';
import { OverlayModule } from '@angular/cdk/overlay';
import { DetailedMenuComponent } from '~components/menu/detailed-menu/detailed-menu.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { NgxGalleryModule } from 'ngx-gallery';
import { FabButtonComponent } from '~components/common/fab-button/fab-button.component';
import { TagsComponent } from '~components/common/tags/tags.component';
import { MatChipsModule } from '@angular/material/chips';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { QuestionsComponent } from './components/questions/questions.component';
import { TextBoxComponent } from './components/questions/text-box/text-box.component';
import { RadioButtonComponent } from './components/questions/radio-button/radio-button.component';
import { SliderComponent } from './components/questions/slider/slider.component';
import { BusinessHeaderFooterComponent } from '~components/business-header-footer/business-header-footer/business-header-footer.component';
import { BusinessHeaderComponent } from '~components/business-header-footer/business-header/business-header.component';
import { BusinessFooterComponent } from '~components/business-header-footer/business-footer/business-footer.component';
import { QuickMenuComponent } from '~components/menu/quick-menu/quick-menu.component';
import { CategoriesComponent } from '~components/menu/categories/categories.component';
import { MenuItemsComponent } from '~components/menu/items/menu-items/menu-items.component';
import { MenuItemComponent } from '~components/menu/items/menu-item/menu-item.component';
import { KittyOpinionsComponent } from '~components/kitty-opinions/kitty-opinions.component';
import { MatRadioModule } from '@angular/material/radio';
import { RestaurantFeedbackComponent } from '~components/restaurant-feedback/restaurant-feedback.component';
import { NotificationsComponent } from '~components/common/notifications/notifications.component';
import { MatRippleModule } from '@angular/material/core';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { ThankyouKittyComponent } from './components/thankyou/thankyou-kitty/thankyou-kitty.component';
import { ThankyouBusinessComponent } from './components/thankyou/thankyou-business/thankyou-business.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConnectionLostInterceptor } from './interceptors/connection-lost.interceptor';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ErrorsHandler } from './errors-handler';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProgressSpinnerModule } from '~app/progress-spinner/progress-spinner.module';
import { SpecialsComponent } from './components/specials/specials.component';
import { SplQuickMenuComponent } from './components/specials/spl-quick-menu/spl-quick-menu.component';
import { SplMenuItemsComponent } from './components/specials/spl-menu-items/spl-menu-items.component';
import { SplMenuItemComponent } from './components/specials/spl-menu-item/spl-menu-item.component';
import { SplDetailedMenuComponent } from './components/specials/spl-detailed-menu/spl-detailed-menu.component';
import { SplIngredientsComponent } from './components/specials/spl-ingredients/spl-ingredients.component';
import { SplHeaderComponent } from './components/specials/spl-header/spl-header.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { JsonApiModule } from 'angular2-jsonapi';
import { OnboardComponent } from './components/kitty-home/onboard/onboard.component';
import { OnboardStatusComponent } from './components/kitty-home/onboard-status/onboard-status.component';
import { RestaurantHomeComponent } from './components/restaurant-home/restaurant-home.component';
import { ServiceableComponent } from './components/serviceable/serviceable.component';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { restaurantReducer } from './reducers/restaurant.reducer';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storageSyncMetaReducer } from 'ngrx-store-persist';
import { themeReducer } from './reducers/theme.reducer';
import { dinerReducer, dinerIdentityReducer } from './reducers/diner.reducer';
import { ingredientsReducer } from './reducers/ingredients.reducer';
import { EffectsModule } from '@ngrx/effects';
import { IngredientsEffects } from './effects/ingredients.effects';
import { CategoriesEffects } from './effects/categories.effects';
import { categoriesReducer, categoryIdReducer } from './reducers/categories.reducer';
import { menuReducer, menuIdReducer } from './reducers/menu.reducer';
import { MenuEffects } from './effects/menu.effects';
import { OrderSummaryComponent } from './components/order-summary/order-summary.component';
import { OrderCourseComponent } from './components/order-summary/order-course/order-course.component';
import { OrderCoursesComponent } from './components/order-summary/order-courses/order-courses.component';
import { sidenavreducer } from './reducers/sidenavtoggle.reducer';
// import { PaymentscreenComponent } from './components/paymentscreen/paymentscreen.component';
import { MatTableModule } from '@angular/material/table';
import { cartReducer } from './reducers/cart.reducer';
import { CartSummaryComponent } from './components/cart-summary/cart-summary.component';
import { CurrentCourseComponent } from './components/cart-summary/current-course/current-course.component';
import { CartEffects } from './effects/cart.effects';
import { orderReducer } from './reducers/order.reducer';
import { OrderEffects } from './effects/order.effects';
import { DetailedMenuItemComponent } from './components/menu/detailed-menu/detailed-menu-item/detailed-menu-item.component';
import { DescriptionComponent } from './components/menu/detailed-menu/description/description.component';
import { IngredientsComponent } from './components/menu/detailed-menu/ingredients/ingredients.component';
import { NutritionCardComponent } from './components/menu/detailed-menu/nutrition-card/nutrition-card.component';
import { SimilarItemsComponent } from './components/menu/detailed-menu/similar-items/similar-items.component';
import { loadingIndicatorReducer } from './reducers/loading-indicator.reducer';
import { paymentReducer } from './reducers/payment.reducer';
import { ReportProblemPageComponent } from './components/report-problem-page/report-problem-page.component';
import { PaymentInitiateScreenComponent } from './components/payment-initiate/paymentscreen.component';
import { PaymentFinalComponent } from './components/payment-final/payment-final.component';
import { roundReducer } from './reducers/round.reducer';
import { PaymentEffects } from './effects/payment.effects';
import { serviceItemsReducer } from './reducers/service-items.reducer';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { WebSocketConnectionStatusReducer, WebsocketLogStateReducer } from './reducers/websocket-log.reducer';
import { WebsocketEffects } from './effects/websocket.effects';
import { PaymentSuccessDialogComponent } from './components/payment-initiate/payment-success-dialog/payment-success-dialog.component';
import { httpInterceptorProviders } from './interceptors';
import { CompleteDiningComponent } from './components/complete-dining/complete-dining.component';
import { clearState } from './reducers/main';
import { CameraAccessDialogComponent } from './components/camera-access-dialog/camera-access-dialog.component';
import { ScanComponent } from './components/scan/scan.component';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { PaymentFailureComponent } from './components/payment-failure/payment-failure.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { SelectCategoryDialogComponent } from './components/menu/select-category-dialog/select-category-dialog.component';
import { MenuItemDetailedComponent } from './components/menu/items/menu-item-detailed/menu-item-detailed.component';
import { DiningOptionsComponent } from './components/dining-options/dining-options.component';
import { GeoLocationAccessComponent } from './components/geo-location-access/geo-location-access.component';
import { NearByRestaurantsComponent } from './components/near-by-restaurants/near-by-restaurants.component';
import { DeliveryDetailsComponent } from './components/delivery-details/delivery-details.component';
import { AuthTokenComponent } from './components/auth-token/auth-token.component';
import { DigitsRoundOffPipe } from './pipes/digits-round-off.pipe';
import { OrderConfirmationComponent } from './components/order-confirmation/order-confirmation.component';
import { CookieService } from 'ngx-cookie-service';
import { menuSettingsReducer } from './reducers/menu-settings.reducer';
import { MenuSettingEffects } from './effects/menu-setting.effects.';
import { JoinpartyComponent } from './components/joinparty/joinparty.component';
import { JoinpartyCreateComponent } from './components/joinparty/joinparty-create/joinparty-create.component';
import { JoinpartyScanComponent } from './components/joinparty/joinparty-scan/joinparty-scan.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { basketReducer } from './reducers/basket.reducer';
import { CartEmptyDialogComponent } from './components/cart-empty-dialog/cart-empty-dialog.component';
import { TimeLeftPipe } from './pipes/time-left.pipe';
import { CorrelateItemsComponent } from './components/menu/correlate-items/correlate-items.component';
import { CorrelateItemComponent } from './components/menu/correlate-items/correlate-item/correlate-item.component';
import { UpscaleItemComponent } from './components/menu/upscale-item/upscale-item.component';
import { RoundingOffComponent } from './components/rounding-off/rounding-off.component';
import { roundOffReducer } from './reducers/round-off.reducer';
import { RoundOffItemsComponent } from './components/round-off-items/round-off-items.component';
import { RoundOffItemComponent } from './components/round-off-items/round-off-item/round-off-item.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { PaymentModesComponent } from './components/payment-modes/payment-modes.component';
import { paymentModeReducer } from './reducers/payment-modes.reducer';
import { UpiPaymentVerifyComponent } from './components/upi-payment-verify/upi-payment-verify.component';
import { MatSelectModule } from '@angular/material/select';
import { NgOtpInputModule } from 'ng-otp-input';
import { UpiPaymentDetailsDialogComponent } from './components/upi-payment-details-dialog/upi-payment-details-dialog.component';
import { PrivacyPolicyKittyposComponent } from './components/privacy-policy-kittypos/privacy-policy-kittypos.component';
import { UPIReducer } from './reducers/upi.reducer';
import { JokesComponent } from './components/jokes/jokes.component';
import { jokereducer } from './reducers/joke.reducer';
import { ClipboardModule, ClipboardService } from 'ngx-clipboard';
import { KnowYourProductComponent } from './components/know-your-product/know-your-product.component';

import { KnowYourProductCrossDialogComponent } from './components/know-your-product/know-your-product-cross-dialog/know-your-product-cross-dialog.component';
import { KnowYourProductUpsaleDialogComponent } from './components/know-your-product/know-your-product-upsale-dialog/know-your-product-upsale-dialog.component';
import { KnowYourItemComponent } from './components/know-your-product/know-your-item/know-your-item.component';
import { BusinessPageComponent } from './components/business-page/business-page.component';
import { CartSnackbarComponent } from './components/cart-snackbar/cart-snackbar.component';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { SpecialOfferCardComponent } from './components/business-page/special-offer-card/special-offer-card.component';
import { SpecialItemCardComponent } from './components/business-page/special-item-card/special-item-card.component';

@NgModule({
    declarations: [
        AppComponent,
        CarousalComponent,
        ScannerComponent,
        CodeSearchComponent,
        LocationSearchComponent,
        HomeComponent,
        RestaurantHeaderComponent,
        RatingsPipe,
        KittyHeaderComponent,
        DetailedMenuComponent,
        FabButtonComponent,
        TagsComponent,
        IngredientsComponent,
        NutritionCardComponent,
        BusinessHeaderFooterComponent,
        BusinessHeaderComponent,
        BusinessFooterComponent,
        QuickMenuComponent,
        CategoriesComponent,
        MenuItemsComponent,
        MenuItemComponent,
        KittyOpinionsComponent,
        RestaurantFeedbackComponent,
        NotificationsComponent,
        QuestionsComponent,
        TextBoxComponent,
        RadioButtonComponent,
        SliderComponent,
        ThankyouKittyComponent,
        ThankyouBusinessComponent,
        ThankyouComponent,
        SimilarItemsComponent,
        SpecialsComponent,
        SplQuickMenuComponent,
        SplMenuItemsComponent,
        SplMenuItemComponent,
        SplDetailedMenuComponent,
        SplIngredientsComponent,
        SplHeaderComponent,
        OnboardComponent,
        OnboardStatusComponent,
        RestaurantHomeComponent,
        ServiceableComponent,
        OrderSummaryComponent,
        OrderCourseComponent,
        OrderCoursesComponent,
        CurrentCourseComponent,
        PaymentInitiateScreenComponent,
        CartSummaryComponent,
        SimilarItemsComponent,
        DetailedMenuItemComponent,
        DescriptionComponent,
        ReportProblemPageComponent,
        PaymentFinalComponent,
        QrCodeComponent,
        PaymentSuccessDialogComponent,
        CompleteDiningComponent,
        CameraAccessDialogComponent,
        ScanComponent,
        PaymentStatusComponent,
        PaymentSuccessComponent,
        PaymentFailureComponent,
        PrivacyPolicyComponent,
        SelectCategoryDialogComponent,
        MenuItemDetailedComponent,
        DiningOptionsComponent,
        GeoLocationAccessComponent,
        NearByRestaurantsComponent,
        DeliveryDetailsComponent,
        AuthTokenComponent,
        DigitsRoundOffPipe,
        OrderConfirmationComponent,
        JoinpartyComponent,
        JoinpartyCreateComponent,
        JoinpartyScanComponent,
        CartEmptyDialogComponent,
        TimeLeftPipe,
        CorrelateItemsComponent,
        CorrelateItemComponent,
        UpscaleItemComponent,
        RoundingOffComponent,
        RoundOffItemsComponent,
        RoundOffItemComponent,
        ContactUsComponent,
        PaymentModesComponent,
        UpiPaymentVerifyComponent,
        UpiPaymentDetailsDialogComponent,
        PrivacyPolicyKittyposComponent,
        JokesComponent,
        KnowYourProductComponent,
        KnowYourProductCrossDialogComponent,
        KnowYourProductUpsaleDialogComponent,
        KnowYourItemComponent,
        BusinessPageComponent,
        CartSnackbarComponent,
        FeedbackFormComponent,
        SpecialOfferCardComponent,
        SpecialItemCardComponent
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDmRISErfG3jVp0kNGTwYmmcUhqd8R6FM4'
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        NgQrScannerModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        FormsModule,
        MatDialogModule,
        ClipboardModule,
        MatCardModule,
        MatButtonModule,
        MatBottomSheetModule,
        FlexLayoutModule,
        MatInputModule,
        MatCarouselModule.forRoot(),
        MatIconModule,
        MatToolbarModule,
        /* HttpClientInMemoryWebApiModule.forRoot(
             InMemoryDataService, {dataEncapsulation: false}
         ),*/
        MatGridListModule,
        MatButtonToggleModule,
        LayoutModule,
        MatSidenavModule,
        MatListModule,
        MatBadgeModule,
        OverlayModule,
        TextFieldModule,
        MatFormFieldModule,
        MatSliderModule,
        ScrollingModule,
        NgxGalleryModule,
        MatChipsModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatRadioModule,
        MatTabsModule,
        MatStepperModule,
        MatRippleModule,
        NgxSkeletonLoaderModule,
        ZXingScannerModule,
        MatProgressSpinnerModule,
        ProgressSpinnerModule,
        MatToolbarModule,
        DragDropModule,
        JsonApiModule,
        NgxSpinnerModule,
        MatIconModule,
        MatTooltipModule,
        HttpClientModule,
        MatTableModule,
        NgxQRCodeModule,
        MatSelectModule,
        NgOtpInputModule,



        //state managment store settings
        StoreModule.forRoot({
            restaurant: restaurantReducer,
            theme: themeReducer,
            diner: dinerReducer,
            ingredients: ingredientsReducer,
            categories: categoriesReducer,
            categoryId: categoryIdReducer,
            menu: menuReducer,
            menuId: menuIdReducer,
            sidenavtoggle: sidenavreducer,
            cart: cartReducer,
            order: orderReducer,
            loadIndicator: loadingIndicatorReducer,
            paymentOrder: paymentReducer,
            dinerIdentity: dinerIdentityReducer,
            round: roundReducer,
            serviceItems: serviceItemsReducer,
            webSocketConnectionStatus: WebSocketConnectionStatusReducer,
            websocketLog: WebsocketLogStateReducer,
            menuSettings: menuSettingsReducer,
            basket: basketReducer,
            roundOff: roundOffReducer,
            paymentModes: paymentModeReducer,
            upi: UPIReducer,
            joke: jokereducer,
        },
            { metaReducers: [clearState, storageSyncMetaReducer] }
        ),
        //TODO: This is subjected to be removed in prod
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states

        }),
        //effects settings
        EffectsModule.forRoot([IngredientsEffects,
            CategoriesEffects,
            MenuEffects,
            MenuSettingEffects,
            CartEffects,
            OrderEffects,
            PaymentEffects,
            WebsocketEffects
        ]),

    ],
    providers: [
        CookieService,
        NgxSpinnerService,
        ClipboardService,
        httpInterceptorProviders
        // NgxSpinnerService,
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: ConnectionLostInterceptor,
        //     multi: true
        //   },
        //   {
        //       provide: ErrorHandler,
        //       useClass: ErrorsHandler
        //   },


    ],
    bootstrap: [AppComponent],
    entryComponents: [
        NotificationsComponent,
        TextBoxComponent,
        SpecialsComponent,
        PaymentSuccessDialogComponent,
        CameraAccessDialogComponent,
        SelectCategoryDialogComponent,
        GeoLocationAccessComponent,
        OrderConfirmationComponent,
        CartEmptyDialogComponent,
        CorrelateItemsComponent,
        UpscaleItemComponent,
        RoundingOffComponent,
        RoundOffItemsComponent,
        UpiPaymentDetailsDialogComponent,
        KnowYourProductCrossDialogComponent,
        KnowYourProductUpsaleDialogComponent,
        CartSnackbarComponent
    ]
})
export class AppModule {
}
