import {Component, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef, NgZone} from '@angular/core';
import {MenuItem} from '~models/menu-item';
import {ActiveBanner} from '~models/active-banner';
import { Observable, of } from 'rxjs';
import { AppState } from '~app/reducers/main';
import { Store, select } from '@ngrx/store';
import { selectBusinessCategories, selectBusinessItems, selectCategoryByFocuedId, selectCategoryItems, selectCategoryItemsByFocusedId, selectItemsByIDs} from '~app/selectors/menu.selectors';
import { SaveCategoryIdAction } from '~app/actions/categories.actions';
import { MatDialog, MatIconRegistry } from '@angular/material';
import { SelectCategoryDialogComponent } from '../select-category-dialog/select-category-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuItemsComponent } from '../items/menu-items/menu-items.component';
import { FormControl, Validators } from '@angular/forms';
import { VoiceRecognitionService } from '~app/services/voice-recognition.service';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from '~app/services/notifications.service';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { Category } from '~app/models/category';

@Component({
    selector: 'app-quick-menu',
    templateUrl: './quick-menu.component.html',
    styleUrls: ['./quick-menu.component.scss']
})
export class QuickMenuComponent implements OnInit, OnChanges {

    menuItems$: Observable<MenuItem[]>

    categories$: Observable<Category[]>
    banner: ActiveBanner;
    inputText:string="";
    focusedCategory$:Observable<Category>
    searchTextControl = new FormControl('', Validators.required);

    bussinessItems:MenuItem[]=[]

    micOn = false
    
    voiceSearchText: string = ''

    @ViewChild(MenuItemsComponent,{static: false}) menuItemsComponent: MenuItemsComponent ;

    // @ViewChild('input', {static: false}) input: ElementRef;

    iOS: boolean = false
    
    constructor(private store: Store<AppState>,
                private matIconRegistry: MatIconRegistry, 
                private domSanitizer: DomSanitizer, 
                public dialog: MatDialog,
                public service : VoiceRecognitionService,
                private zone: NgZone,
                private loadingIndicatorService: LoadingIndicatorService,
                private spinner: NgxSpinnerService,
                private notificationService: NotificationsService
                ) {
                    this.service.init() //This is to initialize speech recognition
                    this.menuItems$=this.store.pipe(select(selectCategoryItemsByFocusedId))
                    this.focusedCategory$=this.store.pipe(select(selectCategoryByFocuedId))
                    this.categories$ = this.store.pipe(select(selectBusinessCategories))

                    if(navigator.platform==='MacIntel' || navigator.platform==='iPhone'){
                        this.iOS = true
                    }
                    if(localStorage.getItem("isCategoryDialogOpened") === "false"){
                        this.openCategoryDialog()
                    }

                    if(localStorage.getItem("isShownAddEatPay") ==  "false"){
                        // this.openAddEatPayDialog()
                    }

                   
        }

    ngOnInit() {

        this.zone.run(() => {
            if(this.service.recognition){
                this.service.recognition.addEventListener('result', (e:any) => {
                    const transcript = Array.from(e.results)
                      .map((result: any) => result[0])
                      .map((result) => result.transcript)
                      .join('');
                    // this.input.nativeElement.placeholder = transcript
                    this.searchTextControl.setValue(transcript)
                    this.voiceSearchInput(transcript)
                  });
            }
            
        })
        
      this.initializeMatIcons()
      this.store.pipe(select(selectBusinessItems)).subscribe(
          next=>{
            this.bussinessItems=next;
          }
      )



    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    categoryIdChangeHandler(categoryId: string) {
        this.store.dispatch(new SaveCategoryIdAction(categoryId))
        this.menuItems$ = this.store.pipe(select(selectCategoryItems, {id: categoryId}))
        
    }

    openCategoryDialog(): void {
        const dialogRef = this.dialog.open(SelectCategoryDialogComponent, {
        });
    
        dialogRef.afterClosed().subscribe(result => {

            document.getElementById('menuItems').scroll({top:0,behavior:'smooth'});
            this.searchTextControl.setValue("")
            this.menuItems$ = this.store.pipe(select(selectCategoryItemsByFocusedId))

        });
    }


    // openAddEatPayDialog(): void {
    //     const dialogRef = this.dialog.open(AddEatPayComponent, {
    //         width: "340px"
    //     });
    
    //     dialogRef.afterClosed().subscribe(result => {
    //         localStorage.setItem("isShownAddEatPay","true")
    //     });
    // }
  
    searchInput(event :Event){

        const filterValue = (event.target as HTMLInputElement).value.toLocaleLowerCase();

        if(filterValue.length < 1){
            this.loadingIndicatorService.set(LoadingContextMap.LOADING)
            this.spinner.show()
            this.menuItems$ = this.store.pipe(select(selectCategoryItemsByFocusedId))
            this.spinner.hide()
        }


    }

    search(){

        this.loadingIndicatorService.set(LoadingContextMap.LOADING)
        this.spinner.show()
    
        let filterValue = this.searchTextControl.value

        if(filterValue.length>0){
            let filteredlist_arr:MenuItem[] =[]
            filteredlist_arr = this.bussinessItems.filter(m=> m.name.toLowerCase().includes(filterValue.toLowerCase()))
            // this.menuItems$=of(filteredlist_arr)

            let filtered_arr_ids = filteredlist_arr.map(f => f.id)

            this.menuItems$=this.store.pipe(select(selectItemsByIDs, {ids: filtered_arr_ids}))

        }
        this.spinner.hide()
    }

    
  initializeMatIcons(): void {
    this.categories$.subscribe(
        next => {
            for(let category of next){
                if (category !== undefined) {
                    this.matIconRegistry.addSvgIcon(category.name,this.domSanitizer.bypassSecurityTrustResourceUrl(category.imageUrl));
                }  
            }
        } 
    )
 }

 startSpeechRecognition(){
     this.micOn  = true
     this.service.start()
 }
    
 stopSpeechRecognition(){
     this.micOn = false
    this.service.stop()

 }


 voiceSearchInput(voiceSearchText: string){
        
    const filterValue = voiceSearchText.toLocaleLowerCase();

    if(filterValue.length>1){
             let filteredlist_arr:MenuItem[] =[]
             filteredlist_arr = this.bussinessItems.filter(m=> m.name.toLowerCase().includes(filterValue))
             this.menuItems$=of(filteredlist_arr)
    }
    if(filterValue.length<1){
        this.menuItems$ = this.store.pipe(select(selectCategoryItemsByFocusedId))
    }

}

}