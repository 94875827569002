import { State } from '@ngrx/store';
import { paymentActions, PaymentActionTypes } from '~app/actions/payment.actions';
import { PaymentOrder } from '~app/models/payment';

let initialState: PaymentOrder = {
    id: null,
    amount: null,
    amount_paid: null,
    amount_due:null,
    currency: null,
    receipt: null,
    status: null,
    attempts: null,
    entity: null,
    notes: null,
    created_at: null,
    orderID:null,
    paymentID:null,
    isPaid:false,
    mode:null
}


export function paymentReducer(state=initialState, action: paymentActions){
    switch(action.type){

        case PaymentActionTypes.INITIATE_PAYMENT: {
             return state
        }

        case PaymentActionTypes.LOAD_PAYMENT: {
             return state
        }

        case PaymentActionTypes.SAVE_PAYMENT: {
            return action.payload
        }

        case PaymentActionTypes.UPDATE_PAYMENT: {

            let payment = action.payload
            
            let changes = {
                ...state,
                paymentID:payment.paymentID,
                orderID:payment.orderID,
                isPaid:true
            }

            return changes
        }


        case PaymentActionTypes.CLEAR_PAYMENT: {
            return undefined
        }

        default: {
            return state
        }
    }
}