import * as fromMenuSettings from '../reducers/menu-settings.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CategoryIndex, itemIndex } from '~app/models/menu-settings';

export interface State {
  menuSettings: fromMenuSettings.MenuSettingsState
}

export const selectMenuSettingsState = createFeatureSelector<fromMenuSettings.MenuSettingsState>('menuSettings')

export const selectMenuSettingsIds = createSelector(
  selectMenuSettingsState,
  (menuSettingsState) => fromMenuSettings.selectMenuSettingsIds(menuSettingsState)
)

export const selectMenuSettingsEntities = createSelector(
  selectMenuSettingsState,
  (menuSettingsState) => fromMenuSettings.selectMenuSettingsEntities(menuSettingsState)
)

export const selectCategoriesOrder = createSelector(
  selectMenuSettingsIds,
  selectMenuSettingsEntities,
  (ids, menuSettings) => {
    let categoryOrder: CategoryIndex[] = []
    if (ids.length > 0) {
      for (let id of ids) {
        let ci:CategoryIndex;
        ci ={
          categoryId:menuSettings[id].categoryId,
          index:menuSettings[id].index,
          items:menuSettings[id].items.slice().sort((a, b) => a.index - b.index)
        }  
        categoryOrder.push(ci)
      }
      categoryOrder = categoryOrder.slice().sort((a, b) => a.index - b.index)
    }
    return  categoryOrder
  }

)

export const selectCategoryitemsOrderByIndex = createSelector(
  selectCategoriesOrder,
  (catOrder,props) => {
    let selectedCat = props.id
    return catOrder.filter(m => m.categoryId == selectedCat)[0].items
  }

)

