/* 
  This is to communication between grand child and grand parent
*/

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from '~app/models/menu-item';

@Injectable({
  providedIn: 'root'
})
export class SplGParentChildService {

  constructor() { }

  private childClickedEvent = new BehaviorSubject<MenuItem>(null);
  private childClickedEventOnItemClick = new BehaviorSubject<boolean>(false);

  emitChildEvent(msg: MenuItem){
     this.childClickedEvent.next(msg);
  }

  childEventListner(){
     return this.childClickedEvent.asObservable();
   } 

   emitChildEventOnItemClick(msg: boolean){
    this.childClickedEventOnItemClick.next(msg);
   }

   childEventListenerOnItemClick(){
     return this.childClickedEventOnItemClick.asObservable();
   }

}
