import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SavePaymentModesAction } from '~app/actions/payment-modes.actions';
import { AppState } from '~app/reducers/main';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentModesService {

  paymentModesUrl = '/api/payment-modes'

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private store: Store<AppState>
  ) { }

  initialize(): Observable<any>{
    let restaurantID = this.authService.getContext().restaurantId

    return this.http.get<any>(this.paymentModesUrl+'?restaurantID='+restaurantID).pipe(map(c => {return {source: 11, result: c}}));
  }

  load(next: any){
    this.store.dispatch(new SavePaymentModesAction(next))
  }
}
