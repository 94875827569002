
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ServiceItem } from '~app/models/service-item';
import * as fromServiceItems from '../reducers/service-items.reducer';

export interface State{
  serviceItems: fromServiceItems.ServiceItemsState
}

export const selectServiceItemsState = createFeatureSelector<fromServiceItems.ServiceItemsState>('serviceItems')

export const selectServiceItemsIds = createSelector(
   selectServiceItemsState,
  (serviceItemsState) => fromServiceItems.selectserviceItemsIds(serviceItemsState)
)

export const selectServiceItemsEntities = createSelector(
   selectServiceItemsState,
  (serviceItemsState) => fromServiceItems.selectserviceItemsEntities(serviceItemsState)
)


export const selectServiceItems = createSelector(
    selectServiceItemsIds,
    selectServiceItemsEntities,
    (ids,ServiceItems)=>{
        let finalResult:ServiceItem[]=[];
        for(let id of ids ){
            finalResult.push(ServiceItems[id])
        }
    return finalResult
    }
)