/*
QuestionId string `json:"questionId"`
	SingleSelectionResponse *MultipleChoiceSingleSelectionQuestionResponse `json:"singleSelectionResponse"`
	MultiSelectionResponse  *MultipleChoiceMultiSelectionQuestionResponse `json:"multiSelectionResponse"`
	RangeResponse *RangeQuestionResponse `json:"rangeResponse"`
	TextResponse *TextQuestionResponse `json:"textResponse"`
 */
export class MultiSelectionResponse {
    constructor(choiceIds: string[]) {
        this.selectedChoiceIds = choiceIds;
        // this.selectedIndices = choiceIds;
    }

    selectedChoiceIds: string[];
    selectedIndices: number[];
}
