import { Component, OnInit, Input } from '@angular/core';
import { Ingredient } from '~app/models/ingredient';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-spl-ingredients',
  templateUrl: './spl-ingredients.component.html',
  styleUrls: ['./spl-ingredients.component.scss']
})
export class SplIngredientsComponent implements OnInit {

  @Input()
    ingredients: Ingredient[];

    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {

    }

    ngOnInit() {
        for (const ingredient of this.ingredients) {
            if (ingredient !== undefined) {
                this.matIconRegistry.addSvgIcon(ingredient.name,
                    this.domSanitizer.bypassSecurityTrustResourceUrl(ingredient.imageUrl));
            }
        }
    }

}
