import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { AppState } from '~app/reducers/main';
import { Store } from '@ngrx/store';
import { SaveCategoriesAction, ReloadCategoriesAction } from '~app/actions/categories.actions';
import { LoadingIndicatorService } from './loading-indicator.service';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { map } from 'rxjs/operators';
import { Category } from '~app/models/category';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private categoriesUrl = 'api/categories'

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private store: Store<AppState>,
    private loadingIndicatorService: LoadingIndicatorService
  ) { }

  initialize(): Observable<any>{
    const restaurantID = this.authService.getContext().restaurantId
    
    this.loadingIndicatorService.set(LoadingContextMap.LOADING_CATEGORIES)
    return this.http.get<Category[]>(this.categoriesUrl + '/' + restaurantID).pipe(map(c => {return {source: 2, result: c}}))
  }

  load(next: any){
    this.store.dispatch(new SaveCategoriesAction(next))
  }

  reload(next: any){
    this.store.dispatch(new ReloadCategoriesAction(next))
  }


}
