import {Component, OnInit, Inject, ChangeDetectionStrategy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestaurantService } from '~app/services/restaurant.service';
import { NotificationsService } from '~app/services/notifications.service';
import { AppState } from '~app/reducers/main';
import { Store } from '@ngrx/store';
import { ResetMenuAction } from '~app/actions/menu.actions';
import { ResetCategoriesAction } from '~app/actions/categories.actions';
import { ResetIngredientsAction } from '~app/actions/ingredients.actions';
import { ClearCartAction } from '~app/actions/cart.actions';
import { ClearOrdersAction } from '~app/actions/order.actions';
import { Observable } from 'rxjs';
import { LoadingIndicatorState } from '~app/reducers/loading-indicator.reducer';
import { ClearPaymentAction } from '~app/actions/payment.actions';
import { AuthService } from '~app/services/auth.service';
import { UpdateDinerIdentityAction, ResetDinerIdentity, CompleteDiningAction } from '~app/actions/diner.actions';
import { ResetRoundsAction } from '~app/actions/round.actions';
import { ResetServiceItemsAction } from '~app/actions/service-Items.actions';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { SnackBarMessageMap } from '~app/constants/snackbar-message.constants';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CameraAccessDialogComponent } from '~app/components/camera-access-dialog/camera-access-dialog.component';


@Component({
    selector: 'app-qr-scanner',
    templateUrl: './scanner.component.html',
    styleUrls: ['./scanner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScannerComponent implements OnInit{
    color = 'primary';
    mode = 'indeterminate';
    value = 50;
    displayProgressSpinner = false;

    indicator$: Observable<LoadingIndicatorState>

    isCameraAccessAllowed: boolean

    constructor(
                public router: Router, 
                public spinner: NgxSpinnerService,
                public restaurantService: RestaurantService,
                public notificationService: NotificationsService,
                public store: Store<AppState>,
                public authService:AuthService,
                public loadingIndicatorService: LoadingIndicatorService,
                public dialog: MatDialog,
                ) {
                
                    let constraints={video:true};
                    navigator.mediaDevices.getUserMedia(constraints)
                    .then((stream)=>{console.log("camera access allowed")})
                    .catch((err)=>
                     {if(err.name=="NotAllowedError"){this.openDialog()}
                    });                    

    }
    ngOnInit(): void {
        // make couple of actions to reset store
        this.store.dispatch(new ResetMenuAction())
        this.store.dispatch(new ResetCategoriesAction())
        this.store.dispatch(new ResetIngredientsAction())
        this.store.dispatch(new ResetRoundsAction())
        this.store.dispatch(new ResetDinerIdentity())
        this.store.dispatch(new ResetServiceItemsAction())

        this.store.dispatch(new ClearCartAction())
        this.store.dispatch(new ClearOrdersAction())
        this.store.dispatch(new ClearPaymentAction())
        
    }

    public scanSuccessHandler(code: string) {
        //TODO: Now, Code is going to be url, and we need to extract the trackingID value and secret value
        const url = new URL(code)

        const trackingID = url.searchParams.get('trackingID');
        const secret = url.searchParams.get('secret')

        let newCode = undefined

        if(trackingID!=''){
            newCode = trackingID
        }

        if(secret!=''){
            newCode = newCode+':'+secret
        }

        this.loadingIndicatorService.set(LoadingContextMap.QR_SCAN)
        this.spinner.show()
        this.authService.authenticateDiner(newCode).subscribe(
            next => {
                this.store.dispatch(new CompleteDiningAction())
                this.loadingIndicatorService.set(LoadingContextMap.QR_SCAN_SUCCESS)

                this.store.dispatch(new UpdateDinerIdentityAction({ sessionID: next.sessionId, restaurantID: next.restaurantId, orderID: next.orderId ,trackingCode:next.trackingCode}))

                this.router.navigate(['session'])

                this.spinner.hide()
            },
            error => {
                this.spinner.hide()
                this.notificationService.showMessage(SnackBarMessageMap.WRONG_QR_SCAN)
            }
        )
        
    }

    openDialog() {
        this.dialog.open(CameraAccessDialogComponent, {
          data: {
          }
        });
      }

}
