import { GotRoundAddedMessageAction, GotRoundUpdatedMessageAction } from '~app/actions/websocket-log.actions';

export const EventActionMap = {
    'round-added:v0': GotRoundAddedMessageAction,
    'round-updated:v0': GotRoundUpdatedMessageAction
}

export const EventList = [
    "round-added:v0",
    "round-updated:v0"
]