import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs-compat';
import { SelectCategoryDialogComponent } from '~app/components/menu/select-category-dialog/select-category-dialog.component';
import { MenuItem } from '~app/models/menu-item';
import { Restaurant } from '~app/models/restaurant';
import { AppState } from '~app/reducers/main';
import { MenuIDState } from '~app/reducers/menu.reducer';
import { selectBusinessCategories, selectCategoryItemsByFocusedId, selectOfferItems, selectSpecialItems } from '~app/selectors/menu.selectors';
import { selectRestaurant } from '~app/selectors/restaurant.selectors';
import { BusinessHeaderComponent } from '../business-header-footer/business-header/business-header.component';
import { Category } from '~app/models/category';
import { SaveCategoryIdAction } from '~app/actions/categories.actions';
import { cartLocationService, position } from '~app/services/cart-location.service';

@Component({
  selector: 'app-business-page',
  templateUrl: './business-page.component.html',
  styleUrls: ['./business-page.component.scss']
})
export class BusinessPageComponent implements OnInit{
  offerItems$: Observable<MenuItem[]>;
  gamesIconUrl='../assets/images/jokes.png'
  jokesIconUrl='../assets/images/jokes.png'

  specialItems$: Observable<MenuItem[]>;
  restaurantID: string ;
  restaurantObj$: Observable<Restaurant>;

  videoUrls:string[];
  categories$: Observable<Category[]>;

  constructor(private store: Store<AppState>,public dialog: MatDialog,
    public router: Router,private route: ActivatedRoute,
    private cartLocationService:cartLocationService,
    private elementRef:ElementRef,

    private matIconRegistry: MatIconRegistry, 
    private domSanitizer:DomSanitizer
            ) { 
              this.restaurantObj$ = this.store.pipe(select(selectRestaurant))
              this.store.pipe(select('restaurant')).subscribe(
                next=>{
                 this.restaurantID=next.id
                  this.videoUrls=next.videoStories
                }
              )
            }

  ngOnInit() {

    this.categories$ = this.store.pipe(select(selectBusinessCategories))
    this.offerItems$=this.store.pipe(select(selectOfferItems))
    this.specialItems$=this.store.pipe(select(selectSpecialItems))
    this.initializeMatIcons()

  }


  openCategoryDialog(): void {
    // localStorage.setItem("isCategoryDialogOpened",'false')
    this.viewMenu()
}

viewMenu(){
  this.router.navigate(['restaurant',this.restaurantID,'menu']);
  
}
viewFun(){
  this.router.navigate(['restaurant',this.restaurantID,'jokes'])
}

loaded(){
  
}

onCategorySelection(categoryId:string){
  this.store.dispatch(new SaveCategoryIdAction(categoryId))
  this.router.navigate(['/restaurant', this.restaurantID, 'menu']);

}


viewFeedback(){
  this.router.navigate(['restaurant',this.restaurantID,'feedback-form'])
}
initializeMatIcons(): void {
  this.categories$.subscribe(
      next => {
          for(let category of next){
              if (category !== undefined) {
                  this.matIconRegistry.addSvgIcon(category.name,
                      this.domSanitizer.bypassSecurityTrustResourceUrl(category.imageUrl));
              }  
          }
      }
  )
}

OpenGoogleReviewPage(){
  const url = 'https://g.page/r/CYYB8c51YCxpEBM/review';
  window.open(url, '_blank');
}

}
