import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from '~app/services/notifications.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppState } from '~app/reducers/main';
import { Store } from '@ngrx/store';
import { Datastore } from '~app/jsonapi/store-service';
import { SessionProfile } from '~app/jsonapi/session-Profile.model';
import { PreloadingService } from '~app/services/preloading.service';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { SnackBarMessageMap } from '~app/constants/snackbar-message.constants';
import { SaveDinerAction } from '~app/actions/diner.actions';
import { AuthService } from '~app/services/auth.service';

@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.scss']
})
export class OnboardComponent implements OnInit {

  onBoardForm: FormGroup;

  constructor(
     public router: Router,
     public notificationService: NotificationsService,
     public spinner: NgxSpinnerService,
     public store: Store<AppState>,
     public dataStore: Datastore,
     public preloadingService: PreloadingService,
     public loadingIndicatorService: LoadingIndicatorService,
     public authService: AuthService
    ) {
      let hasRunningSession = localStorage.getItem("hasRunningSession")
      if(hasRunningSession!=null && JSON.parse(hasRunningSession)){
        this.router.navigate(['/restaurant', this.authService.getContext().restaurantId, 'menu']);
      }
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.onBoardForm = new FormGroup({
        // tslint:disable-next-line:max-line-length
        dinerName: new FormControl('', 
        Validators.compose([Validators.minLength(2), Validators.required]))
    });
 }

 onSubmit(){

  this.loadingIndicatorService.set(LoadingContextMap.UPDATING_GUEST)
  this.spinner.show()

  let dinerName = this.onBoardForm.get('dinerName').value

  if(!dinerName){
    dinerName = "chefk" // default chef name if user clicks on skip button
  }

   let sessionProfile = this.dataStore.createRecord(SessionProfile,{
    name:dinerName
  });

  sessionProfile.save().subscribe(
    next =>{
      this.store.dispatch(new SaveDinerAction({id: next.id, name: next.name}))
      this.preloadingService.initialize()
    },
    error =>{
      this.spinner.hide()
      this.notificationService.showMessage(SnackBarMessageMap.SOMETHING_WRONG)
    }
  ); 
   
  }

}
