
import { AppState } from '~app/reducers/main';
import { selectByItemId, selectCategoryItemsByFocusedId } from '~app/selectors/menu.selectors';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MenuItem } from '~models/menu-item';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { orderService, orderData, orderItem, Course } from 'src/app/services/add-order-list.service'
import { AddItemtoCartAction, RemoveItemFromCart, UpdateItemQuantityAction } from '~app/actions/cart.actions';
import { Observable, Subscription, timer, of } from 'rxjs';
import { CartItem } from '~app/models/cart';
import { UpdateBasket } from '~app/actions/basket.actions';
import { CartService } from '~app/services/cart.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
  item: string;
  originalPrice: number
  orignalItem: MenuItem
}


@Component({
  selector: 'app-upscale-item',
  templateUrl: './upscale-item.component.html',
  styleUrls: ['./upscale-item.component.scss']
})
export class UpscaleItemComponent implements OnInit {

  upScaleItem$:Observable<MenuItem>

  vegIcon = '../assets/icons/Veg_icon.svg';

  nonVegIcon = '../assets/icons/Nonveg_icon.svg';

  lightingBoltIcon='..//assets/icons/lightingBoltIcon.svg'


  constructor(private store: Store<AppState>,
    public dialogRef: MatDialogRef<UpscaleItemComponent>,
    private orderService: orderService,
    private cartService: CartService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { 

    this.upScaleItem$=this.store.pipe(select(selectByItemId,{id: data.item}))

  }

  ngOnInit() {
  }

  addItemWithOutAnimation(item: MenuItem) {

    this.cartService.getCart().subscribe(
      next => {

        let c: CartItem[] = []
        
        if(next.length>0){
          c = next
        }
        c.push({
          itemID: item.id,
          quantity: 1,
          referrer: 1, //1 is upsale ref: 0 -> regular, 1 -> upsale, 2 -> cross sale, 3 -> round off
          intendedItemID: this.data.orignalItem.id,
          intendedPrice: this.data.orignalItem.price*(1-this.data.orignalItem.offer/100),
          optPrice: item.price*(1 - item.offer/100)
        })
        

        // Dispatch an action, and update the backend

        this.cartService.updateCart(c).subscribe(
          next => {
            //TODO: Remove the orignal selection, and select the upsale item; this.data.orignalItem

            let newc: CartItem[] = []

            for(let ci of c){
              if(ci.itemID != this.data.orignalItem.id){
                newc.push(ci)
              }
            }

            this.cartService.updateCart(newc).subscribe(
              next => {
                this.store.dispatch(new UpdateItemQuantityAction({ item: this.data.orignalItem, operation: "-" }))
                this.store.dispatch(new RemoveItemFromCart(this.data.orignalItem.id))
                this.store.dispatch(new UpdateBasket(newc))
              },
              error => {
                console.log("Error in updating cart:", error)
              }
            )

            this.store.dispatch(new AddItemtoCartAction(item));
            this.store.dispatch(new UpdateBasket(c))
            this.dialogRef.close()

          },
          error => {
            console.log("error in updating the cart:", error)
            this.close()
          }
        )

      },
      error => {
        console.log("error in getting cart from backend:", error)
        this.close()
      }
    )
  }



  close(){
    this.dialogRef.close()
  }

  checkIfIsPast(d: string): boolean {
    let givenDate = new Date(d)
    let currDate = new Date()

    if(currDate>givenDate){
      return true
    }else {
      return false
    }
  }

}


