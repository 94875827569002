import { Component, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { MenuItem } from '~app/models/menu-item';
import { RestaurantDataProvider } from '~app/services/restaurant-data-provider.service';
import { ActivatedRoute } from '@angular/router';
import { SplGParentChildService } from '~app/services/spl-gparent-child.service';
import { NotificationsService } from '~services/notifications.service';
import { Restaurant } from '~app/models/restaurant';
import { Category } from '~app/models/category';

@Component({
  selector: 'app-specials',
  templateUrl: './specials.component.html',
  styleUrls: ['./specials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialsComponent implements OnInit{
  restaurant: Restaurant;
  menuItems: MenuItem[];
  categories: Category[];

  menuItem: MenuItem;

  flag: boolean ;

  restaurantId: string;
  selectedCategoryId: string;
  constructor(private restaurantData: RestaurantDataProvider,
    private activatedRoute: ActivatedRoute,
    private splGrandChild: SplGParentChildService,
    private notificationsService: NotificationsService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.copySpecialMenu();
    
    this.splGrandChild.childEventListner().subscribe(menuItemId => {
      this.menuItem = menuItemId;
    });

    this.splGrandChild.childEventListenerOnItemClick().subscribe(showDetails => {
      this.flag = showDetails;
    });
  }

  private copySpecialMenu() {
    this.restaurant = this.restaurantData.restaurant;
    this.menuItems = this.restaurantData.todaySpecials.getTodaySpecialItems();
  }
  
}
