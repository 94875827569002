import { Ingredient } from '~app/models/ingredient';
import {EntityState, Dictionary, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import { ingredientsActions, ingredientsActionTypes } from '~app/actions/ingredients.actions';

export class IngredientsState implements EntityState<Ingredient>{
    ids: string[] | number[];
    entities: Dictionary<Ingredient>;
}

export const adapter: EntityAdapter<Ingredient> = createEntityAdapter<Ingredient>();

let initialState: IngredientsState = adapter.getInitialState();

export function ingredientsReducer(state=initialState, action: ingredientsActions){
    switch(action.type){
        case ingredientsActionTypes.LOAD_INGREDIENTS: {
            return state;
        }
        case ingredientsActionTypes.SAVE_INGREDIENTS: {
            return adapter.addMany(action.payload, state);
        }
        case ingredientsActionTypes.RESET_INGREDIENTS: {
            return undefined
        }

        case ingredientsActionTypes.RELOAD_INGREDIENTS: {
            return adapter.upsertMany(action.payload, state);
        }
        default: {
            return state;
        }
    }
}


// get the selectors
const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = adapter.getSelectors();
   
  // select the array of user ids
  export const selectIngredientsIds = selectIds;
   
  // select the dictionary of user entities
  export const selectIngredientsEntities = selectEntities;
   
  // select the array of users
  export const selectAllIngredients = selectAll;
   
  // select the total user count
  export const selectIngredientsTotal = selectTotal