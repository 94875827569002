import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, concatMap } from 'rxjs/operators';
import { EventActionMap } from '../constants/event-types.constants';
import { RoundsService } from '~app/services/rounds.service';
import { WebSocketActionTypes, GotEventAction, GotRoundAddedMessageAction, GotRoundUpdatedMessageAction, SaveWebsocketLogAction, AddEventAction } from '~app/actions/websocket-log.actions';

@Injectable()
export class WebsocketEffects {

    constructor(
        private actions$: Actions,
        private roundService: RoundsService,
        ){}


    @Effect()
    GotEventAction$ = this.actions$.pipe(
        ofType<GotEventAction>(WebSocketActionTypes.GOT_EVENT),
        map(action => action.payload),
        concatMap(payload => [
            new AddEventAction(payload)
        ])
    )


    @Effect()
    AddEventAction$  = this.actions$.pipe(
        ofType<AddEventAction>(WebSocketActionTypes.ADD_EVENT),
        map(action => action.payload),
        concatMap(payload => [
           //TODO:  SAVE_EVENT, and any side effects we need to do
           new SaveWebsocketLogAction(payload),
           new EventActionMap[payload.type](payload)
        ])

    )


    @Effect(
        {dispatch: false}
    )
    GotRoundAddedMessageAction$ = this.actions$.pipe(
        ofType<GotRoundAddedMessageAction>(WebSocketActionTypes.GOT_ROUND_ADDED_MESSAGE),
        map((action) => this.roundService.update(action.payload))
    )

    @Effect(
        {dispatch: false}
    )
    GotRoundUpdatedMessageAction$ = this.actions$.pipe(
        ofType<GotRoundUpdatedMessageAction>(WebSocketActionTypes.GOT_ROUND_UPDATED_MESSAGE),
        map(action => this.roundService.updateRound(action.payload))
    )

}
