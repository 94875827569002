import {JsonApiModelConfig, JsonApiModel, Attribute, HasMany, BelongsTo} from 'angular2-jsonapi';


@JsonApiModelConfig({
    type: 'session-profiles'
})

export class SessionProfile extends JsonApiModel {

    @Attribute()
    name: string;

    @Attribute()
    phone: string

    @Attribute()
    address: string
   
}