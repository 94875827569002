import { Component, OnInit } from '@angular/core';
import { RestaurantDataProvider } from '~services/restaurant-data-provider.service';
import { Restaurant } from '~app/models/restaurant';

@Component({
  selector: 'app-business-header-footer',
  templateUrl: './business-header-footer.component.html',
  styleUrls: ['./business-header-footer.component.scss']
})
export class BusinessHeaderFooterComponent implements OnInit {

  restaurant: Restaurant;
  constructor(private _dp: RestaurantDataProvider) { }

  ngOnInit() {
    this.restaurant = this._dp.restaurant;
  }

}
