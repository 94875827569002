import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidationMap } from '~app/constants/validators';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { AuthService } from '~app/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '~app/reducers/main';
import { UpdateDinerIdentityAction } from '~app/actions/diner.actions';
import { NotificationsService } from '~app/services/notifications.service';
import { SnackBarMessageMap } from '~app/constants/snackbar-message.constants';

@Component({
  selector: 'app-auth-token',
  templateUrl: './auth-token.component.html',
  styleUrls: ['./auth-token.component.scss']
})
export class AuthTokenComponent implements OnInit {

  authTokenForm: FormGroup
  trackingID: string

  constructor(
    private route: ActivatedRoute,
    private loadingIndicatorService: LoadingIndicatorService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router,
    private notificationService: NotificationsService
  ) { }

  ngOnInit() {
    if(localStorage.getItem('trackingID')!=null){
      this.trackingID = localStorage.getItem('trackingID')
    }
    this.initForm()
  }

  private initForm() {
    this.authTokenForm = new FormGroup({
        // tslint:disable-next-line:max-line-length
        authCode: new FormControl('',
        Validators.compose(
          [Validators.minLength(6),
          Validators.required,
          Validators.maxLength(6),
          Validators.pattern(ValidationMap.OTP_PATTERN)
        ]
        ))
    })
  }

  onSubmit(){

    let authCode = this.authTokenForm.get('authCode').value

    let code = this.trackingID + ':' + authCode

    this.loadingIndicatorService.set(LoadingContextMap.CREATING_SESION)
    this.spinner.show()
    this.authService.authenticateDiner(code).subscribe(
        next => {
            this.loadingIndicatorService.set(LoadingContextMap.SUCCESS)

            this.store.dispatch(new UpdateDinerIdentityAction({ sessionID: next.sessionId, restaurantID: next.restaurantId, orderID: next.orderId ,trackingCode:next.trackingCode}))

            this.router.navigate(['/session'])

            this.spinner.hide()
        },
        error => {
            this.spinner.hide()
            this.notificationService.showMessage(SnackBarMessageMap.SOMETHING_WRONG)
        }
    )


    
  }

}
