import { Injectable } from '@angular/core';
import { Datastore } from '~app/jsonapi/store-service';
import { ServiceItemsSet } from '~app/jsonapi/service-item-set.model';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';
import { AppState } from '~app/reducers/main';
import { Store } from '@ngrx/store';
import { SaveServiceItemsAction } from '~app/actions/service-Items.actions';
import { LoadingIndicatorService } from './loading-indicator.service';
import { LoadingContextMap } from '~app/constants/loading-context.constants';

@Injectable({
  providedIn: 'root'
})
export class ServiceItemsService {

  constructor(
    private authService: AuthService,
    private dataStore: Datastore,
    private store: Store<AppState>,
    private loadingIndicatorService:  LoadingIndicatorService
  ) { }

  initialize(){

    let restaurantId = this.authService.getContext().restaurantId

    return this.dataStore.findRecord(
      ServiceItemsSet,restaurantId,{
      include: 'service-items,service-items.image-download-descriptor'
    }).pipe(map(s => {return {source: 6, result: s}}))
  }

  load(next: any){
    let serviceables = next.serviceItems;
    if(serviceables){
      let serviceItemList =[] ;

      for( let serviceable of serviceables ){
  
          serviceItemList.push({
              id:serviceable.id,
              name:serviceable.name,
              imageUrl:serviceable.imageDownloadDescriptor.url
          })
      }
     this.store.dispatch(new SaveServiceItemsAction(serviceItemList))
     this.loadingIndicatorService.set(LoadingContextMap.LOADING_SERVICEABLES)
  
    }
  }
   

}
