import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Restaurant } from '~app/models/restaurant';
import { AppState } from '~app/reducers/main';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

   phoneIcon= '../assets/icons/phoneIcon.png' ;
   whatsappIcon= '../assets/icons/whatsappIcon.png' ;
   facebookIcon= '../assets/icons/facebookIcon.png' ;
   emailIcon= '../assets/icons/emailIcon.png' ;

   restaurant$: Observable<Restaurant>

   facebookLink = 'https://www.facebook.com/Kittychef-105352548588515'

   whatsappLink = 'https://api.whatsapp.com/send?phone=919886100232&text=Hello%20Kittychef!'

  constructor(
    private store: Store<AppState>
  ) { 
    this.restaurant$=this.store.pipe(select('restaurant'))
  }
   
  ngOnInit() {
    this.restaurant$.subscribe(
      next => {
        this.whatsappLink = this.whatsappLink + '. We are messaging from '+next.name+'.'
      },
      error => {
        console.log("error in getting restaurant details:", error)
      }
    )
  }
   

  contactViaFacebook(){
    
  }

  contactViaEmail(){
    
  }
}
