import { loadingActions, LoadingIndicatorActionTypes } from '~app/actions/loading-indicator.actions';

export interface LoadingIndicatorState {
    load:boolean;
}
let initialstate: LoadingIndicatorState={
    load:false,
}

export function loadingIndicatorReducer(state=initialstate, action: loadingActions){
    switch(action.type){
        case LoadingIndicatorActionTypes.SHOW_INDICATOR: {
            return {
                load: true
            }
        }
        case LoadingIndicatorActionTypes.HIDE_INDICATOR: {
            return {
                load: false
            }
        }
        default: {
            return state
        }
    }
}