import { Component, OnInit, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { ActivationStart, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable } from 'rxjs';
import { LoadingIndicatorService } from './services/loading-indicator.service';
import { SwUpdate } from '@angular/service-worker';
import { NgxSpinnerService } from 'ngx-spinner';
import { StillSnackbarService } from './services/still-snackbar.service';
import { LoadingContextMap } from './constants/loading-context.constants';
import { AuthService } from './services/auth.service';
import { DinerState } from './reducers/diner.reducer';
import { AppState } from './reducers/main';
import { Store, select } from '@ngrx/store';
import { ConnectionService } from 'ng-connection-service';
import { MatSnackBar } from '@angular/material';
import { ConnectivityOfflineService } from './services/connectivity-offline.service';
import { Connectivity } from './constants/connectivity.constants';
import { CompleteDiningAction } from './actions/diner.actions';
import { filter } from 'rxjs/internal/operators/filter';
import { MenuReloadAction } from './actions/menu.actions';
import { ReloadMenuSettingsAction } from './actions/menu-setting.actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
    title = 'Kittychef';
    
    loadingContext$: Observable<string>

    dinerProfile: DinerState

    hasJwt: boolean

    hasAuth: boolean = false

    expireTimeStamp: number

    constructor(private router: Router,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private loadingIndicatorService: LoadingIndicatorService,
        private swUpdate: SwUpdate,
        private spinner: NgxSpinnerService,
        private stillSnackBarService: StillSnackbarService,
        private authService: AuthService,
        private store: Store<AppState>,
        private connectionService: ConnectionService,
        private _snackBar: MatSnackBar,
        private connectivityOfflineService: ConnectivityOfflineService,
    ) {
        if(localStorage.getItem('auth')!=null){
            this.hasAuth = true
        }
        this.hasJwt = this.authService.hasJwtToken()

        this.store.pipe(select('diner')).subscribe(
            next => {
                this.dinerProfile = next
            }
        )

        let expireTimeStamp = this.authService.getContext()?this.authService.getContext().exp: Number.MAX_SAFE_INTEGER 

        let timeStamp = Math.floor(Date.now() / 1000)

        if(this.hasAuth && this.hasJwt && expireTimeStamp > timeStamp && localStorage.getItem('doneWithPreloading')!=null && localStorage.getItem('doneWithPreloading')=='true'){
            this.router.navigate(['/restaurant', this.authService.getContext().restaurantId, 'home']);
        }
        // else if(this.hasJwt && !this.dinerProfile){
        //      this.router.navigate(['session'])
        //  }
        else if(this.hasAuth && this.hasJwt && expireTimeStamp <timeStamp){
            localStorage.clear()
            this.store.dispatch(new CompleteDiningAction())
            this.router.navigate(['/'])
        }

        // Page reload/refresh
            this.router.events
        .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
        .subscribe(event => {
        if (
            event.id === 1 &&
            event.url === event.urlAfterRedirects 
        ) {
        // TODO: Dispatch an action here now
        if(localStorage.getItem('auth')){
            this.store.dispatch(new MenuReloadAction({}))
            this.store.dispatch(new ReloadMenuSettingsAction())
        }
      
        }
        })

        // Connectity logic
        this.connectionService.monitor().subscribe(isConnected => {
            if (isConnected) {
              this._snackBar.open(Connectivity.ONLINE_MSG, 'CLOSE', {
                duration: 3000,
              });
            }
            else {
             let snackBarRef = this.connectivityOfflineService.show()
      
             snackBarRef.onAction().subscribe(()=> {})
            }
          })


        console.log("we are in app component ts file: swupdate is enabled:", this.swUpdate.isEnabled, navigator.platform)
        if (this.swUpdate.isEnabled) {
            console.log("we are in swupdate if condition")
            this.swUpdate.available.subscribe(() => {
      
              let snackBarRef = this.stillSnackBarService.showSnackBar()
      
              snackBarRef.onAction().subscribe(()=> {
                this.loadingIndicatorService.set(LoadingContextMap.UPDATING)
                this.spinner.show()
                window.location.reload();
                this.spinner.hide()
              })
            })
          }

        this.loadingContext$ = this.loadingIndicatorService.get()


        // this.router.events.subscribe(event => {
        //     if (event instanceof NavigationEnd) {
        //         console.log('This is navigation end!');
        //         (<any>window).ga('set', 'page', event.urlAfterRedirects);
        //         (<any>window).ga('send', 'pageview');
        //     }
        // });

        this.matIconRegistry.addSvgIcon(
            "joinParty",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/joinParty.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "menu",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/menu.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "orders",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/orders.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "payments",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/payments.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "rateRestaurant",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rateRestaurant.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "reportProblem",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/reportProblem.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "rateKitty",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/rateKitty.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "restaurantCorner",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/restaurantCorner.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "serviceables",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/serviceables.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "theme",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/theme.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "biryani2",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/biryani2.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "breakfast2",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/breakfast2.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "curry2",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/curry2.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "dessert2",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/dessert2.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "drincks2",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/drinck2.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "soup2",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/soup2.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "starters2",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/startes2.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "websocket",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/websocket.svg")
        );


    }

    ngOnInit(){
    }

}
