import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'ratings'
})
export class RatingsPipe implements PipeTransform {

    star = 'star';
    halfStar = 'star_half';
    stringArray = ['star_border', 'star_border', 'star_border', 'star_border', 'star_border'];

    transform(value: number): string[] {

        const lastDigit = (value * 10) % 10;
        const firstDigit = ((value * 10) - lastDigit) / 10;

        for (let i = 0; i < firstDigit; i++) {
            this.stringArray.fill(this.star, i, i + 1);
        }

        if (lastDigit !== 0) {
            this.stringArray = this.stringArray.fill(this.halfStar, firstDigit, firstDigit + 1);
        }

        return this.stringArray;
    }

}
