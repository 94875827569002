
import * as fromIngredients from  '../reducers/ingredients.reducer'
import { createFeatureSelector, createSelector } from '@ngrx/store'

export interface State{
    ingredients: fromIngredients.IngredientsState
}

export const selectIngredientsState = createFeatureSelector<fromIngredients.IngredientsState>('ingredients')

export const selectIngredientsIds = createSelector(
    selectIngredientsState,
    (ingredientsState) => fromIngredients.selectIngredientsIds(ingredientsState)
)

export const selectIngredientsEntities = createSelector(
    selectIngredientsState,
    (ingredientsState) => fromIngredients.selectIngredientsEntities(ingredientsState)
)