import { Component, OnInit } from '@angular/core';
import { AppState } from '~app/reducers/main';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CartState } from '~app/reducers/cart.reducer';
import { selectCartItems, selectCartCount, selectTotalCartBill } from '~app/selectors/cart.selectors';
import { ClearCartAction, UpdateItemQuantityAction } from '~app/actions/cart.actions';
import { MenuForRoundOff, MenuItem } from '~app/models/menu-item';
import { SendtoKitchenAction } from '~app/actions/order.actions';
import { RoundState } from '~app/reducers/order.reducer';
import { selectOrderItems, selectOrderIds, selectOrderCount, selectTotalBill } from '~app/selectors/order.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { Datastore } from '~app/jsonapi/store-service';
import { Round } from '~app/jsonapi/round.model';
import { JsonApiQueryData } from 'angular2-jsonapi';
import { RoundToAdd } from '~app/reducers/round.reducer';
import { AddRoundAction } from '~app/actions/round.actions';
import { LoadingIndicatorService } from '~app/services/loading-indicator.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from '~app/services/notifications.service';
import { LoadingContextMap } from '~app/constants/loading-context.constants';
import { selectRestaurant } from '~app/selectors/restaurant.selectors';
import { Restaurant } from '~app/models/restaurant';
import { MatDialog } from '@angular/material';
import { OrderConfirmationComponent } from '~app/components/order-confirmation/order-confirmation.component';
import { CartService } from '~app/services/cart.service';
import { CartItem } from '~app/models/cart';
import { ClearBasket, UpdateBasket } from '~app/actions/basket.actions';
import { SnackBarMessageMap } from '~app/constants/snackbar-message.constants';
import { CartEmptyDialogComponent } from '~app/components/cart-empty-dialog/cart-empty-dialog.component';
import { RoundingOffComponent } from '~app/components/rounding-off/rounding-off.component';
import { RoundOffService } from '~app/services/round-off.service';
import { selectRoundOffItems } from '~app/selectors/round-off.selectors';
import { RoundOffItemsComponent } from '~app/components/round-off-items/round-off-items.component';


export class listItem {
  itemID: string;
  quantity: number;
  unitPrice?: number
  itemName?: string
  offer?: number


  referrer?: number //This is for sales tracking; ref: 0 -> regular, 1 -> upsale, 2 -> cross sale, 3 -> round off

  intendedItemID?: string

  intendedPrice?: number

  optPrice?: number

  constructor(itemID: string, quantity: number, referrer: number, intendedItemID: string, intendedPrice: number, optPrice: number) {
    this.itemID = itemID;
    this.quantity = quantity;

    this.referrer = referrer
    this.intendedItemID = intendedItemID
    this.intendedPrice = intendedPrice
    this.optPrice = optPrice
  }
}

@Component({
  selector: 'app-current-course',
  templateUrl: './current-course.component.html',
  styleUrls: ['./current-course.component.scss']
})
export class CurrentCourseComponent implements OnInit {

  cartItems$: Observable<any>

  cartItems: MenuItem[]

  ordersCount$: Observable<number>
  // directly connected with jsonapi
  rounds: Round[]

  cartEmptyImage = '../assets/images/cart_is_empty.png'

  cashPaymentIcon='../assets/icons/cash.png'
  cardPaymentIcon='../assets/icons/credit.png'
  upiPaymentIcon='../assets/icons/upi.svg'

  cartCount$: Observable<number>;
  restaurantObj$: Observable<Restaurant>;
  totalCartBill$: Observable<number>;

  onlyMenu: boolean = false

  totalBill: number

  isPartyRunning: boolean = false

  restaurant: Restaurant

  roundOffItems$: Observable<MenuItem[]>

  roundOffItems: MenuItem[]

  constructor(private store: Store<AppState>,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private dataStore: Datastore,
    private loadingIndicatorService: LoadingIndicatorService,
    private spinner: NgxSpinnerService,
    private notificationsService: NotificationsService,
    public dialog: MatDialog,
    private cartService: CartService,
  ) {
    this.cartItems$ = this.store.pipe(select(selectCartItems))
    this.cartCount$ = this.store.pipe(select(selectCartCount))
    this.ordersCount$ = this.store.pipe(select(selectOrderCount))
    this.totalCartBill$ = this.store.pipe(select(selectTotalCartBill))
    this.restaurantObj$ = this.store.pipe(select(selectRestaurant))
    this.roundOffItems$ = this.store.pipe(select(selectRoundOffItems))

    this.cartItems$.subscribe(
      next => {
        this.cartItems = next
      },
      error => {
        console.log("error in getting cartitems:", error)
      }
    )

  }

  ngOnInit() {
    if (localStorage.getItem('onlyMenu') != null && localStorage.getItem('onlyMenu') == 'true') {
      this.onlyMenu = true
    }

    this.totalCartBill$.subscribe(
      next => {
        this.totalBill = next
      }
    )

    this.restaurantObj$.subscribe(
      next => {
        this.restaurant = next
      },
      error => {
        console.log("error in getting restaurant obj:", error)
      }
    )

    this.roundOffItems$.subscribe(
      next => {
        this.roundOffItems = next
      },
      error => {
        console.log("error in getting roundoff items:",error)
      }
    )

    if (JSON.parse(localStorage.getItem('isPartyRunning'))) {
      this.isPartyRunning = true
    }
  }


  changeCount(item: MenuItem, operation: string) {
    // this.store.dispatch(new UpdateItemQuantityAction({ item: item, operation: operation }))

    this.loadingIndicatorService.set("Adding item to cart...")
    this.spinner.show()

    this.cartService.getCart().subscribe(
      next => {
        let c: CartItem[] = []

        if (next.length > 0) {
          // copy these item to c

          c = [...next]
        }


        let cartItemsCount = c.map(e => e.quantity).reduce((total, b) => { return total + b })

        if (cartItemsCount === 1 && operation === '-') {
          const emptyCartconfirmDialogRef = this.dialog.open(CartEmptyDialogComponent, {
         
          });
          emptyCartconfirmDialogRef.afterClosed().subscribe(result => {
            if (result.confirm) {

              let idx

              for (let i = 0; i < c.length; ++i) {
                if (c[i].itemID === item.id) {
                  idx = i
                  break
                }
              }

              if (operation === '-') {
                c[idx].quantity = c[idx].quantity - 1

                this.store.dispatch(new UpdateItemQuantityAction({ item: item, operation: operation }));
              }

              // remove logic for quantity <0
              if (c[idx].quantity === 0) {
                c = [
                  ...c.slice(0, idx),
                  ...c.slice(idx + 1,)
                ]
              }

              //Now, update this with the backend


              this.cartService.updateCart(c).subscribe(
                next => {
                  // Update this with the store
                  this.store.dispatch(new UpdateBasket(c))
                },
                error => {
                  console.log("Error in updating cart to the backend:", error)
                }
              )
            }
          });
          this.spinner.hide()

        }

        else {

          if(operation === '-'){
            this.loadingIndicatorService.set("removing item from cart...")
          }
          if(operation === '+'){
            this.loadingIndicatorService.set("Adding item to cart...")
          }
          this.spinner.show()
          
          let idx
          for (let i = 0; i < c.length; ++i) {
            if (c[i].itemID === item.id) {
              idx = i
              break
            }
          }

          if (operation === '-') {
            c[idx].quantity = c[idx].quantity - 1


            this.store.dispatch(new UpdateItemQuantityAction({ item: item, operation: operation }));
          }
          if (operation === '+') {
            c[idx].quantity = c[idx].quantity + 1

            this.store.dispatch(new UpdateItemQuantityAction({ item: item, operation: operation }));

          }


          // remove logic for quantity <0
          if (c[idx].quantity === 0) {
            c = [
              ...c.slice(0, idx),
              ...c.slice(idx + 1,)
            ]
          }

          //Now, update this with the backend


          this.cartService.updateCart(c).subscribe(
            next => {
              // Update this with the store
              this.store.dispatch(new UpdateBasket(c))
            },
            error => {
              console.log("Error in updating cart to the backend:", error)
              this.loadingIndicatorService.set("Error in updating to cart...")
              this.spinner.hide()
            }
          )

        }

        this.spinner.hide()

      },
      error => {
        this.loadingIndicatorService.set("Error in adding to cart...")
        this.spinner.hide()

      }
    )


  }


  order(cart: any, cartAmount: number) {
    //TODO: Show dialogue, on confirmation call orderItems()

    const dialogRef = this.dialog.open(OrderConfirmationComponent, {
      data: {
        cartAmount: cartAmount
      }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.orderItems(cart)
      }
    });
  }

  orderItems(cart: any) {

    this.loadingIndicatorService.set("Ordering...")
    this.spinner.show()

    this.store.dispatch(new SendtoKitchenAction(cart))

    let listItems = [];

    for (let item of cart) {
      listItems.push(new listItem(item.id, item.quantity, item.referrer, item.intendedItemID, item.intendedPrice, item.optPrice))
    };

    let round = this.dataStore.createRecord(Round, {
      lineItems: listItems
    });
    round.save().subscribe(
      next => {

        //TODO: Empty cart
        this.cartService.updateCart([]).subscribe(
          next => {
            this.store.dispatch(new ClearBasket())
          },
          error => {
            console.log("Error in emptying cart:", error)
          }
        )


        this.loadingIndicatorService.set(LoadingContextMap.SUCCESS)

        let round: RoundToAdd = {
          id: next.id,
          status: next.status,
          roundNumber: next.number,
          lineItems: next.lineItems
        }

        this.store.dispatch(new AddRoundAction(round))
        this.router.navigate(['../order-summary'], { relativeTo: this.activatedRoute })
        this.spinner.hide()
      },
      error => {
        this.spinner.hide()
        this.notificationsService.showMessage((error as Error).message)
      }
    );
  }

  backToQuickMenu() {
    this.router.navigate(['../menu'], { relativeTo: this.activatedRoute });
  }

  backToOrders() {
    this.router.navigate(['../order-summary'], { relativeTo: this.activatedRoute });
  }

  refreshCart() {
    this.loadingIndicatorService.set(LoadingContextMap.REFRESHING)
    this.spinner.show()

    this.cartService.getCart().subscribe(
      next => {
        this.loadingIndicatorService.set(LoadingContextMap.SUCCESS)
        this.store.dispatch(new UpdateBasket(next))
        this.spinner.hide()
      },
      error => {
        this.spinner.hide()
        this.notificationsService.showMessage(SnackBarMessageMap.SOMETHING_WRONG)
      }
    )
  }

  checkRoundOff(){

    //TODO: We need to call backend to get round off items

    let cgst = this.restaurant.cgstRate*0.01
    let sgst = this.restaurant.sgstRate*0.01
    let tcv = this.totalBill*(1+cgst +sgst) 

    let rn // Rounding number

    let l2d = tcv % 100

    let diff = 0 

    let hook = 100

    if(l2d !=0  && l2d != 50){
      if(l2d<50){
        hook = 50
      }else if(l2d>50){
        hook = 100
      }

      diff = hook - l2d

      rn = tcv + diff


      let x = Math.ceil((rn - tcv)/(1+cgst+sgst))

     
      //We need to search for x in cart items + round off items configured
      let resultantItems = this.cartItems.concat(this.roundOffItems)

      //TODO: Remove duplicates if any

      let m = new Map()

      let result: MenuItem[] = []

      for(let obj of resultantItems){
        if(!m.has(obj.id)){
          result.push(obj)
          m.set(obj.id, obj)
        }
      }

      let finalResult: MenuItem[] = []
            
      for(let ci of result){
        if(x % ci.price == 0 ){
          finalResult.push(ci)
        }
      }

      //TODO-SAMAR: Open dialogue here to display items over the dialogue
      const dialogRoundOffItemRef = this.dialog.open(RoundOffItemsComponent, {
        maxHeight:"60vh",
        data: {
           //passing rounding off items ids to dialog
              roundOffItemsIds:finalResult.length>0?finalResult.map(roi=>roi.id):[],
              roundOffAmount:rn,
              targetRoundOffValue: x
              }
      });


    }else{
      // Already rounded-off case
      const dialogUpsaleItemRef = this.dialog.open(RoundingOffComponent , {
        maxHeight:"60vh",
        data: {}
      });
    }

  }

}
