
import * as fromMenu from '../reducers/menu.reducer';
import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { selectCategoriesEntities, selectCategoriesIds } from './categories.selectors';
import { selectIngredientsEntities } from './ingredients.selectors';
import { MenuItem } from '~app/models/menu-item';
import { adapter } from '../reducers/menu.reducer';
import { AppState } from '~app/reducers/main';
import { Ingredient } from '~app/models/ingredient';
import { CategoriesComponent } from '~app/components/menu/categories/categories.component';
import { CategoryIdState } from '~app/reducers/categories.reducer';
import { selectCategoriesOrder, selectCategoryitemsOrderByIndex  } from './menu-settings.selectors';
import { CategoryIndex } from '~app/models/menu-settings';
import { Category } from '~app/models/category';
import { selectMenuSettingsEntities } from '~app/reducers/menu-settings.reducer';

export interface State {
    menu: fromMenu.MenuState
}

export const selectMenuState = createFeatureSelector<fromMenu.MenuState>('menu')

export const selectMenuIds = createSelector(
    selectMenuState,
    (menu) => fromMenu.selectMenuIds(menu)
)

export const selectMenuEntities = createSelector(
    selectMenuState,
    (menu) => fromMenu.selectMenuEntities(menu)
)

export const selectBusinessCategories = createSelector(
    selectMenuIds,
    selectMenuEntities,
    selectCategoriesEntities,
    selectCategoriesOrder,
    (menuIds, menuEntities, categoriesEntities, menuSettings) => {

        let finalResult=[]

        let resultSet: Set<string> = new Set()
        let resultList: Category[] = []
        let offerItems = []
        let specials = []

        //TODO: Consider menu settings
        if(menuSettings.length>0){
            for(let c of menuSettings){
                resultList.push(categoriesEntities[c.categoryId])
            }

        }

        for (let id of menuIds) {
            if (!menuEntities[id].disabled) {
                if(menuSettings.length==0){
                    resultSet.add(menuEntities[id].categoryId)
                }
                if (menuEntities[id].offer > 0) {
                    offerItems.push(menuEntities[id])
                }
                if ((menuEntities[id].recommended || menuEntities[id].mustTry)) {
                    specials.push(menuEntities[id])
                }
            }
        }
        for (let rs of resultSet) {
            resultList.push(categoriesEntities[rs])
        }

        if (offerItems.length > 0) {
            let offerCategory = categoriesEntities['77e6838a-7571-4677-97f2-57b49d507b37'] //This is offer category id
            finalResult.push(offerCategory)
        }

        if (specials.length > 0) {
            let specialCategory = categoriesEntities['4ec2b543-b14f-4ccd-a951-10f0b780f25f'] //This is special category id
            finalResult.push(specialCategory)
        }


        finalResult = finalResult.concat(resultList)

        let catIndex =0
        let finalResultWithIndex:Category[]=[]
        for ( let cat of finalResult){
            catIndex=catIndex + 1
             cat={...cat,index:catIndex}
             finalResultWithIndex.push(cat)
        }
    
        return finalResultWithIndex

    }
)

export const selectBusinessItems = createSelector(
    selectMenuIds,
    selectMenuEntities,
    selectCategoriesEntities,
    selectIngredientsEntities,
    (menuIds, menuEntities, categoryEntities, ingredientsEntities) => {
        let finalResult: MenuItem[] = []

        for (let mId of menuIds) {
            let catId = menuEntities[mId].categoryId
            let ingIds = menuEntities[mId].ingredientIds

            let ing: Ingredient[] = []

            if (ingIds) {
                for (let iId of ingIds) {
                    ing.push(ingredientsEntities[iId])
                }
            }

            finalResult.push({
                ...menuEntities[mId],
                category: categoryEntities[catId],
                ingredients: ing
            })

        }
        let fR = finalResult.filter(m => m.disabled == false)
        
        return fR
    }

)

export const selectBusinessItems1 = createSelector(
    selectMenuIds,
    selectMenuEntities,
    selectCategoriesEntities,
    selectIngredientsEntities,
    (menuIds, menuEntities, categoryEntities, ingredientsEntities) => {
        let finalResult: MenuItem[] = []

        for (let mId of menuIds) {
            let catId = menuEntities[mId].categoryId
            let ingIds = menuEntities[mId].ingredientIds

            let ing: Ingredient[] = []

            if (ingIds) {
                for (let iId of ingIds) {
                    ing.push(ingredientsEntities[iId])
                }
            }

            finalResult.push({
                ...menuEntities[mId],
                ingredients: ing
            })

        }
        let fR = finalResult.filter(m => m.disabled == false)
        
        return fR
    }

)

export const selectByItemId = createSelector(
    selectBusinessItems,
    (menu, props) => {
        let id = props.id
        return menu.filter(m => m.id == id)[0]
    }
)


export const selectItemsByIDs = createSelector(
    selectBusinessItems,
    (menu, props) => {

        let ids = props.ids

        let result: MenuItem[] = []

        if(ids.length>0){
            for(let id of ids){
                for(let m of menu){
                    if(id===m.id){
                        result.push(m)
                    }
                }
            }
        }

        return result;
    }
)

export const selectCategoryItems = createSelector(
    selectBusinessItems,
    selectCategoriesOrder ,
    (menu, menuSettings,props) => {
        let selectedCat = props.id
        if(menuSettings.length >0){
            let orderMenuItems:MenuItem[] =[]
            let itemsOrder:CategoryIndex=menuSettings.filter(ci=> ci.categoryId == selectedCat)[0]
            if(itemsOrder.items.length > 0){
                for(let mi of itemsOrder.items){
                    orderMenuItems.push(menu.filter(item => item.id == mi.itemId)[0])
                }
                return orderMenuItems
            }
            else{
                return menu.filter(m => m.categoryId == selectedCat)
            }
            
        }
        else{
            return menu.filter(m => m.categoryId == selectedCat)
        }
    }
)

export const getSavedCategoryId = (state: AppState) => state.categoryId

export const selectCategoryByFocuedId = createSelector(
    selectBusinessCategories,
    getSavedCategoryId,
    (cats, catId) => {
        let selectedCat = catId.id
        return cats.filter(m => m.id == selectedCat)[0]
    }
)

export const selectOfferItems = createSelector(
    selectBusinessItems,
    (menu) => {
        return menu.filter(m => m.offer > 0 && m.disabled === false)
    }
)

export const selectSpecialItems = createSelector(
    selectBusinessItems,
    (menu) => {
        return menu.filter(e => (e.recommended || e.mustTry) && !e.disabled)
    }
)

export const selectCategoryItemsByFocusedId = createSelector(
    selectBusinessItems,
    getSavedCategoryId,
    selectOfferItems,
    selectSpecialItems,
    selectCategoriesOrder,
    (menu, catId, offerItems, specials,Ci) => {
        let selectedCat = catId.id
       

        let finalResult=[] ;

        if (selectedCat == '77e6838a-7571-4677-97f2-57b49d507b37') {
            finalResult=offerItems
        } else if (selectedCat == '4ec2b543-b14f-4ccd-a951-10f0b780f25f') {
            finalResult= specials
        }
        else {
            if(Ci.length > 0){
                let itemOrder=Ci.filter(c => c.categoryId == selectedCat)[0]?Ci.filter(c => c.categoryId == selectedCat)[0].items:[]
             let items=[]
              for( let item of itemOrder){
                   items.push(menu.filter(i => i.id == item.itemId )[0])
              }

              finalResult=items.filter(item => item != undefined ) //removing undefined values
              
            }else{

                finalResult=menu.filter(m => m.categoryId == selectedCat)
            }
           
            
        }
        let itemIndex=0;
        let finalResultWithIndex=[]
        for(let item of finalResult){
            itemIndex=itemIndex+1
            item ={
                ...item,index:itemIndex
            }
            finalResultWithIndex.push(item)
        }

        return finalResultWithIndex
    }
)


// export const selectFilteredMenuItems=createSelector(
//     selectBusinessItems,
//     (menu:MenuItem[], props) => {
//         let searchValue:string=props.searchValue
//         let filteredlist_arr =[]
//         filteredlist_arr = menu.filter(m=> m.name.toLowerCase().includes(searchValue))
//         return filteredlist_arr
//     }
// )


export const selectUpdatedMenu = createSelector(
    selectBusinessItems1,
    selectBusinessCategories,
    selectCategoriesOrder,
    (items, categories, settings) => {

        // Assume no menu arrangement settings
        let finalResult: MenuItem[] = []
        let result: Category[] = []
        for(let category of categories){
            if(["77e6838a-7571-4677-97f2-57b49d507b37", "4ec2b543-b14f-4ccd-a951-10f0b780f25f"].includes(category.id)){
                continue
             }
            let mItems = items.filter(item => item.categoryId == category.id)
            category.menuItems =  mItems
            mItems[0].category = category
            result.push(category)
            finalResult.push(...mItems)
        }
        

        // Now, assume there are menu arrangment settings
        if(settings && Array.isArray(settings) && settings.length>0){
            finalResult = []
            let sortedCategoryIds = settings.map(s => s.categoryId)

            // Next, we need to sort categories based on sortedCategoryIds

            result.sort((a,b) => {
                return sortedCategoryIds.indexOf(a.id) - sortedCategoryIds.indexOf(b.id)
            })

            // Now, order items based on the item ordering preferences

           for(let category of result){
             if(["77e6838a-7571-4677-97f2-57b49d507b37", "4ec2b543-b14f-4ccd-a951-10f0b780f25f"].includes(category.id)){
                continue
             }
             let mItems = category.menuItems

             let cats = settings.filter(s => s.categoryId == category.id)[0] // TODO: may have efficient way
             let sortedItemIds = cats.items.map(m => m.itemId)

             mItems.sort((a,b) => {
                return sortedItemIds.indexOf(a.id) - sortedItemIds.indexOf(b.id)
             })

             mItems.filter(m => m.category!=null)[0].category = null

             mItems[0].category = category
             

             category.menuItems = mItems

             finalResult.push(...mItems)

           }
        }

        return finalResult

    }
)


export const selectSimiarItems = createSelector(
    selectBusinessItems,
    (menu, props) => {
        let id = props.id
        return menu.filter(m => m.categoryId == id)
    }
)